import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/states/reducers'
import { Select } from 'antd'
import { DeleteFilled, HolderOutlined } from '@ant-design/icons'
import {
  CellInterface,
  ComponentInterface,
  ContentsInterface,
  RelationInterface,
  PaginationInterface,
} from '@/types'
import { getContentsListSearch } from '@/states/actions/contents.actions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'
import { CHUNK_CONTENTS_GET_SIZE, DEFAULT_SORT } from '@/configs'
import { useAppDispatch } from '@/states/store'

interface ContentsRelationProps {
  component: ComponentInterface
  cell: CellInterface | null | undefined
  relationList: (ContentsInterface | RelationInterface)[]
  onSelect: (contentsList: (ContentsInterface | RelationInterface)[]) => void
}

let timeout
let currentValue

export const ContentsRelationSelector = ({
  component,
  cell,
  relationList,
  onSelect,
}: ContentsRelationProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State
  const [uid, setUid] = useState<string>(uuidv4())
  const [init, setInit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [list, setList] = useState<ContentsInterface[]>([])
  const [modelSelected, setModelSelected] = useState<number | null>(null)
  const [selectedUidList, setselectedUidList] = useState<number[]>([])
  const [selectedList, setSelectedList] = useState<
    (ContentsInterface | RelationInterface)[]
  >([])
  const [pagination, setPagination] = useState<PaginationInterface>({
    total: 0,
    pageSize: 9999,
    current: 1,
  })

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { contentsInfo } = modalsState

  // Effect
  useEffect(() => {
    if (cell && cell.relationList?.length && !init) {
      setInit(true)
    }
  }, [cell])

  useEffect(() => {
    if (cell?.component?.type === 'RELATION') {
      setSelectedList(relationList)
    } else {
      setSelectedList([])
    }
  }, [relationList])

  /**
   * 자동 완성 입력
   * @param value
   */
  const onHandleSearch = (value) => {
    if (value && value.length > 1 && modelSelected) {
      fetchContentsList(value, (data) => setList(data))
    } else {
      setList([])
    }
  }

  /**
   * 콘텐트 목록 가져오기
   * @param paging
   * @param sorting
   * @param field
   * @param filter
   * @param delay
   * @param skipLoading
   */
  const fetchContentsList = async (value, callback) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    timeout = setTimeout(async () => {
      setLoading(true)

      const targetModel = modelList.find((m) => m.id === Number(modelSelected))
      const titleComponent = targetModel?.flattenComponentList?.find(
        (fc) => fc.option?.title
      )

      const contentsListReqs: any[] = []

      try {
        const getData = (page: number) => {
          const resAxios = getContentsListSearch(
            currentProject?.uid,
            modelSelected,
            {
              page: page,
              size: CHUNK_CONTENTS_GET_SIZE,
              direction: 'ASC',
            }
          )
          contentsListReqs.push(resAxios)

          return resAxios.then((res) => {
            if (!res.data.pageInfo.isLast) {
              return getData(page + 1)
            }
          })
        }

        await getData(0)

        await axios.all([...contentsListReqs]).then(
          axios.spread(async (...responses) => {
            const updatedAllContents = responses.map((r) => r.data.list).flat()

            if (currentProject) {
              const updatedList: ContentsInterface[] = JSON.parse(
                JSON.stringify(
                  updatedAllContents.filter(
                    (i) =>
                      (!contentsInfo ||
                        (contentsInfo && contentsInfo.uid !== i.uid)) &&
                      i.cellList &&
                      i.cellList.find(
                        (c) =>
                          c.component.id === titleComponent?.id &&
                          c.languageMap &&
                          c.languageMap[currentProject.defaultLang] &&
                          c.languageMap[currentProject.defaultLang].includes(
                            currentValue
                          )
                      )
                  )
                )
              )

              updatedList.forEach((contents) => {
                const titleCell = contents.cellList.find(
                  (cell) => cell?.component?.id === titleComponent?.id
                )

                // @ts-ignore
                contents.title =
                  titleCell && titleCell.languageMap
                    ? titleCell.languageMap[currentProject.defaultLang]
                    : contents.uid

                // @ts-ignore
                contents.modelTitle =
                  targetModel?.languageMap[currentProject.defaultLang]
              })

              if (currentValue === value && currentProject) {
                callback(updatedList)
                setLoading(false)
              }
            }
          })
        )
      } catch (e) {
        console.log(e)
        callback([])
        setLoading(false)
      }
    }, 300)
  }

  /**
   * 미디어 선택
   * @param value
   */
  const onHandleChange = (value) => {
    let changedSelectedList: (ContentsInterface | RelationInterface)[] = []

    if (value) {
      if (component.option?.multiple) {
        changedSelectedList = [
          ...selectedList,
          ...list.filter(
            (m) =>
              value.includes(m.uid) &&
              !selectedList.map((sm) => sm.uid).includes(m.uid)
          ),
        ]
      } else {
        changedSelectedList = list.filter((m) => m.uid === value)
      }
    }

    onSelect(changedSelectedList)
  }

  /**
   * 선택 옵션
   */
  const options = list.map((contents) => (
    <Select.Option key={contents.uid} value={contents.uid}>
      <div className="flex items-center space-x-2">
        <div className="px-2 bg-gray-200 border border-gray-300 rounded-sm">
          {contents.modelTitle}
        </div>
        <div>{contents.title}</div>
      </div>
    </Select.Option>
  ))

  /**
   * 선택 미디어 정렬
   * @param oldIndex
   * @param newIndex
   */
  const onSortEnd = (result) => {
    if (!result.destination) {
      return
    }

    // Sort 실행
    const updatedSelectedList = JSON.parse(JSON.stringify(selectedList))
    const startIndex = result.source.index
    const endIndex = result.destination.index
    const [removed] = updatedSelectedList.splice(startIndex, 1)
    updatedSelectedList.splice(endIndex, 0, removed)
    updatedSelectedList.forEach((item, idx) => {
      item.order = idx + 1
    })

    setSelectedList(updatedSelectedList)
    onSelect(updatedSelectedList)
  }

  /**
   * 미디어 삭제
   * @param contents
   */
  const onRemovecontents = (contents) => {
    setSelectedList(selectedList.filter((s) => s.uid !== contents.uid))
    onSelect(selectedList.filter((s) => s.uid !== contents.uid))
  }

  return (
    <div className={'space-y-6'}>
      {currentProject && currentProject.role !== 'VIEWER' ? (
        <div className="flex justify-between space-x-4">
          <div className="w-52">
            <Select
              showArrow
              value={modelSelected}
              defaultActiveFirstOption={false}
              onChange={(val) => setModelSelected(val)}
              className="w-full"
              placeholder={t('selectModel')}>
              {modelList
                .filter((m) => m.hasTitle)
                .map((model, mIdx) => (
                  <Select.Option key={mIdx} value={model.id}>
                    {model.languageMap[currentProject.defaultLang]}
                  </Select.Option>
                ))}
            </Select>
          </div>
          <div className={'w-full space-y-1'}>
            {currentProject ? (
              component.option?.multiple ? (
                <Select
                  mode="multiple"
                  showArrow
                  loading={loading}
                  disabled={!modelSelected}
                  value={selectedUidList}
                  onSearch={onHandleSearch}
                  onChange={onHandleChange}
                  defaultActiveFirstOption={false}
                  className="w-full"
                  placeholder={t('autocomplete', { max: 2 })}
                  filterOption={false}>
                  {options}
                </Select>
              ) : (
                <Select
                  showSearch
                  loading={loading}
                  disabled={!modelSelected}
                  value={selectedUidList}
                  onSearch={onHandleSearch}
                  onChange={onHandleChange}
                  defaultActiveFirstOption={false}
                  className="w-full"
                  placeholder={t('autocomplete', { max: 2 })}
                  filterOption={false}>
                  {options}
                </Select>
              )
            ) : (
              <></>
            )}
            <p className="text-xs text-gray-500 mb-0">
              {t('selectContentsRelation')}
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* 미디어 선택: 시작 */}
      <div className="space-y-1">
        <DragDropContext onDragEnd={onSortEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="space-y-1"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {selectedList.length ? (
                  selectedList.map((contents, index) => (
                    <Draggable
                      key={component.id + '-' + contents.uid}
                      draggableId={component.id + '-' + contents.uid}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}>
                          <div className="py-2 px-3 bg-gray-200 border border-gray-300 text-xs rounded-sm">
                            <div className="flex items-center justify-between w-full space-x-6">
                              <div
                                className={`flex-none px-2 bg-gray-300 border border-gray-400 rounded-sm rel-modelTitle-${cell?.uid}-${contents.uid}`}>
                                {contents.modelTitle
                                  ? contents.modelTitle
                                  : '-'}
                              </div>
                              <div
                                className={`w-full rel-title-${cell?.uid}-${contents.uid}`}>
                                {contents.title ? contents.title : '-'}
                              </div>
                              {currentProject?.role !== 'VIEWER' ? (
                                <div className="flex-none flex justify-end space-x-4 items-center w-20">
                                  <DeleteFilled
                                    className="cursor-pointer"
                                    onClick={() => onRemovecontents(contents)}
                                  />
                                  <div
                                    className={
                                      component.option?.multiple ? '' : 'hidden'
                                    }
                                    {...provided.dragHandleProps}>
                                    <HolderOutlined className="cursor-move" />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <></>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {/* 미디어 선택: 끝 */}
    </div>
  )
}
