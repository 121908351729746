import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import moment from 'moment'
import { MediaInterface } from '@/types'

interface MediaFileInfoProps {
  mediaInfo: MediaInterface
}

export const MediaFileInfo = ({ mediaInfo }: MediaFileInfoProps) => {
  const { t, i18n } = useTranslation()

  return mediaInfo ? (
    <div className="block bg-gray-200 rounded-sm py-2 px-3">
      <Row gutter={24}>
        <Col span={24} className="mb-3">
          <p className="text-xs leading-5 mb-0">
            {t('fileName')}
            <span className="ml-2 rounded-sm px-1 bg-gray-100 border border-gray-400 text-xs">
              {mediaInfo.file.type}
            </span>
          </p>
          <p className="leading-6 mb-0">{mediaInfo.file.name}</p>
        </Col>
        <Col span={12} className="mb-3">
          <p className="text-xs leading-5 mb-0">{t('extension')}</p>
          <p className="leading-6 mb-0">
            {mediaInfo.file.path
              .split('.')
              [mediaInfo.file.path.split('.').length - 1].toUpperCase()}
          </p>
        </Col>
        <Col span={12} className="mb-3">
          <p className="text-xs leading-5 mb-0">{t('resolutions')}</p>
          <p className="leading-6 mb-0">
            {mediaInfo.file.meta &&
            mediaInfo.file.meta.width &&
            mediaInfo.file.meta.height
              ? mediaInfo.file.meta.width + 'x' + mediaInfo.file.meta.height
              : '-'}
          </p>
        </Col>
        <Col span={12}>
          <p className="text-xs leading-5 mb-0">{t('fileSize')}</p>
          <p className="leading-6 mb-0">{mediaInfo?.file.meta?.size}</p>
        </Col>
        {mediaInfo.date && mediaInfo.date.createdAt ? (
          <Col span={12}>
            <p className="text-xs leading-5 mb-0">{t('createdDate')}</p>
            <p className="leading-6 mb-0">
              {moment(mediaInfo.date.createdAt, 'YYYYMMDDHHmmss').format(
                'YYYY-MM-DD'
              )}
            </p>
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </div>
  ) : (
    <></>
  )
}
