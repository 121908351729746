import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Select } from 'antd'
import {
  EditFilled,
  DeleteFilled,
  HolderOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import {
  CellInterface,
  ComponentInterface,
  FileType,
  MediaInterface,
  PaginationInterface,
} from '@/types'
import { getMediaListSearch } from '@/states/actions/media.actions'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  setMediaFormModal,
  setMediaUploadModal,
} from '@/states/actions/modals.actions'
import { v4 as uuidv4 } from 'uuid'
import { MediaThumb } from '.'
import { useAppDispatch } from '@/states/store'

interface MediaSelectorProps {
  component: ComponentInterface
  cell: CellInterface | null | undefined
  mediaList: MediaInterface[]
  onSelect: (mediaList: MediaInterface[]) => void
  noLazy?: boolean
}

let timeout
let currentValue

export const MediaSelector = ({
  component,
  cell,
  mediaList,
  onSelect,
  noLazy,
}: MediaSelectorProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State
  const [uid, setUid] = useState<string>(uuidv4())
  const [init, setInit] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [keyword, setKeyword] = useState<string>('')
  const [list, setList] = useState<MediaInterface[]>([])
  const [selectedIdList, setSelectedIdList] = useState<number[]>([])
  const [selectedList, setSelectedList] = useState<MediaInterface[]>([])
  const [sort, setSort] = useState<string>('DATE.DESC')
  const [filter, setFilter] = useState<FileType | ''>('')
  const [pagination, setPagination] = useState<PaginationInterface>({
    total: 0,
    pageSize: 100,
    current: 1,
  })

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const { currentProject, newMediaList } = projectsState
  const { mediaSelectorModal, updatedMediaInfo } = modalsState

  // Effect
  useEffect(() => {
    if (cell && cell.mediaList?.length && !init) {
      setInit(true)
    }
  }, [cell])

  useEffect(() => {
    setList([])
  }, [mediaSelectorModal])

  useEffect(() => {
    if (newMediaList && newMediaList.uid === uid) {
      const addMediaList =
        newMediaList.mediaList && newMediaList.mediaList.length
          ? component.option?.multipleMedia
            ? [...selectedList, ...newMediaList.mediaList]
            : [newMediaList.mediaList[0]]
          : []
      setSelectedList(addMediaList)
      onSelect(addMediaList)
    }
  }, [newMediaList])

  useEffect(() => {
    if (cell?.component?.type === 'MEDIA') {
      setSelectedList(mediaList)
    } else {
      setSelectedList([])
    }
  }, [mediaList])

  useEffect(() => {
    if (updatedMediaInfo && selectedList.length) {
      const updatedSelectedList = JSON.parse(JSON.stringify(selectedList))
      const updatedMedia = updatedSelectedList.find(
        (s) => s.id === updatedMediaInfo.id
      )

      if (updatedMedia) {
        const updatedMediaIdx = updatedSelectedList.indexOf(updatedMedia)
        updatedSelectedList[updatedMediaIdx].fileType =
          updatedMediaInfo.fileType
        updatedSelectedList[updatedMediaIdx].languageMap =
          updatedMediaInfo.languageMap
        updatedSelectedList[updatedMediaIdx].file = updatedMediaInfo.file

        setSelectedList(updatedSelectedList)
      }
    }
  }, [updatedMediaInfo])

  /**
   * 자동 완성 입력
   * @param value
   */
  const onHandleSearch = (value) => {
    if (value && value.length > 2) {
      fetchMediaList(value, (data) => setList(data))
    } else {
      setList([])
    }
  }

  /**
   * 미디어 검색
   * @param value
   * @param callback
   */
  const fetchMediaList = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }
    currentValue = value
    timeout = setTimeout(() => {
      setLoading(true)

      const req = {
        page: pagination.current - 1,
        size: pagination.pageSize,
        direction: sort.split('.')[1],
        condition: sort.split('.')[0],
        type: filter ? filter : null,
        keyword: {
          value: value,
        },
      }

      getMediaListSearch(currentProject?.uid, req)
        .then((res) => {
          if (currentProject) {
            const sortedList = res.data.list.sort(function (a, b) {
              return a.languageMap[
                currentProject.defaultLang
              ].name.localeCompare(
                b.languageMap[currentProject.defaultLang].name
              )
            })
            if (currentValue === value && currentProject) {
              callback(sortedList)
              setLoading(false)
            }
          }
        })
        .catch((e) => {
          console.log(e)
          callback([])
          setLoading(false)
        })
    }, 300)
  }

  /**
   * 미디어 선택
   * @param value
   */
  const onHandleChange = (value) => {
    let changedSelectedList: MediaInterface[] = []

    if (value) {
      if (component.option?.multipleMedia) {
        changedSelectedList = [
          ...selectedList,
          ...list.filter(
            (m) =>
              value.includes(m.id) &&
              !selectedList.map((sm) => sm.id).includes(m.id)
          ),
        ]
      } else {
        changedSelectedList = list.filter((m) => m.id === value)
      }
    }

    onSelect(changedSelectedList)
  }

  /**
   * 선택 옵션
   */
  const options = list.map((media) => (
    <Select.Option key={media.id} value={media.id}>
      <div className="flex items-center space-x-2">
        <MediaThumb
          media={media}
          noLazy={noLazy}
          // width="20"
          outerWidth={20}
          outerHeight={20}
          textSize="xs"
          thumbnail="SMALL"
        />
        <div>
          {currentProject &&
          media.languageMap &&
          media.languageMap[currentProject.defaultLang]
            ? (media.languageMap[currentProject.defaultLang]?.name as string)
            : ''}
        </div>
      </div>
    </Select.Option>
  ))

  /**
   * 선택 미디어 정렬
   * @param oldIndex
   * @param newIndex
   */
  const onSortEnd = (result) => {
    if (!result.destination) {
      return
    }

    // Sort 실행
    const updatedSelectedList = JSON.parse(JSON.stringify(selectedList))
    const startIndex = result.source.index
    const endIndex = result.destination.index
    const [removed] = updatedSelectedList.splice(startIndex, 1)
    updatedSelectedList.splice(endIndex, 0, removed)
    updatedSelectedList.forEach((item, idx) => {
      item.order = idx + 1
    })

    setSelectedList(updatedSelectedList)
    onSelect(updatedSelectedList)
  }

  /**
   * 미디어 수정
   * @param media
   */
  const onEditMedia = (media) => {
    dispatch(setMediaFormModal(true, media))
  }

  /**
   * 미디어 삭제
   * @param media
   */
  const onRemoveMedia = (media) => {
    setSelectedList(selectedList.filter((s) => s.id !== media.id))
    onSelect(selectedList.filter((s) => s.id !== media.id))
  }

  return (
    <div className={'space-y-6'}>
      {currentProject && currentProject.role !== 'VIEWER' ? (
        <div className="flex justify-between space-x-4">
          <div className={'w-full space-y-1'}>
            {currentProject ? (
              component.option?.multipleMedia ? (
                <Select
                  mode="multiple"
                  showArrow
                  loading={loading}
                  value={selectedIdList}
                  onSearch={onHandleSearch}
                  onChange={onHandleChange}
                  defaultActiveFirstOption={false}
                  className="w-full"
                  placeholder={t('autocomplete', { max: 3 })}
                  filterOption={false}>
                  {options}
                </Select>
              ) : (
                <Select
                  showSearch
                  loading={loading}
                  value={selectedIdList}
                  onSearch={onHandleSearch}
                  onChange={onHandleChange}
                  defaultActiveFirstOption={false}
                  className="w-full"
                  placeholder={t('autocomplete', { max: 3 })}
                  filterOption={false}>
                  {options}
                </Select>
              )
            ) : (
              <></>
            )}
            <p className="text-xs text-gray-500 mb-0">{t('selectMediaDesc')}</p>
          </div>
          <div className="flex">
            <Button
              onClick={() => dispatch(setMediaFormModal(true, null, null, uid))}
              className="flex-none -mr-px"
              icon={<PlusOutlined />}
              style={{
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0',
              }}>
              {t('addMediaContents')}
            </Button>
            {component.option?.multipleMedia ? (
              <Button
                onClick={() => dispatch(setMediaUploadModal(true, uid))}
                className="flex-none"
                icon={<UploadOutlined />}
                style={{
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                }}>
                {t('uploadMultipleMediaContents')}
              </Button>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* 미디어 선택: 시작 */}
      <div className="space-y-1">
        <DragDropContext onDragEnd={onSortEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="space-y-1"
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {selectedList.length ? (
                  selectedList.map((media, index) => (
                    <Draggable
                      key={component.id + '-' + media.id}
                      draggableId={
                        component.id + '-' + (media.id as number).toString()
                      }
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}>
                          <div className="py-2 px-3 bg-gray-200 border border-gray-300 text-xs rounded-sm">
                            <div className="flex items-center justify-between w-full space-x-6">
                              <MediaThumb
                                media={media}
                                noLazy={noLazy}
                                // width="64"
                                outerWidth={64}
                                outerHeight={64}
                                textSize="xl"
                                thumbnail="SMALL"
                              />
                              <div className="w-full">
                                {currentProject &&
                                media.languageMap &&
                                media.languageMap[currentProject?.defaultLang]
                                  ? media.languageMap[
                                      currentProject?.defaultLang
                                    ]?.name
                                  : ''}
                              </div>
                              {currentProject?.role !== 'VIEWER' ? (
                                <div className="flex-none flex justify-end space-x-4 items-center w-20">
                                  <EditFilled
                                    className="cursor-pointer"
                                    onClick={() => onEditMedia(media)}
                                  />
                                  <DeleteFilled
                                    className="cursor-pointer"
                                    onClick={() => onRemoveMedia(media)}
                                  />
                                  <div
                                    className={
                                      component.option?.multipleMedia
                                        ? ''
                                        : 'hidden'
                                    }
                                    {...provided.dragHandleProps}>
                                    <HolderOutlined className="cursor-move" />
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                            {provided.placeholder}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <></>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {/* 미디어 선택: 끝 */}
    </div>
  )
}
