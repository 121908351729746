import { ComponentTypeInterface } from '@/types/component.type'
import {
  TranslationOutlined,
  FontSizeOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  NumberOutlined,
  MailOutlined,
  LockFilled,
  ApiOutlined,
  FileImageFilled,
  CodeSandboxOutlined,
  PicLeftOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'

export const availableComponents: ComponentTypeInterface[] = [
  {
    type: 'SINGLE_LINE_TEXT',
    icon: TranslationOutlined,
    options: ['required', 'title', 'unique', 'allowHtml', 'maxText'],
    searchable: true,
    sortable: true,
    exportable: true,
  },
  /* {
    type: 'SINGLE_LINE_TEXT_MONO',
    icon: FontSizeOutlined,
    options: ['required', 'title', 'unique', 'allowHtml', 'maxText'],
    searchable: true,
    sortable: true,
    exportable: true,
  }, */
  {
    type: 'LONG_LINE_TEXT',
    icon: FontSizeOutlined,
    options: ['required', 'title', 'unique', 'allowHtml'],
    searchable: true,
    sortable: true,
    exportable: true,
  },
  /* {
    type: 'LONG_LINE_TEXT_MONO',
    icon: FontSizeOutlined,
    options: ['required', 'title', 'unique', 'allowHtml'],
    searchable: true,
    sortable: true,
    exportable: true,
  }, */
  {
    type: 'RICH_TEXT',
    icon: PicLeftOutlined,
    options: [],
    searchable: true,
    sortable: false,
    exportable: true,
  },
  /* {
    type: 'RICH_TEXT_MONO',
    icon: PicLeftOutlined,
    options: [],
    searchable: true,
    sortable: false,
    exportable: true,
  }, */
  {
    type: 'CATEGORY',
    icon: UnorderedListOutlined,
    options: ['required', 'multiple'],
    searchable: false,
    sortable: false,
    exportable: true,
  },
  {
    type: 'DATE',
    icon: CalendarOutlined,
    options: ['dateFormats'],
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    type: 'NUMBER',
    icon: NumberOutlined,
    options: ['required', 'min', 'max'],
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    type: 'EMAIL',
    icon: MailOutlined,
    options: ['required', 'title', 'unique'],
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    type: 'PASSWORD',
    icon: LockFilled,
    options: ['required'],
    searchable: true,
    sortable: true,
    exportable: true,
  },
  {
    type: 'BOOLEAN',
    icon: CheckCircleOutlined,
    searchable: false,
    sortable: true,
    exportable: true,
  },
  {
    type: 'RELATION',
    icon: ApiOutlined,
    options: ['required', 'multiple'],
    searchable: false,
    sortable: false,
    exportable: true,
  },
  {
    type: 'MEDIA',
    icon: FileImageFilled,
    options: ['multipleMedia'],
    searchable: false,
    sortable: false,
    exportable: true,
  },
  {
    type: 'BLOCK',
    icon: CodeSandboxOutlined,
    searchable: false,
    sortable: false,
    exportable: false,
  },
]

export const dateFormatsMoment = {
  time: 'HH:mm:ss',
  date: 'YYYY-MM-DD',
  year: 'YYYY',
  month: 'YYYY-MM',
}

export const dateFormatReq = 'YYYY-MM-DDTHH:mm:ss.SSS'
