import React, { useEffect } from 'react'
import { shallowEqual, useSelector, connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router'
import { Layout, Menu } from 'antd'
const { Header, Content, Footer, Sider } = Layout
import { setAccountMenu } from '@/states/actions/layout.actions'
import { RootState } from '@/states/reducers'
import { LayoutGnbHome, LayoutSidebarAuthSettings } from '@/components/layout'
import { LAYOUT_GNB_WIDTH, LAYOUT_SIDEBAR_WIDTH } from '@/configs'
import { ProjectsFormModal } from '@/components/projects/projects.form.modal'
import { useAppDispatch } from '@/states/store'
import { getProfile } from '@/states/actions'

export const LayoutHome = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { authState, layoutState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { me, init } = authState
  const { accountMenu } = layoutState

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // Effect
  useEffect(() => {
    if (!init) {
      // 내 프로필 가져오기
      dispatch(getProfile())
    }
  }, [init])

  /**
   * 헤더 메뉴 닫기
   * @param e
   */
  const closeMenu = (e) => {
    if (accountMenu && !e.target.closest('.stay-open')) {
      dispatch(setAccountMenu(false))
    }
  }

  return (
    <Layout hasSider onClick={(e) => closeMenu(e)}>
      <LayoutGnbHome></LayoutGnbHome>
      {location.pathname.includes('/settings') ? (
        <LayoutSidebarAuthSettings></LayoutSidebarAuthSettings>
      ) : (
        ''
      )}
      <Layout
        className="site-layout h-screen"
        style={{
          marginLeft:
            LAYOUT_GNB_WIDTH +
            (location.pathname.includes('/settings')
              ? LAYOUT_SIDEBAR_WIDTH
              : 0),
        }}>
        <Content>
          <div className="site-layout-background">{children}</div>
        </Content>
      </Layout>
      <ProjectsFormModal />
    </Layout>
  )
}
