import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { message } from 'antd'
import { RootState } from '@/states/reducers'
import {
  CellInterface,
  ComponentInterface,
  ContentsInterface,
  LanguagesAvailable,
} from '@/types'
import {
  getContentsItem,
  updateContents,
  updateContentsItem,
} from '@/states/actions/contents.actions'
import { ContentsCellComponent } from './'
import moment from 'moment-timezone'
import { dateFormatReq } from '@/configs'
import { useAppDispatch } from '@/states/store'

interface ContentsCellItemProps {
  component: ComponentInterface
  contents: ContentsInterface
  cell: CellInterface | null | undefined
}

export const ContentsCellItem = ({
  component,
  contents,
  cell,
}: ContentsCellItemProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, currentModel, contentsList, flattenComponentList } =
    projectsState

  /**
   * 셀 정보 수정
   * @param componentId
   * @param key
   * @param value
   * @param lang
   * @returns
   */
  const onHandleCellChange = (
    componentId: number | undefined,
    key: string,
    value: boolean | number | string | object | null,
    lang?: LanguagesAvailable
  ) => {
    if (!contents || currentProject?.role === 'VIEWER') return false

    const updatedContents = JSON.parse(JSON.stringify(contentsList))
    const contentsItem = updatedContents.find((cn) => cn.uid === contents.uid)
    const contentsIdx = updatedContents.indexOf(contentsItem)

    // Update
    if (cell && cell.uid) {
      const cellItem = contentsItem.cellList.find((cl) => cl.uid === cell.uid)
      const cellIdx = contentsItem.cellList.indexOf(cellItem)

      if (lang) {
        // Language map 추가
        if (typeof cellItem[key] === 'undefined') {
          cellItem[key] = {}
        }
        currentProject?.languageList.map((lang) => {
          cellItem[key][lang] =
            cell.languageMap && cell.languageMap[lang]
              ? cell.languageMap[lang]
              : ''
        })

        cellItem[key][lang] = value
      } else {
        cellItem[key] = value
      }

      contentsItem.cellList[cellIdx] = cellItem
    }
    // Add
    else {
      const cellAdd = {
        component: {
          id: component.id,
          type: component.type,
        },
        option: component.option,
        value: null,
        selectorIdList: [],
        mediaIdList: [],
        relationUidList: [],
        languageMap: {},
      }

      // Language map 추가
      currentProject?.languageList.map((lang) => {
        cellAdd.languageMap[lang] = ''
      })

      if (lang) {
        cellAdd[key][lang] = value
      } else {
        cellAdd[key] = value
      }

      if (contentsItem.cellList) {
        contentsItem.cellList.push(cellAdd)
      } else {
        contentsItem.cellList = [cellAdd]
      }
    }

    updatedContents[contentsIdx] = contentsItem

    // Sanitize
    contentsItem.cellList.forEach((cell) => {
      const curComponent = flattenComponentList.find(
        (comp) => comp.id === cell.component.id
      )

      if (
        curComponent &&
        (curComponent.type === 'SINGLE_LINE_TEXT' ||
          curComponent.type === 'LONG_LINE_TEXT') &&
        curComponent.option &&
        !curComponent.option.allowHtml
      ) {
        currentProject?.languageList.forEach((lang) => {
          if (cell && cell.languageMap && cell.languageMap[lang]) {
            cell.languageMap[lang] = (cell.languageMap[lang] as string)
              .normalize('NFC')
              .replace(/(<([^>]+)>)/gi, '')
          }
        })
      }

      if (
        curComponent &&
        (curComponent.type === 'PASSWORD' ||
          curComponent.type === 'EMAIL' ||
          curComponent.type === 'SINGLE_LINE_TEXT_MONO' ||
          curComponent.type === 'LONG_LINE_TEXT_MONO' ||
          curComponent.type === 'RICH_TEXT_MONO') &&
        cell.value
      ) {
        cell.value = cell.value.normalize('NFC').replace(/(<([^>]+)>)/gi, '')
      }

      if (
        curComponent &&
        curComponent.type === 'DATE' &&
        cell.value &&
        typeof cell.value === 'string'
      ) {
        if (curComponent.option?.dateFormats === 'year') {
          cell.value = moment(cell.value, 'YYYY').format(dateFormatReq)
        } else if (curComponent.option?.dateFormats === 'month') {
          cell.value = moment(cell.value, 'YYYY-MM').format(dateFormatReq)
        } else if (curComponent.option?.dateFormats === 'date') {
          cell.value = moment(cell.value, 'YYYY-MM-DD').format(dateFormatReq)
        } else if (curComponent.option?.dateFormats === 'time') {
          cell.value = moment(cell.value, 'HH:mm:ss').format(dateFormatReq)
        }
      }
    })

    updateContents(
      currentProject?.uid,
      currentModel?.id,
      contentsItem.uid,
      contentsItem
    )
      .then((res) => {
        getContentsItem(
          currentProject?.uid,
          currentModel?.id,
          contentsItem.uid
        ).then((res) => {
          dispatch(updateContentsItem(res.data.data))
        })
      })
      .catch((e) => {
        message.error(e.response.data.error)
      })
  }

  return currentProject && currentModel && component ? (
    <ContentsCellComponent
      contents={contents}
      component={component}
      cell={cell}
      onCellChange={onHandleCellChange}
    />
  ) : (
    <></>
  )
}
