import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Input, Button, message } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { AlertStatus } from '@/components/common/common.alert'
import { setLogin } from '@/utils/auth'
import {
  AuthLoginInterface,
  AuthRequestInterface,
  AuthVerifyInterface,
  LoginModeType,
} from '@/types'
import {
  loginWithEmailAndPassword,
  requestEmail,
  searchEmail,
  verifyCode,
} from '@/states/actions'
import { useAppDispatch } from '@/states/store'
import { setTmpCode } from '@/states/actions/auth.actions'
import { RootState } from '@/states/reducers'
import {
  getProjectData,
  getProjectUsageData,
  setCurrentProject,
} from '@/states/actions/projects.actions'
import {
  getModelsData,
  setCurrentModel,
  setModelsList,
} from '@/states/actions/models.actions'
import { DEFAULT_CONTENTS_PAGE_SIZE, LINK_PRIVACY, LINK_TERMS } from '@/configs'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { getCategoryGroups } from '@/states/actions/categories.actions'

const initialRequestValues: AuthRequestInterface = {
  email: '',
}

const initialVerifyValues: AuthVerifyInterface = {
  email: '',
  code: '',
}

const initialLoginValues: AuthLoginInterface = {
  email: '',
  password: '',
}

const LoginPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation()

  // State
  const [mode, setMode] = useState<LoginModeType>('request')
  const [loading, setLoading] = useState<boolean>(false)

  // State (Redux)
  const { authState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
    }),
    shallowEqual
  )
  const { tmpCode } = authState

  // Effect
  useEffect(() => {
    setTimeout(() => {
      document.getElementById('requestEmail')?.focus() // auto focus
    })
  }, [])

  // Validation
  const validationRequestSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.required'))
      .email(t('validation.email')),
  })

  const validationVerifySchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.required'))
      .email(t('validation.email')),
    code: Yup.string().required(t('validation.required')),
  })

  const validationLoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.required'))
      .email(t('validation.email')),
  })

  // Formik Request
  const formikRequest = useFormik({
    initialValues: initialRequestValues,
    validationSchema: validationRequestSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)

      const publicUrl = process.env.REACT_APP_URL as string
      const domain = new URL(publicUrl).hostname

      const req = {
        email: values.email,
        site: '[' + (process.env.REACT_APP_NAME as string) + '] ',
        domain: domain,
      }

      searchEmail(req)
        .then((res) => {
          setMode('login')
          formikLogin.setFieldValue('email', values.email)
          setLoading(false)

          setTimeout(() => {
            document.getElementById('loginPassword')?.focus() // auto focus
          })
        })
        .catch((e) => {
          // No data
          if (e.response.status === 404) {
            requestEmail(req)
              .then((res) => {
                setMode('verify')
                formikVerify.setFieldValue('email', values.email)

                document.title = `${t('auth.signup.title')} • ${
                  process.env.REACT_APP_NAME
                }`
              })
              .catch((e) => {
                setSubmitting(false)

                setStatus(t('error.wrong'))
              })
              .then(() => {
                setLoading(false)
              })
          }
        })
        .then(() => {})
    },
  })

  // Formik Verify
  const formikVerify = useFormik({
    initialValues: initialVerifyValues,
    validationSchema: validationVerifySchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)

      const req = {
        email: values.email,
      }

      verifyCode(req, values.code)
        .then((res) => {
          dispatch(setTmpCode(values.email + '#' + values.code))
          navigate('/signup')
        })
        .catch((e) => {
          // 코드 오류
          setStatus(t('error.wrongCode'))
          setLoading(false)
          setSubmitting(false)
        })
        .then(() => {})
    },
  })

  // Formik Login
  const formikLogin = useFormik({
    initialValues: initialLoginValues,
    validationSchema: validationLoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setStatus(null)

      const req: AuthLoginInterface = {
        email: values.email,
        password: values.password,
      }

      loginWithEmailAndPassword(req)
        .then((res) => {
          setLogin(res.data)

          if (process.env.REACT_APP_PROJECT_ID) {
            const projectUid = process.env.REACT_APP_PROJECT_ID

            getProjectData(projectUid)
              .then(async (res) => {
                const projectData = res.data.data

                getProjectUsageData(projectUid)
                  .then(async (res) => {
                    projectData.usage = res.data
                    await dispatch(setCurrentProject(projectData))
                    await getModelsData(projectUid)
                      .then((res) => {
                        dispatch(setModelsList(res.data.list))

                        const modelInit = res.data.list.length
                          ? res.data.list[0]
                          : null

                        if (modelInit) {
                          dispatch(setCurrentModel(modelInit))
                          dispatch(getCategoryGroups(projectUid))

                          navigate(
                            `/projects/${projectUid}/contents/${
                              modelInit.id
                            }?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
                              modelInit.id
                            )}&q=`
                          )
                        } else {
                          navigate(`/projects/${projectUid}/builder`)
                        }

                        setLoading(false)
                      })
                      .catch((e) => {
                        setLoading(false)
                        dispatch(setModelsList([]))

                        navigate(`/projects/${projectUid}/builder`)
                      })
                  })
                  .catch((e) => {
                    message.error(t('error.pageNotFound'))
                    navigate(`/projects/`)
                    setLoading(false)
                  })
              })
              .catch((e) => {
                location.href = '/projects'
              })
          } else {
            location.href = '/projects'
            navigate('/projects')
          }
        })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)

          // 비밀번호 오류
          setStatus(t('error.credentials'))
        })
        .then(() => {})
    },
  })

  /**
   * 메일 변경
   * @param e
   */
  const onChangeEmail = (e) => {
    formikRequest.setFieldValue('email', e.target.value)
    setMode('request')
    setLoading(false)

    setTimeout(() => {
      document.getElementById('requestEmail')?.focus() // auto focus
      formikVerify.setFieldValue('email', '')
      formikVerify.setFieldValue('code', '')
      formikLogin.setFieldValue('email', '')
      formikVerify.setFieldValue('password', '')
    })
  }

  return (
    <>
      <Helmet>
        <title>
          {t('auth.login.title')} · {process.env.REACT_APP_NAME}
        </title>
      </Helmet>
      <div className="min-h-screen flex items-center justify-center ">
        <div className={'max-w-md w-full'}>
          <div className="pb-5 px-16 space-y-5">
            <div className="flex justify-center pb-2">
              <div className="w-32 h-24 memex-logo vertical flex-none"></div>
            </div>
            <div className={'space-y-1'}>
              <h2 className="text-center text-2xl leading-8 font-bold text-gray-900 mb-0">
                {t('auth.login.title')}
              </h2>
              <p className={'text-center text-gray-500'}>
                {t('auth.login.desc')}
              </p>
            </div>
            {/* Form - 시작 */}
            {mode === 'request' ? (
              <form onSubmit={formikRequest.handleSubmit} method="POST">
                <AlertStatus
                  status={formikRequest.status}
                  onClick={() => formikRequest.setStatus(null)}></AlertStatus>
                <div className="space-y-2">
                  <div className="block">
                    <label htmlFor="requestEmail" className="">
                      <div className={'mb-0.5'}>{t('email')}</div>
                      <Input
                        id={'requestEmail'}
                        name="email"
                        type={'email'}
                        onChange={formikRequest.handleChange}
                        value={formikRequest.values.email}
                        placeholder={t('validation.email')}
                        allowClear
                      />
                    </label>
                    {formikRequest.touched.email &&
                    formikRequest.errors.email ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikRequest.errors.email}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-4">
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    block
                    disabled={loading}
                    loading={loading}>
                    {t('auth.login.continueWithEmail')}
                  </Button>
                </div>
              </form>
            ) : (
              <></>
            )}
            {mode === 'verify' ? (
              <form onSubmit={formikVerify.handleSubmit} method="POST">
                <AlertStatus
                  status={formikVerify.status}
                  onClick={() => formikVerify.setStatus(null)}></AlertStatus>
                <div className="space-y-2">
                  <div className="block">
                    <label htmlFor="verifyEmail" className="">
                      <div className={'mb-0.5'}>{t('email')}</div>
                      <Input
                        id={'verifyEmail'}
                        name="email"
                        type="email"
                        onChange={onChangeEmail}
                        value={formikVerify.values.email}
                        placeholder={t('validation.email')}
                        allowClear
                      />
                    </label>
                    {formikVerify.touched.email && formikVerify.errors.email ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikVerify.errors.email}
                      </p>
                    ) : null}
                  </div>
                  <div className="block">
                    <p className={'leading-5 text-gray-500 mb-3'}>
                      {t('auth.login.codeRequested')}
                    </p>
                    <label htmlFor="code" className="">
                      <div className={'mb-0.5'}>{t('signupCode')}</div>
                      <Input
                        id="code"
                        name="code"
                        type="text"
                        onChange={formikVerify.handleChange}
                        value={formikVerify.values.code}
                        placeholder={t('auth.login.loginCode')}
                      />
                    </label>
                    {formikVerify.touched.code && formikVerify.errors.code ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikVerify.errors.code}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-4">
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    block
                    disabled={loading}
                    loading={loading}>
                    {t('auth.login.createNew')}
                  </Button>
                </div>
              </form>
            ) : (
              <></>
            )}
            {mode === 'login' ? (
              <form onSubmit={formikLogin.handleSubmit} method="POST">
                <AlertStatus
                  status={formikLogin.status}
                  onClick={() => formikLogin.setStatus(null)}></AlertStatus>
                <div className="space-y-2">
                  <div className="block">
                    <label htmlFor="loginEmail" className="">
                      <div className={'mb-0.5'}>{t('email')}</div>
                      <Input
                        id={'loginEmail'}
                        name="email"
                        type="email"
                        onChange={onChangeEmail}
                        value={formikLogin.values.email}
                        placeholder={t('validation.email')}
                        allowClear
                      />
                    </label>
                    {formikLogin.touched.email && formikLogin.errors.email ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikLogin.errors.email}
                      </p>
                    ) : null}
                  </div>
                  <div className="block">
                    <label htmlFor="loginPassword" className="">
                      <div className={'mb-0.5'}>{t('password')}</div>
                      <Input.Password
                        id="loginPassword"
                        name="password"
                        type="password"
                        onChange={formikLogin.handleChange}
                        value={formikLogin.values.password}
                        placeholder={t('validation.password')}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                    </label>
                    {formikLogin.touched.password &&
                    formikLogin.errors.password ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikLogin.errors.password}
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-4">
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    block
                    disabled={loading}
                    loading={loading}>
                    {t('auth.login.loginWithPassword')}
                  </Button>
                </div>
              </form>
            ) : (
              <></>
            )}
            {/* Form - 끝 */}
          </div>
          <div className={'text-center space-y-14'}>
            <Link to="/password/forget">
              <span className="text-xs text-gray-500 leading-5 hover:underline cursor-pointer">
                {t('auth.password.forget.title')}
              </span>
            </Link>
            {/* <p
              className="text-xs text-gray-500 leading-5"
              dangerouslySetInnerHTML={{
                __html: t('auth.signup.agree', {
                  linkTerms: LINK_TERMS,
                  linkPrivacy: LINK_PRIVACY,
                }),
              }}></p> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginPage
