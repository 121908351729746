import React, { useRef } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { message } from 'antd'
import CheckList from '@editorjs/checklist'
import Delimiter from '@editorjs/delimiter'
import Embed from '@editorjs/embed'
import Image from '@editorjs/image'
import InlineCode from '@editorjs/inline-code'
import LinkTool from '@editorjs/link'
import List from '@editorjs/list'
import Quote from '@editorjs/quote'
import SimpleImage from '@editorjs/simple-image'
import Header from '@editorjs/header'
import Table from '@editorjs/table'
import RawTool from '@editorjs/raw'
import Underline from '@editorjs/underline'
import { createReactEditorJS } from 'react-editor-js'
import { uploadFile } from '@/states/actions/files.actions'
import { v4 as uuidv4 } from 'uuid'
import { createMedia, getMedia } from '@/states/actions'
import { RootState } from '@/states/reducers'

const ReactEditorJS = createReactEditorJS()

interface CustomEditorProps {
  editorCore: any
  handleInstance: any
  onSave: any
  readOnly?: boolean
}

const CustomEditor = ({
  editorCore,
  handleInstance,
  onSave,
  readOnly = false,
}: CustomEditorProps) => {
  const handleInitialize = React.useCallback(async (instance) => {
    handleInstance(instance)
  }, [])
  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState

  const EDITOR_JS_TOOLS = {
    embed: Embed,
    header: Header,
    list: List,
    linkTool: LinkTool,
    image: {
      class: Image,
      config: {
        uploader: {
          uploadByFile(file) {
            let reqImg = new FormData()
            reqImg.append('files', file)

            // 파일 업로드
            return uploadFile(reqImg).then((res) => {
              const resData = res.data[0]

              // 언어별 값 선택
              const languageMap = {}
              currentProject?.languageList.forEach((lang) => {
                languageMap[lang] = {
                  name: resData.value,
                  description: '',
                }
              })

              const req = {
                type: resData.type,
                languageMap,
                fileId: resData.fileId,
                value: null,
              }

              return createMedia(currentProject?.uid, req)
                .then(async (res) => {
                  return getMedia(currentProject?.uid, res.data.data).then(
                    (res) => {
                      onSave()

                      return {
                        success: 1,
                        file: {
                          url: res.data.data.file.path,
                        },
                      }
                    }
                  )
                })
                .catch((e) => {
                  message.error(e.response.data.error)
                })
            })
          },
        },
      },
    },
    quote: Quote,
    checklist: CheckList,
    delimiter: Delimiter,
    inlineCode: InlineCode,
    simpleImage: SimpleImage,
    table: Table,
    raw: RawTool,
    underline: Underline,
  }

  return (
    <ReactEditorJS
      holder={uuidv4()}
      onInitialize={handleInitialize}
      tools={EDITOR_JS_TOOLS}
      placeholder={``}
      readOnly={readOnly}
    />
  )
}

export default CustomEditor
