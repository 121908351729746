import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Button, Empty, Spin, Modal, message } from 'antd'
import {
  PlusOutlined,
  UploadOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { LayoutProjects } from '@/components/layout'
import { RootState } from '@/states/reducers'
import {
  setMediaUploadModal,
  setMediaFormModal,
} from '@/states/actions/modals.actions'
import { MediaList } from '@/components/media'
import { useAppDispatch } from '@/states/store'

const ProjectsMediaContents = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, mediaListInit } = projectsState

  /**
   * 모든 미디어 컨텐츠 다운로드
   */
  const downloadAllMedia = () => {
    Modal.confirm({
      centered: true,
      title: t('massMediaContentsDownloadWarningTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('massMediaContentsDownloadWarningDesc'),
      okText: t('download'),
      cancelText: t('cancel'),
      onOk() {
        window.open(
          `${process.env.REACT_APP_API_FILE_URL}download/bulk/s3/${currentProject?.uid}`,
          '_blank'
        )
      },
      onCancel() {},
    })
  }

  return (
    <LayoutProjects>
      {currentProject && (
        <Helmet>
          <title>
            {t('mediaContents')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {mediaListInit ? (
        <div className={'h-screen pt-4 pb-52 px-6'}>
          <div className="flex justify-between">
            <div></div>
            {currentProject?.role !== 'VIEWER' ? (
              <div className="flex space-x-2">
                {/* <div>
                  <Button
                    type={'primary'}
                    htmlType={'button'}
                    icon={<DownloadOutlined />}
                    onClick={() => downloadAllMedia()}
                    style={{
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                    }}>
                    {t('downloadMediaContents')}
                  </Button>
                </div> */}
                <div className="flex">
                  <Button
                    type={'primary'}
                    htmlType={'button'}
                    icon={<PlusOutlined />}
                    onClick={() => dispatch(setMediaFormModal(true))}
                    style={{
                      borderTopRightRadius: '0',
                      borderBottomRightRadius: '0',
                    }}>
                    {t('addMediaContents')}
                  </Button>
                  <Button
                    type={'primary'}
                    htmlType={'button'}
                    icon={<UploadOutlined />}
                    onClick={() => dispatch(setMediaUploadModal(true))}
                    style={{
                      borderTopLeftRadius: '0',
                      borderBottomLeftRadius: '0',
                    }}>
                    {t('uploadMultipleMediaContents')}
                  </Button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={'pt-10'}>
            {/* 미디어 목록: 시작 */}
            <MediaList />
            {/* 미디어 목록: 끝 */}
          </div>
        </div>
      ) : (
        <div className={'h-screen flex justify-center items-center'}>
          <Spin />
        </div>
      )}
    </LayoutProjects>
  )
}

export default ProjectsMediaContents
