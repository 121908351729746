import { CONTENTS_SORT_KEY, DEFAULT_SORT, availableComponents } from '@/configs'
import {
  ComponentInterface,
  ComponentOptionType,
  CellInterface,
  ProjectInterface,
  CellValidationInterface,
  ContentsInterface,
  PaginationInterface,
} from '@/types'
import Cookies from 'js-cookie'

/**
 * 컴포넌트 request 설정
 * @param componentsList
 * @returns
 */
export const setComponentsRequestBody = (
  componentsList: ComponentInterface[]
) => {
  const updatedComponentList = JSON.parse(JSON.stringify(componentsList))
  setOrder(updatedComponentList)

  return updatedComponentList

  function setOrder(components) {
    components.forEach((component, index) => {
      component.order = index + 1

      if (typeof component.id === 'string') {
        delete component.id
      }

      if (component.type !== 'BLOCK' && component.childList) {
        delete component.childList
      }

      if (component.type !== 'CATEGORY' && component.selectorList) {
        delete component.selectorList
      }

      if (component.type !== 'RELATION' && component.relationList) {
        delete component.relationList
      }

      if (component.childList) {
        setOrder(component.childList)
      }
    })
  }
}

/**
 * 옵션 초기값 설정
 * @param optionType
 * @returns
 */
export const setDefaultComponentOptionValue = (
  optionType: ComponentOptionType
) => {
  let defaultValue: any = null

  switch (optionType) {
    case 'required':
      defaultValue = false
      break
    case 'unique':
      defaultValue = false
      break
    case 'multiple':
      defaultValue = false
      break
    case 'allowHtml':
      defaultValue = false
      break
    case 'minText':
      defaultValue = 0
      break
    default:
      break
  }

  return defaultValue
}

/**
 * Request Contents 설정
 * @param component
 * @param currentProject
 * @param value
 * @returns
 */
export const setContentsRequest = (
  component: ComponentInterface,
  currentProject: ProjectInterface,
  value
) => {
  if (component && component.id && currentProject) {
    const cellInfo: CellInterface = {
      componentId: component.id,
    }
    const type = component.type

    if (type === 'SINGLE_LINE_TEXT' || type === 'LONG_LINE_TEXT') {
      cellInfo.languageMap = {}
      currentProject.languageList.forEach((lang) => {
        if (cellInfo.languageMap) cellInfo.languageMap[lang] = value[lang]
      })
    } else if (
      type === 'NUMBER' ||
      type === 'BOOLEAN' ||
      type === 'DATE' ||
      type === 'EMAIL' ||
      type === 'PASSWORD'
    ) {
      cellInfo.value = value
    } else if (type === 'CATEGORY') {
      cellInfo.selectorIdList = value
    } else if (type === 'MEDIA') {
      cellInfo.mediaIdList = value
    } else if (type === 'RELATION') {
      cellInfo.relationUidList = value
    }

    return cellInfo
  } else {
    return false
  }
}

/**
 * Cell 검증
 * @param cell
 * @param currentProject
 * @returns
 */
export const checkCellValidation = (
  cell: CellInterface,
  currentProject: ProjectInterface,
  currentContents: ContentsInterface | null,
  allContentsList: ContentsInterface[],
  isImportReplace?: boolean
) => {
  const validationErrorArr: CellValidationInterface[] = []
  const componentName = cell.component?.languageMap
    ? (cell.component.languageMap[currentProject.defaultLang] as string)
    : ''

  // 필수 항목 확인
  if (cell.option?.required) {
    if (cell.type === 'SINGLE_LINE_TEXT' || cell.type === 'LONG_LINE_TEXT') {
      currentProject?.languageList.forEach((lang) => {
        if (!cell.languageMap || !cell.languageMap[lang]) {
          validationErrorArr.push({
            name: componentName + '(' + lang + ')',
            errorType: 'required',
          })
        }
      })
    } else if (
      cell.type === 'DATE' ||
      cell.type === 'EMAIL' ||
      cell.type === 'NUMBER' ||
      cell.type === 'PASSWORD'
    ) {
      if (!cell.value) {
        validationErrorArr.push({
          name: componentName,
          errorType: 'required',
        })
      }
    } else if (cell.type === 'CATEGORY') {
      if (!cell.selectorIdList?.length) {
        validationErrorArr.push({
          name: componentName,
          errorType: 'required',
        })
      }
    } else if (cell.type === 'RELATION') {
      if (!cell.relationUidList?.length) {
        validationErrorArr.push({
          name: componentName,
          errorType: 'required',
        })
      }
    }
  }

  // 고유값 확인
  if (cell.option?.unique && !isImportReplace) {
    const currentAllContentsList = allContentsList.filter(
      (c) => c.uid !== currentContents?.uid
    )

    if (cell.type === 'SINGLE_LINE_TEXT' || cell.type === 'LONG_LINE_TEXT') {
      currentProject?.languageList.forEach((lang) => {
        if (
          cell.languageMap &&
          cell.languageMap[lang] &&
          currentAllContentsList.find(
            (cnts) =>
              cnts.cellList &&
              cnts.cellList.find(
                (c) =>
                  c.component?.id === cell.component?.id &&
                  c.languageMap &&
                  cell.languageMap &&
                  c.languageMap[lang] === cell.languageMap[lang]
              )
          )
        ) {
          validationErrorArr.push({
            name: componentName + '(' + lang + ')',
            errorType: 'unique',
          })
        }
      })
    } else if (cell.type === 'EMAIL') {
      if (
        cell.value &&
        currentAllContentsList.find(
          (cnts) =>
            cnts.cellList &&
            cnts.cellList.find(
              (c) =>
                c.component?.id === cell.component?.id && cell.value === c.value
            )
        )
      ) {
        validationErrorArr.push({
          name: componentName,
          errorType: 'unique',
        })
      }
    }
  }

  return validationErrorArr
}

/**
 * 컴포넌트 평탄화
 * @param components
 * @returns
 */
export const flattenComponents = (components) => {
  const flattedComponentsArr: ComponentInterface[] = []

  var flatComponents = (compList) => {
    compList.forEach((comp) => {
      if (comp.childList) {
        flattedComponentsArr.push(comp)
        flatComponents(comp.childList)
      } else if (comp) flattedComponentsArr.push(comp)
    })
  }

  flatComponents(components)

  return flattedComponentsArr
}

/**
 * 검색, 정렬 가능 컴포넌트 설정
 * @param components
 * @returns
 */
export const setComponentsProperties = (components) => {
  const updatedComponents = components

  updatedComponents.forEach((c: ComponentInterface) => {
    const componentConfig = availableComponents.find((ac) => ac.type === c.type)

    c.searchable = componentConfig?.searchable
    c.sortable = componentConfig?.sortable
    c.exportable = componentConfig?.exportable
  })

  return updatedComponents
}

/**
 * 모델별 저장 정렬값
 * @param modelId
 * @returns
 */
export const getSavedContentsSortKey = (modelId) => {
  const oldContentsSortKey =
    Cookies.get(CONTENTS_SORT_KEY) &&
    typeof JSON.parse(Cookies.get(CONTENTS_SORT_KEY) as string) === 'object'
      ? JSON.parse(Cookies.get(CONTENTS_SORT_KEY) as string)
      : {}

  const sortValue = oldContentsSortKey[modelId]
    ? oldContentsSortKey[modelId]
    : DEFAULT_SORT

  return sortValue
}

/**
 * 문자열 복사
 * @returns {boolean}
 */
export const copyStr = (str) => {
  const string = str
  let textarea
  let result

  try {
    textarea = document.createElement('textarea')
    textarea.setAttribute('readonly', true)
    textarea.setAttribute('contenteditable', true)
    textarea.style.position = 'fixed' // prevent scroll from jumping to the bottom when focus is set.
    textarea.value = string

    document.body.appendChild(textarea)

    textarea.focus()
    textarea.select()

    const range = document.createRange()
    range.selectNodeContents(textarea)

    const sel = window.getSelection()
    sel?.removeAllRanges()
    sel?.addRange(range)

    textarea.setSelectionRange(0, textarea.value.length)
    result = document.execCommand('copy')
  } catch (err) {
    // console.error(err)
    result = null
  } finally {
    document.body.removeChild(textarea)
  }

  // manual copy fallback using prompt
  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0
    const copyHotkey = isMac ? '⌘C' : 'CTRL+C'
    result = prompt(`Press ${copyHotkey}`, string) // eslint-disable-line no-alert
    if (!result) {
      return false
    }
  }
  return true
}

/**
 * 유투브 번호 추출
 * @param url
 * @returns
 */
export const youtubeParser = (url) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)
  return match && match[2].length === 11 ? match[2] : undefined
}

/**
 * 비메오 번호 추출
 * @param url
 * @returns
 */
export const vimeoParser = (url) => {
  let regExp =
    /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
  let match = url.match(regExp)
  return match && match[5] ? match[5] : false
}

/**
 * 콘텐츠 검색
 * @param contents
 * @param sort
 * @param flattenComponentList
 * @param currentLanguage
 * @param pagination
 * @returns
 */
export const filterContentsList = (
  contents: ContentsInterface[],
  flattenComponentList: ComponentInterface[],
  currentLanguage,
  keyword: string
) => {
  let updatedContents = JSON.parse(JSON.stringify(contents))
  const keywordObj = keyword ? keyword.split('||') : null

  if (keywordObj && keywordObj.length > 1 && keywordObj[1]) {
    const searchComponent = flattenComponentList.find(
      (fc) => fc.id === Number(keywordObj[0])
    )

    // 컴포넌트 검색
    if (searchComponent) {
      if (
        searchComponent.type === 'SINGLE_LINE_TEXT' ||
        searchComponent.type === 'LONG_LINE_TEXT' ||
        searchComponent.type === 'RICH_TEXT'
      ) {
        updatedContents = updatedContents.filter(
          (uc) =>
            uc.cellList &&
            uc.cellList.find((c) => c.component.id === searchComponent.id) &&
            uc.cellList
              .find((c) => c.component.id === searchComponent.id)
              .languageMap[currentLanguage].includes(keywordObj[1])
        )
      } else if (
        searchComponent.type === 'NUMBER' ||
        searchComponent.type === 'EMAIL' ||
        searchComponent.type === 'PASSWORD' ||
        searchComponent.type === 'DATE'
      ) {
        updatedContents = updatedContents.filter(
          (uc) =>
            uc.cellList &&
            uc.cellList.find((c) => c.component.id === searchComponent.id) &&
            (
              uc.cellList.find((c) => c.component.id === searchComponent.id)
                .value + ''
            ).includes(keywordObj[1])
        )
      }
    }
    // 전체 검색
    else {
      updatedContents = updatedContents.filter(
        (uc) =>
          uc.cellList &&
          (uc.cellList.find(
            (c) =>
              (c.component.type === 'SINGLE_LINE_TEXT' ||
                c.component.type === 'LONG_LINE_TEXT' ||
                c.component.type === 'RICH_TEXT') &&
              c.languageMap &&
              c.languageMap[currentLanguage].includes(keywordObj[1])
          ) ||
            uc.cellList.find(
              (c) =>
                (c.component.type === 'NUMBER' ||
                  c.component.type === 'EMAIL' ||
                  c.component.type === 'PASSWORD' ||
                  c.component.type === 'DATE') &&
                (c.value + '').includes(keywordObj[1])
            ))
      )
    }
  }

  return updatedContents
}

/**
 * 콘텐츠 정렬, 페이징
 * @param contents
 * @param sort
 * @param flattenComponentList
 * @param currentLanguage
 * @param pagination
 * @returns
 */
export const sortPaginateContentsList = (
  contents: ContentsInterface[],
  sort,
  flattenComponentList: ComponentInterface[],
  currentLanguage,
  pagination: PaginationInterface | null
) => {
  let updatedContents = JSON.parse(JSON.stringify(contents))
  const sortObj = sort.split('.')
  const orderFlags = sortObj[1] === 'DESC' ? [-1, 1] : [1, -1]

  // 정렬
  if (sortObj[0] === 'DATE_CREATE') {
    updatedContents = updatedContents.sort((a, b) =>
      a.date.createdAt > b.date.createdAt ? orderFlags[0] : orderFlags[1]
    )
  } else if (sortObj[0] === 'DATE_UPDATE') {
    updatedContents = [
      ...updatedContents
        .filter((c) => c.date.editedAt)
        .sort((a, b) =>
          a.date.editedAt && a.date.editedAt > b.date.editedAt
            ? orderFlags[0]
            : orderFlags[1]
        ),
      ...updatedContents.filter((c) => !c.date.editedAt),
    ]
  } else {
    // 컴포넌트 정렬
    const targetComponent = flattenComponentList.find(
      (fc) => fc.id === Number(sortObj[0])
    )

    if (targetComponent) {
      if (
        targetComponent.type === 'SINGLE_LINE_TEXT' ||
        targetComponent.type === 'LONG_LINE_TEXT' ||
        targetComponent.type === 'RICH_TEXT'
      ) {
        // 비어있는 콘텐츠
        const emptyContents = updatedContents.filter(
          (uc) =>
            !uc.cellList ||
            !uc.cellList.find((c) => c.component.id === targetComponent.id) ||
            !uc.cellList.find((c) => c.component.id === targetComponent.id)
              .languageMap ||
            !uc.cellList.find((c) => c.component.id === targetComponent.id)
              .languageMap[currentLanguage]
        )

        // 값이 있는 콘텐츠
        let filledContents = updatedContents.filter(
          (uc) =>
            uc.cellList &&
            uc.cellList.find((c) => c.component.id === targetComponent.id) &&
            uc.cellList.find((c) => c.component.id === targetComponent.id)
              .languageMap &&
            uc.cellList.find((c) => c.component.id === targetComponent.id)
              .languageMap[currentLanguage]
        )

        filledContents = filledContents
          .sort((a, b) => (a.uid > b.uid ? orderFlags[0] : orderFlags[1]))
          .sort((a, b) =>
            a.cellList.find((c) => c.component.id === targetComponent.id)
              .languageMap[currentLanguage] >
            b.cellList.find((c) => c.component.id === targetComponent.id)
              .languageMap[currentLanguage]
              ? orderFlags[0]
              : orderFlags[1]
          )

        updatedContents = [...filledContents, ...emptyContents]
      } else if (
        targetComponent.type === 'NUMBER' ||
        targetComponent.type === 'EMAIL' ||
        targetComponent.type === 'PASSWORD' ||
        targetComponent.type === 'DATE'
      ) {
        // 비어있는 콘텐츠
        const emptyContents = updatedContents.filter(
          (uc) =>
            !uc.cellList ||
            !uc.cellList.find((c) => c.component.id === targetComponent.id) ||
            !uc.cellList.find((c) => c.component.id === targetComponent.id)
              .value
        )

        // 값이 있는 콘텐츠
        let filledContents = updatedContents.filter(
          (uc) =>
            uc.cellList &&
            uc.cellList.find((c) => c.component.id === targetComponent.id) &&
            uc.cellList.find((c) => c.component.id === targetComponent.id).value
        )

        filledContents = filledContents
          .sort((a, b) => (a.uid > b.uid ? orderFlags[0] : orderFlags[1]))
          .sort((a, b) =>
            a.cellList.find((c) => c.component.id === targetComponent.id)
              .value >
            b.cellList.find((c) => c.component.id === targetComponent.id).value
              ? orderFlags[0]
              : orderFlags[1]
          )

        updatedContents = [...filledContents, ...emptyContents]
      } else if (targetComponent.type === 'BOOLEAN') {
        // 비어있는 콘텐츠
        const emptyContents = updatedContents.filter(
          (uc) =>
            !uc.cellList ||
            !uc.cellList.find((c) => c.component.id === targetComponent.id) ||
            typeof uc.cellList.find(
              (c) => c.component.id === targetComponent.id
            ).value === 'undefined'
        )

        // 값이 있는 콘텐츠
        let filledContents = updatedContents.filter(
          (uc) =>
            uc.cellList &&
            uc.cellList.find((c) => c.component.id === targetComponent.id) &&
            typeof uc.cellList.find(
              (c) => c.component.id === targetComponent.id
            ).value !== 'undefined'
        )

        filledContents = filledContents
          .sort((a, b) => (a.uid > b.uid ? orderFlags[0] : orderFlags[1]))
          .sort((a, b) =>
            !a.cellList.find((c) => c.component.id === targetComponent.id)
              .value &&
            b.cellList.find((c) => c.component.id === targetComponent.id).value
              ? orderFlags[0]
              : orderFlags[1]
          )

        updatedContents = [...filledContents, ...emptyContents]
      }
    }
  }

  // 페이징
  if (pagination) {
    const newOffset =
      ((pagination.current - 1) * pagination.pageSize) % updatedContents.length
    const endOffset = newOffset + pagination.pageSize

    return updatedContents.slice(newOffset, endOffset)
  } else {
    return updatedContents
  }
}

/**
 * 파일 사이즈 출력 포맷
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 * @see https://stackoverflow.com/a/14919494
 * @returns
 */
export const humanFileSize = (bytes, si = false, dp = 1) => {
  if (bytes === 'UNLIMITED') return 'UNLIMITED'

  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B'
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return bytes.toFixed(dp) + '' + units[u]
}

/**
 * Indexed DB 저장시 필요없는 필드 제거
 * @param str
 * @returns
 */
export const removeUnnecessaryPropertiesForIndexedDB = (
  contentsList: ContentsInterface[]
) => {
  const savedContents = JSON.parse(JSON.stringify(contentsList))

  savedContents.forEach((contents) => {
    // delete s.uid
    delete contents.order
    // delete s.date

    if (contents.cellList) {
      contents.cellList.forEach((c) => {
        delete c.uid
        delete c.order

        if (c.component) {
          // delete c.component.id
          delete c.component.order

          if (c.component.type === 'RICH_TEXT') {
            delete c.languageMap
          }
        }

        if (c.option) {
          delete c.option
        }

        if (c.mediaList) {
          delete c.mediaList
        }
      })
    }
  })

  return savedContents
}
