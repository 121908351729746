import { combineReducers, Reducer, AnyAction } from 'redux'
import { RootStateInterface } from '@/states/interfaces/rootState'
import authReducer from './auth.reducer'
import modalsReducer from './modals.reducer'
import layoutReducer from './layout.reducer'
import projectsReducer from './projects.reducer'

const rootReducer: Reducer<RootStateInterface, AnyAction> =
  combineReducers<RootStateInterface>({
    auth: authReducer,
    modals: modalsReducer,
    layout: layoutReducer,
    projects: projectsReducer,
  })

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
