import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Progress } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import { UsageInterface } from '@/types'
import { humanFileSize } from '@/utils/helpers'
import { ProgressSize } from 'antd/lib/progress/progress'
import { LAYOUT_WARNING_COLOR } from '@/configs'
import { useAppDispatch } from '@/states/store'

interface ProjectsUsageBarProps {
  usage: UsageInterface | undefined
  label: string
  size?: ProgressSize
  isFile?: boolean
}

export const ProjectsUsageBar = ({
  usage,
  label,
  size,
  isFile = false,
}: ProjectsUsageBarProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )

  const { currentProject } = projectsState

  return usage ? (
    <div className="">
      <div className="flex justify-between items-center">
        <div>{label}</div>
        {!isFile ? (
          currentProject?.usage?.type !== 'UNLIMITED' ? (
            <div>
              {usage.current.toLocaleString()} / {usage.limit.toLocaleString()}
              {t('countWord')}
            </div>
          ) : (
            <div>
              {usage.current.toLocaleString()}
              {t('countWord')}
            </div>
          )
        ) : currentProject?.usage?.type !== 'UNLIMITED' ? (
          <div>
            {humanFileSize(usage.current)} / {humanFileSize(usage.limit)}
          </div>
        ) : (
          <div>{humanFileSize(usage.current)}</div>
        )}
      </div>
      <div className="flex space-x-2 justify-between items-center">
        {currentProject?.usage?.type !== 'UNLIMITED' ? (
          <Progress
            percent={((usage.current / usage.limit) as number) * 100}
            size={size ? size : 'default'}
            showInfo={false}
            strokeColor={
              usage.current >= usage.limit ? LAYOUT_WARNING_COLOR : ''
            }
          />
        ) : (
          <></>
        )}
        {currentProject?.usage?.type !== 'UNLIMITED' &&
        usage.current >= usage.limit ? (
          <div className="-mt-px">
            <CheckCircleFilled
              style={{
                color: LAYOUT_WARNING_COLOR,
              }}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}
