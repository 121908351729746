import { CancelTokenSource } from 'axios'
import { axiosInstance, FILES_URL } from '@/configs'

/**
 * 파일 업로드
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const uploadFile = (req, source?: CancelTokenSource | undefined) => {
  return axiosInstance.post(`${FILES_URL}/upload/archive`, req, {
    cancelToken: source ? source.token : undefined,
  })
}

/**
 * 파일 설명 수정
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateFileDescription = (req) => {
  return axiosInstance.put(`${FILES_URL}/description`, req)
}

/**
 * 파일 삭제
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteFile = (id) => {
  return axiosInstance.delete(`${FILES_URL}/${id}`)
}
