import React, { FC, useEffect, useState } from 'react'
import { Alert } from 'antd'

interface ChildProps {
  status: string
  onClick: (e: React.MouseEvent) => void
}

export const AlertStatus: FC<ChildProps> = (props) => {
  return props.status ? (
    <div className={'mb-5'}>
      <Alert message={props.status} type="error" closable />
    </div>
  ) : (
    <></>
  )
}
