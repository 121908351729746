import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Radio } from 'antd'
import { setCurrentLanguage } from '@/states/actions/projects.actions'
import { RootState } from '@/states/reducers'
import { LanguagesAvailable } from '@/types'
import { useAppDispatch } from '@/states/store'

export const ContentsLanguage = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, currentLanguage } = projectsState

  // State
  const [langs, setLangs] = useState<
    { label: string; value: LanguagesAvailable }[]
  >([])

  // Effect
  useEffect(() => {
    if (currentProject) {
      setLangs(
        currentProject.languageList.map((l) => {
          return { label: t('lang.' + l.toLocaleLowerCase()), value: l }
        })
      )
    }
  }, [currentProject])

  /**
   * 언어 변경
   * @param lang
   */
  const onChangeLanguage = (e) => {
    dispatch(setCurrentLanguage(e.target.value))
  }

  return currentProject ? (
    <div className="flex items-center space-x-3">
      <div className="text-gray-600">{t('contentsLanguages')}:</div>
      <div>
        <Radio.Group
          options={langs}
          onChange={onChangeLanguage}
          value={currentLanguage}
          optionType="button"
        />
      </div>
    </div>
  ) : (
    <></>
  )
}
