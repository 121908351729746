import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
import { RootState } from '@/states/reducers'
const { Sider } = Layout
import { LAYOUT_DEFAULT_BG } from '@/configs'
import { useAppDispatch } from '@/states/store'

export const LayoutSidebarProjectSettings = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { projectUid } = useParams()

  // State (Redux)
  const { layoutState, projectsState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { sidebar } = layoutState
  const { currentProject } = projectsState

  /**
   * 프로젝트 설정 메뉴
   */
  const projectSettingsMenus: any[] = [
    ...[
      {
        key: 'settings',
        label: t('project'),
        onClick: () => {
          navigate(`/projects/${projectUid}/settings`)
        },
      },
      {
        key: 'members',
        label: t('members'),
        onClick: () => {
          navigate(`/projects/${projectUid}/settings/members`)
        },
      },
    ],
    ...(process.env.REACT_APP_USAGE_HIDE === '1' ||
    currentProject?.role !== 'ADMIN'
      ? []
      : [
          {
            key: 'usage',
            label: t('usage'),
            onClick: () => {
              navigate(`/projects/${projectUid}/settings/usage`)
            },
            disabled: currentProject?.role !== 'ADMIN',
          },
        ]),
  ]

  return (
    <Sider
      theme={'light'}
      width={'auto'}
      className={'border-r border-gray-300 overflow-hidden overflow-y-auto'}
      style={{
        height: '100vh',
        backgroundColor: LAYOUT_DEFAULT_BG,
        zIndex: 100,
      }}>
      <h4 className={'py-3 px-6 text-xs font-bold uppercase leading-5 mb-0'}>
        {t('settings')}
      </h4>
      <div>
        <Menu
          theme="light"
          defaultSelectedKeys={[]}
          mode="inline"
          style={{
            background: 'transparent',
          }}
          selectedKeys={[
            location.pathname === `/projects/${projectUid}/settings`
              ? 'settings'
              : '',
            location.pathname === `/projects/${projectUid}/settings/members`
              ? 'members'
              : '',
            location.pathname === `/projects/${projectUid}/settings/usage`
              ? 'usage'
              : '',
          ]}
          items={projectSettingsMenus}
        />
      </div>
    </Sider>
  )
}
