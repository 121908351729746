import React, { useEffect, useState } from 'react'
import { Routes, useLocation } from 'react-router-dom'
import NProgress from 'nprogress'
import { notification } from 'antd'

const CustomSwitch = ({ children }) => {
  const [prevLoc, setPrevLoc] = useState('')
  const location = useLocation()

  useEffect(() => {
    setPrevLoc(location.pathname)
    NProgress.start()

    if (location.pathname === prevLoc) {
      setPrevLoc('')
    }

    notification.destroy()
  }, [location])

  useEffect(() => {
    NProgress.done()
  }, [prevLoc])

  return (
    <>
      <Routes>{children}</Routes>
    </>
  )
}

export default CustomSwitch
