import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/states/reducers'
import { Button } from 'antd'
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { setMediaListViewType } from '@/states/actions/layout.actions'
import { useAppDispatch } from '@/states/store'

export const MediaListViewType = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State
  const [viewType, setViewType] = useState('')

  // State (Redux)
  const { layoutState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { mediaListView } = layoutState

  // Effect
  useEffect(() => {
    setViewType(mediaListView)
  }, [mediaListView])

  return (
    <div className={'space-x-2.5'}>
      <Button
        type={viewType === 'card' ? 'primary' : 'ghost'}
        icon={<AppstoreOutlined />}
        onClick={() => dispatch(setMediaListViewType('card'))}
        title={t('viewType.cardView')}
      />
      <Button
        type={viewType === 'list' ? 'primary' : 'ghost'}
        icon={<UnorderedListOutlined />}
        onClick={() => dispatch(setMediaListViewType('list'))}
        title={t('viewType.tableView')}
      />
    </div>
  )
}
