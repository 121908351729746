import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ComponentInterface,
  LanguagesAvailable,
  CellInterface,
  LanguageMap,
  ContentsInterface,
} from '@/types'
import { Input, InputNumber, Select, Switch, DatePicker, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import {
  LAYOUT_DEFAULT_CELL_WIDTH,
  LAYOUT_DEFAULT_CELL_BODY_HEIGHT,
  dateFormatsMoment,
} from '@/configs'
import moment from 'moment-timezone'
import 'moment/locale/ko'
import locale from 'antd/es/date-picker/locale/ko_KR'
import {
  setContentsRelationSelectorModal,
  setMediaSelectorModal,
} from '@/states/actions/modals.actions'
import { MediaThumb } from '../media'
import { useAppDispatch } from '@/states/store'

let focusClass = 'relative cell-active bg-white z-10'

interface ContentsCellProps {
  contents: ContentsInterface
  component: ComponentInterface
  cell: CellInterface | null | undefined
  onCellChange: (
    componentId: number | undefined,
    key: string,
    value: boolean | number | string | object | null,
    lang?: LanguagesAvailable
  ) => void
  parentComponent?: ComponentInterface
  customClassName?: string
}

export const ContentsCellComponent = ({
  contents,
  component,
  cell,
  onCellChange,
  parentComponent,
  customClassName,
}: ContentsCellProps) => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { q } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const {
    currentProject,
    currentModel,
    currentLanguage,
    contentsPagination,
    contentsSort,
    contentsListLoading,
    categoriesList,
  } = projectsState

  // State
  const [isFocus, setIsFocus] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<any>(null)
  const [languageMap, setLanguageMap] = useState<LanguageMap>({})
  const [valid, setValid] = useState<boolean>(true)

  // Effect
  useEffect(() => {
    if (
      (component.type === 'SINGLE_LINE_TEXT' ||
        component.type === 'LONG_LINE_TEXT') &&
      cell &&
      cell.languageMap
    ) {
      setLanguageMap(cell.languageMap)
    } else if (cell && cell.value) {
      setCurrentValue(cell.value)
    } else if (component.type === 'RICH_TEXT' && cell) {
      getTextFromRichText(cell.languageMap)
    } else {
      setLanguageMap({})
      setCurrentValue(null)
    }
  }, [cell, component])

  // Effect
  useEffect(() => {
    let isValid = true

    if (
      component &&
      component.option?.required &&
      (!cell ||
        (cell &&
          (component.type === 'SINGLE_LINE_TEXT' ||
            component.type === 'LONG_LINE_TEXT') &&
          (!cell.languageMap || !cell.languageMap[currentLanguage])) ||
        (component.type !== 'SINGLE_LINE_TEXT' &&
          component.type !== 'LONG_LINE_TEXT' &&
          component.type !== 'CATEGORY' &&
          component.type !== 'RELATION' &&
          !cell.value) ||
        (component.type === 'CATEGORY' &&
          (!cell.selectorList || cell.selectorList.length === 0)) ||
        (component.type === 'RELATION' &&
          (!cell.relationList || cell.relationList.length === 0)))
    ) {
      isValid = false
    }

    setValid(isValid)
  }, [contents, cell, currentLanguage])

  // Memo
  const categorySelectors = useMemo(() => {
    return component.type === 'CATEGORY'
      ? categoriesList.find((c) => c.id === component.selectorGroupId)
      : null
  }, [categoriesList, component])

  /**
   * 포커스 아웃 & 셀 변경
   * @param key
   * @param value
   * @param lang
   */
  const onBlurChange = (key, value, lang) => {
    setIsFocus(false)

    // 변경 미확인
    if (
      (component.type === 'SINGLE_LINE_TEXT' ||
        component.type === 'LONG_LINE_TEXT') &&
      cell &&
      cell.languageMap &&
      languageMap &&
      languageMap[lang] === cell?.languageMap[lang]
    ) {
      return false
    } else if (
      (component.type === 'EMAIL' ||
        component.type === 'PASSWORD' ||
        component.type === 'DATE' ||
        component.type === 'SINGLE_LINE_TEXT_MONO' ||
        component.type === 'NUMBER') &&
      ((cell &&
        typeof cell.value !== 'undefined' &&
        cell.value === currentValue) ||
        (cell && typeof cell.value === 'undefined' && !currentValue))
    ) {
      return false
    }

    onCellChange(component.id, key, value, lang)
  }

  /**
   * 텍스트 변경
   * @param value
   * @param lang
   */
  const onTextChange = (value, lang) => {
    if (languageMap) {
      const updatedLanguage = JSON.parse(JSON.stringify(languageMap))
      updatedLanguage[lang] = value
      setLanguageMap(updatedLanguage)
    }
  }

  /**
   * 값 변경
   * @param value
   */
  const onValueChange = (value) => {
    setCurrentValue(value)
  }

  /**
   * 모달 수정폼 열기
   * @param contentsToBeEdited
   */
  const onContentsOpenModal = () => {
    navigate(
      `/projects/${currentProject?.uid}/contents/${currentModel?.id}?page=${
        contentsPagination.current
      }&size=${
        contentsPagination.pageSize
      }&sort=${contentsSort}&q=${searchParams.get('q')}&edit=modal&id=${
        contents.uid
      }&component=${component.devKey}`
    )
  }

  /**
   * RICH TEXT 텍스트 추출
   * @param languageMap
   * @returns
   */
  const getTextFromRichText = (languageMap) => {
    if (!languageMap || !currentProject) return false

    try {
      const tmpLanguageMap = {}

      currentProject.languageList.forEach((lang) => {
        if (languageMap[lang]) {
          const jsonRichTextData = JSON.parse(
            languageMap[lang].replace(/(?:\r\n|\r|\n)/g, ' ')
          )

          tmpLanguageMap[lang] = jsonRichTextData.blocks
            .filter((b) => b.type === 'paragraph')
            .map((b) => b.data.text)
            .join(' ')
        }
      })

      setLanguageMap(tmpLanguageMap)
    } catch (e) {
      console.log(e)
    }
  }

  return currentProject && currentModel && component ? (
    <div
      className={`text-sm cell-component ${
        valid ? '' : 'cell-component-warning'
      } ${isFocus ? '' : 'overflow-hidden'} `}
      style={{
        height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
      }}>
      {/* Single Line Text: 시작 */}
      {component.type === 'SINGLE_LINE_TEXT' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div
              key={lang}
              className={`${lang === currentLanguage ? '' : 'hidden'} ${
                isFocus ? focusClass : ''
              }`}>
              <Input
                name={component.devKey}
                autoComplete={'off'}
                required={component.option?.required}
                bordered={false}
                maxLength={component.option?.maxText}
                value={
                  languageMap && languageMap[lang]
                    ? (languageMap[lang] as string)
                    : ''
                }
                onFocus={(e) => setIsFocus(true)}
                onBlur={(e) =>
                  onBlurChange('languageMap', languageMap[lang], lang)
                }
                onPressEnter={(e) =>
                  onBlurChange('languageMap', languageMap[lang], lang)
                }
                onChange={(e) => onTextChange(e.target.value, lang)}
                style={{
                  width:
                    (component?.option?.width
                      ? component.option.width - 1
                      : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                  height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
                }}
                readOnly={currentProject.role === 'VIEWER'}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Single Line Text: 끝 */}
      {/* Single Line Text Mono: 시작 */}
      {component.type === 'SINGLE_LINE_TEXT_MONO' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div
              key={lang}
              className={`${lang === currentLanguage ? '' : 'hidden'} ${
                isFocus ? focusClass : ''
              }`}>
              <Input
                name={component.devKey}
                autoComplete={'off'}
                required={component.option?.required}
                bordered={false}
                maxLength={component.option?.maxText}
                onFocus={(e) => setIsFocus(true)}
                onBlur={(e) => onBlurChange('value', e.target.value, null)}
                onPressEnter={(e) => onBlurChange('value', currentValue, null)}
                value={cell && cell.value ? Number(cell.value) : undefined}
                onChange={(val) => onCellChange(component.id, 'value', val)}
                style={{
                  width:
                    (component?.option?.width
                      ? component.option.width - 1
                      : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                  height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
                }}
                readOnly={currentProject.role === 'VIEWER'}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Single Line Text: 끝 */}
      {/* Long Line Text: 시작 */}
      {component.type === 'LONG_LINE_TEXT' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div
              key={lang}
              className={`${lang === currentLanguage ? '' : 'hidden'} ${
                isFocus ? focusClass + ' absolute' : ''
              }`}>
              <Input.TextArea
                rows={5}
                name={component.devKey}
                required={component.option?.required}
                bordered={false}
                value={
                  languageMap && languageMap[lang]
                    ? (languageMap[lang] as string)
                    : ''
                }
                onFocus={(e) => setIsFocus(true)}
                onBlur={(e) =>
                  onBlurChange('languageMap', languageMap[lang], lang)
                }
                onChange={(e) => onTextChange(e.target.value, lang)}
                style={{
                  width:
                    (component?.option?.width
                      ? component.option.width - 1
                      : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                }}
                readOnly={currentProject.role === 'VIEWER'}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Long Line Text: 끝 */}
      {/* Rich Text: 시작 */}
      {component.type === 'RICH_TEXT' ? (
        !contentsListLoading ? (
          <div>
            {currentProject.languageList.map((lang) => (
              <div
                key={lang}
                className={`${lang === currentLanguage ? '' : 'hidden'} ${
                  isFocus ? focusClass + ' absolute' : ''
                }`}
                onClick={() => onContentsOpenModal()}>
                <div
                  className={`ant-input ant-input-borderless ant-editor-input relative cursor-pointer`}
                  style={{
                    width:
                      (component?.option?.width
                        ? component.option.width - 1
                        : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: languageMap[lang] as string,
                  }}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="py-1 px-2">
            <Spin size="small" indicator={<LoadingOutlined spin />} />
          </div>
        )
      ) : (
        <></>
      )}
      {/* Rich Text: 끝 */}
      {/* Number: 시작 */}
      {component.type === 'NUMBER' ? (
        <div className={`${isFocus ? focusClass : ''}`}>
          <InputNumber
            name={component.devKey}
            required={component.option?.required}
            bordered={false}
            controls={false}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => setIsFocus(false)}
            min={component.option?.min}
            max={component.option?.max}
            value={cell && cell.value ? Number(cell.value) : undefined}
            onChange={(val) => onCellChange(component.id, 'value', val)}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Number: 끝 */}
      {/* Category: 시작 */}
      {component.type === 'CATEGORY' && currentLanguage ? (
        <div className={`${isFocus ? focusClass : ''}`}>
          {component.option?.multiple ? (
            <Select
              mode="multiple"
              showArrow
              className={`w-full`}
              onFocus={(e) => setIsFocus(true)}
              onBlur={(e) => setIsFocus(false)}
              // onBlur={(e) => onBlurChange('selectorIdList', e)}
              bordered={false}
              allowClear={!component?.option?.required}
              onChange={(val) =>
                onCellChange(component.id, 'selectorIdList', val)
              }
              value={
                cell && cell.selectorList
                  ? cell.selectorList.map((s) => s.id)
                  : []
              }
              style={{
                width:
                  (component?.option?.width
                    ? component.option.width - 1
                    : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              }}
              disabled={currentProject.role === 'VIEWER'}>
              {categorySelectors?.selectorList.map((selector) => (
                <Select.Option key={selector.id} value={selector.id}>
                  {selector.languageMap[currentLanguage]}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select
              className={`w-full`}
              onFocus={(e) => setIsFocus(true)}
              onBlur={(e) => setIsFocus(false)}
              bordered={false}
              allowClear={!component?.option?.required}
              onChange={(val) =>
                onCellChange(component.id, 'selectorIdList', [val])
              }
              value={
                cell && cell.selectorList && cell.selectorList.length
                  ? cell.selectorList.map((s) => s.id)[0]
                  : null
              }
              style={{
                width:
                  (component?.option?.width
                    ? component.option.width - 1
                    : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
              }}
              disabled={currentProject.role === 'VIEWER'}>
              {categorySelectors?.selectorList.map((selector) => (
                <Select.Option key={selector.id} value={selector.id}>
                  {selector.languageMap[currentLanguage]}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Category: 끝 */}
      {/* Boolean: 시작 */}
      {component.type === 'BOOLEAN' ? (
        <div className="p-1">
          <Switch
            defaultChecked={cell?.value === true}
            onChange={(checked) => onCellChange(component.id, 'value', checked)}
            disabled={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Boolean 끝 */}
      {/* Date: 시작 */}
      {component.type === 'DATE' ? (
        <div className={`${isFocus ? focusClass : ''}`}>
          <DatePicker
            locale={locale}
            autoComplete={'off'}
            picker={component?.option?.dateFormats}
            className={`${isFocus ? focusClass : ''}`}
            bordered={false}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => setIsFocus(false)}
            onChange={(val, dateString) =>
              onCellChange(component.id, 'value', dateString)
            }
            value={
              cell && cell.value
                ? moment(cell.value as string, 'YYYY-MM-DDTHH:mm:ss')
                : undefined
            }
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            disabled={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Date 끝 */}
      {/* Email: 시작 */}
      {component.type === 'EMAIL' ? (
        <div className={`${isFocus ? focusClass : ''}`}>
          <Input
            type={'email'}
            autoComplete={'off'}
            name={component.devKey}
            className={`${isFocus ? focusClass : ''}`}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => onBlurChange('value', e.target.value, null)}
            onPressEnter={(e) => onBlurChange('value', currentValue, null)}
            onChange={(e) => onValueChange(e.target.value)}
            required={component.option?.required}
            bordered={false}
            value={currentValue}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Email: 끝 */}
      {/* Password: 시작 */}
      {component.type === 'PASSWORD' ? (
        <div className={`${isFocus ? focusClass : ''}`}>
          <Input.Password
            autoComplete={'off'}
            name={component.devKey}
            className={`${isFocus ? focusClass : ''}`}
            onFocus={(e) => setIsFocus(true)}
            onBlur={(e) => onBlurChange('value', e.target.value, null)}
            onPressEnter={(e) => onBlurChange('value', currentValue, null)}
            onChange={(e) => onValueChange(e.target.value)}
            required={component.option?.required}
            bordered={false}
            value={currentValue}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Password: 끝 */}
      {/* Media: 시작 */}
      {component.type === 'MEDIA' ? (
        <div>
          {!contentsListLoading ? (
            <div
              className={`overflow-x-auto px-2 ${
                currentProject.role === 'VIEWER' ? '' : 'cursor-pointer'
              }  ${isFocus ? focusClass : ''}`}
              style={{
                width:
                  (component?.option?.width
                    ? component.option.width - 1
                    : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
                height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
              }}
              onClick={() =>
                dispatch(
                  setMediaSelectorModal(
                    currentProject.role !== 'VIEWER',
                    cell ? cell : undefined,
                    component,
                    contents
                  )
                )
              }>
              <ul
                className="space-x-1 text-xs flex items-center w-max mb-0"
                style={{
                  height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
                }}>
                {cell?.mediaList?.map((media) => (
                  <li
                    className="pr-2 border border-gray-400 bg-gray-200 flex items-center"
                    key={media.id}>
                    <MediaThumb
                      media={media}
                      // width="20"
                      outerWidth={20}
                      outerHeight={20}
                      textSize="xs"
                      thumbnail="SMALL"
                    />
                    <div className="pl-2 leading-5">
                      {currentProject &&
                      media.languageMap &&
                      media.languageMap[currentProject.defaultLang]
                        ? (media.languageMap[currentProject.defaultLang]
                            ?.name as string)
                        : ''}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="py-1 px-2">
              <Spin size="small" indicator={<LoadingOutlined spin />} />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Media: 끝 */}
      {/* Relation: 시작 */}
      {component.type === 'RELATION' ? (
        <div>
          <div
            className={`overflow-x-auto text-xs px-2 pt-2 ${
              currentProject.role === 'VIEWER' ? '' : 'cursor-pointer'
            }  ${isFocus ? focusClass : ''}`}
            style={{
              width:
                (component?.option?.width
                  ? component.option.width - 1
                  : LAYOUT_DEFAULT_CELL_WIDTH - 1) + 'px',
              height: LAYOUT_DEFAULT_CELL_BODY_HEIGHT + 'px',
            }}
            onClick={() =>
              dispatch(
                setContentsRelationSelectorModal(
                  currentProject.role !== 'VIEWER',
                  cell ? cell : undefined,
                  component,
                  contents
                )
              )
            }>
            {cell?.relationList ? cell.relationList.length : 0}개
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Relation: 끝 */}
    </div>
  ) : (
    <></>
  )
}
