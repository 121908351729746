import React, { useEffect, useState, useMemo } from 'react'
import { createSelector } from '@reduxjs/toolkit'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
  FileOutlined,
  LinkOutlined,
  PlayCircleOutlined,
  YoutubeOutlined,
} from '@ant-design/icons'
import { FileInterface, MediaInterface } from '@/types'
import { vimeoParser, youtubeParser } from '@/utils/helpers'

interface MediaFileInfoProps {
  media: MediaInterface
  cover?: boolean
  width?: string
  height?: string
  outerWidth?: number
  outerHeight?: number
  textSize: string
  noLazy?: boolean
  region?: 'full' | 'square'
  rotation?: number
  thumbnail?: 'SMALL' | 'MEDIUM' | 'LARGE'
}

export const MediaThumb = ({
  media,
  cover,
  width,
  height,
  outerWidth,
  outerHeight,
  textSize,
  noLazy,
  region,
  rotation,
  thumbnail,
}: MediaFileInfoProps) => {
  const { t, i18n } = useTranslation()

  // 썸네일
  const thumbnailFile: FileInterface | null | undefined = useMemo(
    () =>
      media && thumbnail && media.file && media.file.thumbnailList
        ? media.file.thumbnailList.find((t) => t.type === thumbnail)
        : media.file,
    [media]
  )

  /**
   * 이미지
   * @returns
   */
  const MediaImage = () => {
    return thumbnailFile ? (
      <>
        {cover ? (
          <div
            className={`overflow-hidden flex-none flex items-center bg-cover bg-no-repeat bg-center`}
            style={{
              width: outerWidth ? outerWidth + 'px' : '100%',
              height: outerHeight ? outerHeight + 'px' : '100%',
              backgroundImage: `url(${thumbnailFile.path})`,
            }}></div>
        ) : !noLazy ? (
          <LazyLoadImage
            src={thumbnailFile.path}
            alt={media.file.meta.type.split('/')[1]}
            className="w-full"
          />
        ) : (
          <img
            src={thumbnailFile.path}
            alt={media.file.meta.type.split('/')[1]}
            className="w-full"
          />
        )}
      </>
    ) : !noLazy ? (
      <LazyLoadImage
        src={media.file?.path.replace(
          '/full/max/0/',
          `/${region ? region : 'full'}/^${
            width || height
              ? (width ? width : '') + ',' + (height ? height : '')
              : 'max'
          }/${rotation ? rotation : '0'}/`
        )}
        alt={media.file.meta.type.split('/')[1]}
        className="w-full"
      />
    ) : (
      <img
        src={media.file?.path.replace(
          '/full/max/0/',
          `/${region ? region : 'full'}/^${
            width || height
              ? (width ? width : '') + ',' + (height ? height : '')
              : 'max'
          }/${rotation ? rotation : '0'}/`
        )}
        alt={media.file.meta.type.split('/')[1]}
        className="w-full"
      />
    )
  }

  /**
   * 이미지 파일
   * @returns
   */
  const MediaFileImage = () => {
    return (
      <div
        className={`overflow-hidden flex-none flex items-center`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        <MediaImage />
      </div>
    )
  }

  /**
   * 이미지가 아닌 파일
   * @returns
   */
  const MediaFileNonImage = () => {
    return (
      <div
        className={` bg-gray-300 flex-none flex items-center justify-center`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        <FileOutlined className={`text-${textSize}`} />
      </div>
    )
  }

  /**
   * 유투브 URL
   * @returns
   */
  const MediaUrlYoutube = () => {
    return (
      <div
        className={`overflow-hidden flex-none flex items-center relative`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        {!noLazy ? (
          <LazyLoadImage
            height={height}
            src={`https://img.youtube.com/vi/${youtubeParser(
              media.value
            )}/0.jpg`}
            alt={media.value}
            className="w-full max-h-full"
          />
        ) : (
          <img
            src={`https://img.youtube.com/vi/${youtubeParser(
              media.value
            )}/0.jpg`}
            alt={media.value}
            className="w-full max-h-full"
          />
        )}
        <div
          className="absolute w-full h-full flex items-center justify-center"
          style={{
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}>
          <YoutubeOutlined
            className={` text-${textSize}`}
            style={{
              color: 'white',
            }}
          />
        </div>
      </div>
    )
  }

  /**
   * 비메오 URL
   * @returns
   */
  const MediaUrlVimeo = () => {
    return (
      <div
        className={`overflow-hidden flex-none flex items-center relative`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        {!noLazy ? (
          <LazyLoadImage
            src={`https://vumbnail.com/${vimeoParser(media.value)}.jpg`}
            height={outerHeight}
            alt={media.value}
            className="w-full max-h-full"
          />
        ) : (
          <img
            src={`https://vumbnail.com/${vimeoParser(media.value)}.jpg`}
            alt={media.value}
            className="w-full max-h-full"
          />
        )}
        <div
          className="absolute w-full h-full flex items-center justify-center"
          style={{
            backgroundColor: 'rgba(0,0,0,0.4)',
          }}>
          <PlayCircleOutlined
            className={`text-${textSize}`}
            style={{
              color: 'white',
            }}
          />
        </div>
      </div>
    )
  }

  /**
   * 일반 URL
   * @returns
   */
  const MediaUrlLink = () => {
    return (
      <div
        className={` bg-gray-300 flex-none flex items-center justify-center`}
        style={{
          width: outerWidth ? outerWidth + 'px' : 'auto',
          height: outerHeight ? outerHeight + 'px' : 'auto',
        }}>
        <LinkOutlined className={`text-${textSize}`} />
      </div>
    )
  }

  return media ? (
    <div>
      {media.mediaType === 'FILE' &&
      media.file &&
      media.file.type === 'IMAGE' ? (
        <MediaFileImage />
      ) : (
        <></>
      )}
      {media.mediaType === 'FILE' &&
      media.file &&
      media.file.type !== 'IMAGE' ? (
        <MediaFileNonImage />
      ) : (
        <></>
      )}
      {media.mediaType === 'URL' && youtubeParser(media.value) ? (
        <MediaUrlYoutube />
      ) : (
        <></>
      )}
      {media.mediaType === 'URL' && vimeoParser(media.value) ? (
        <MediaUrlVimeo />
      ) : (
        <></>
      )}
      {media.mediaType === 'URL' &&
      !youtubeParser(media.value) &&
      !vimeoParser(media.value) ? (
        <MediaUrlLink />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
