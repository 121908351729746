import { AppDispatch } from '@/states/store'
import { ActionTypesLayout } from '@/states/interfaces'
import { MediaListViewType, ProjectListViewType } from '@/types'

/**
 * 계정 메뉴 설정
 */
export const setAccountMenu = (isOpen: boolean) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesLayout.SET_ACCOUNT_MENU,
    payload: {
      isOpen: isOpen,
    },
  })
}

/**
 * GNB 설정
 */
export const toggleGnb = (isCollapse: boolean) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesLayout.TOGGLE_GNB,
    payload: {
      isCollapse: isCollapse,
    },
  })
}

/**
 * 사이드바 설정
 */
export const setSidebar = (isOpen: boolean) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesLayout.SET_SIDEBAR,
    payload: {
      isOpen: isOpen,
    },
  })
}

/**
 * 뷰 타입 설정
 * @param splitSize
 * @returns {function(*): void}
 */
export const setSplit = (splitSize: number[]) => (dispatch) => {
  dispatch({
    type: ActionTypesLayout.SET_SPLIT_SIZE,
    payload: {
      splitSize: splitSize,
    },
  })
}

/**
 * 프로젝트 목록 보기 타입 설정
 * @param viewType
 * @returns {function(*): void}
 */
export const setProjectListViewType =
  (viewType: ProjectListViewType) => (dispatch) => {
    dispatch({
      type: ActionTypesLayout.SET_PROJECT_LIST_VIEW_TYPE,
      payload: {
        viewType: viewType,
      },
    })
  }

/**
 * 미디어 목록 보기 타입 설정
 * @param viewType
 * @returns {function(*): void}
 */
export const setMediaListViewType =
  (viewType: MediaListViewType) => (dispatch) => {
    dispatch({
      type: ActionTypesLayout.SET_MEDIA_LIST_VIEW_TYPE,
      payload: {
        viewType: viewType,
      },
    })
  }
