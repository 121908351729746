import moment from 'moment'
import { AppDispatch } from '@/states/store'
import { ActionTypesProjects } from '@/states/interfaces'
import { axiosInstance, dateFormatReq, PROJECTS_URL } from '@/configs'
import { CellInterface, ContentsInterface, PaginationInterface } from '@/types'

/**
 * 콘텐츠 목록 불러오기
 * @param projectUid
 * @returns
 */
export const getContentsListSearch = (projectUid, modelId, req) => {
  return projectUid && modelId
    ? axiosInstance.post(
        `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/search`,
        req
      )
    : Promise.reject()
}

/**
 * 콘텐츠 불러오기
 * @param projectUid
 * @returns
 */
export const getContentsItem = (projectUid, modelId, id) => {
  return projectUid && modelId
    ? axiosInstance.get(
        `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${id}`
      )
    : Promise.reject()
}

/**
 * 콘텐츠 목록 다시불러오기
 * @param reload
 * @returns {function(*): *}
 */
export const setContentsListReload = (reload) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CONTENTS_LIST_RELOAD,
    payload: {
      reload,
    },
  })
}

/**
 * 콘텐츠 목록 로딩
 * @param isLoading
 * @returns
 */
export const setContentsListLoading = (isLoading) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CONTENTS_LIST_LOADING,
    payload: {
      isLoading,
    },
  })
}

/**
 * 콘텐츠 목록 설정
 * @param list
 * @returns
 */
export const setContentsList =
  (
    list: ContentsInterface[],
    pagination,
    keyword,
    sort,
    isPreserveCondition = false
  ) =>
  (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_LIST,
      payload: {
        contentsList: list,
        pagination,
        keyword,
        sort,
        isPreserveCondition,
      },
    })
  }

/**
 * 콘텐츠 목록 초기화

 * @returns
 */
export const resetContentsList = () => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.RESET_CONTENTS_LIST,
  })
}

/**
 * 콘텐츠 목록에 추가
 * @param item
 * @returns
 */
export const addContentsList =
  (item, skipSort = false) =>
  (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.ADD_CONTENTS_TO_LIST,
      payload: {
        contentsAdded: item,
        skipSort,
      },
    })
  }

/**
 * 콘텐츠 수정
 * @param item
 * @returns
 */
export const updateContentsItem = (item: ContentsInterface) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.UPDATE_CONTENTS_ITEM,
    payload: {
      contentsItem: item,
    },
  })
}

/**
 * 콘텐츠 삭제
 * @param contentsItemUidList
 * @returns
 */
export const deleteContentsList =
  (contentsItemUidList: string[]) => (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.DELETE_CONTENTS_ITEM,
      payload: {
        contentsItemUidList: contentsItemUidList,
      },
    })
  }

/**
 * 체크된 콘텐츠 목록 설정
 * @param list
 * @returns
 */
export const setCheckedContentsList =
  (list: ContentsInterface[]) => (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CHECKED_CONTENTS_LIST,
      payload: {
        checkedContentsList: list,
      },
    })
  }

/**
 * 콘텐츠 목록 설정
 * @param pagination
 * @returns
 */
export const setContentsConditions =
  (pagination: PaginationInterface, keyword, sort: string, modelId?: number) =>
  (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_CONDITIONS,
      payload: {
        pagination,
        keyword,
        sort,
        modelId,
      },
    })
  }
/**
 * 콘텐츠 목록 설정
 * @param pagination
 * @returns
 */
export const setContentsPagination =
  (pagination: PaginationInterface) => (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_PAGINATION,
      payload: {
        pagination,
      },
    })
  }

/**
 * 콘텐츠 정렬 설정
 * @param sort
 * @param modelId
 * @returns
 */
export const setContentsSort =
  (sort: string, modelId?: number) => (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_SORT,
      payload: {
        sort,
        modelId,
      },
    })
  }

/**
 * 콘텐츠 검색어 설정
 * @param keyword
 * @returns
 */
export const setContentsKeyword = (keyword: string) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CONTENTS_KEYWORD,
    payload: {
      keyword,
    },
  })
}

/**
 * Req 컴포넌트 설정
 * @param componentsArr
 * @returns
 */
export const setContentsReqComponents =
  (componentsArr: CellInterface[]) => (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_REQ_COMPONENTS,
      payload: {
        componentsArr: componentsArr,
      },
    })
  }

/**
 * 콘텐츠 정보 불러오기
 * @param projectUid
 * @param modelId
 * @param id
 * @returns
 */
export const getContents = (projectUid, modelId, id) => {
  return axiosInstance.get(
    `${PROJECTS_URL}/${projectUid}/media/${modelId}/contents/${id}`
  )
}

/**
 * 콘텐츠 추가
 * @param projectUid
 * @param modelId
 * @param req
 * @returns
 */
/* export const createMedia = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/media`, req)
} */
export const createContents = (projectUid, modelId, req) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents`,
    req
  )
}

/**
 * 콘텐츠 여러개 추가
 * @param projectUid
 * @param modelId
 * @param req
 * @returns
 */
/* export const createMedia = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/media`, req)
} */
export const createContentsList = (projectUid, modelId, req) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/list`,
    req
  )
}

/**
 * 콘텐츠 수정
 * @param projectUid
 * @param modelId
 * @param id
 * @param req
 * @returns
 */
export const updateContents = (projectUid, modelId, id, req) => {
  const contentsItem = req

  // Generate Request params
  contentsItem.cellList.forEach((c, clIdx) => {
    // 카테고리
    if (
      c.component.type === 'CATEGORY' &&
      c.selectorList &&
      typeof c.selectorIdList === 'undefined'
    ) {
      c.selectorIdList = c.selectorList.map((s) => s.id)
    } else c.component.type === 'CATEGORY'

    // 미디어
    if (
      c.component.type === 'MEDIA' &&
      c.mediaList &&
      typeof c.mediaIdList === 'undefined'
    ) {
      c.mediaIdList = c.mediaList.map((s) => s.id)
    }

    // 연관콘텐츠
    if (
      c.component.type === 'RELATION' &&
      c.relationList &&
      typeof c.relationUidList === 'undefined'
    ) {
      c.relationUidList = c.relationList.map((s) => s.uid)
    }

    // 날짜
    if (
      c.component?.type === 'DATE' &&
      c.value &&
      typeof c.value === 'string'
    ) {
      if (c.option?.dateFormats === 'year') {
        c.value = moment(c.value, 'YYYY').format(dateFormatReq)
      } else if (c.option?.dateFormats === 'month') {
        c.value = moment(c.value, 'YYYY-MM').format(dateFormatReq)
      } else if (c.option?.dateFormats === 'date') {
        c.value = moment(c.value, 'YYYY-MM-DD').format(dateFormatReq)
      }
    }

    if (typeof c.languageMap === 'undefined') c.languageMap = {}
    if (typeof c.value === 'undefined') c.value = null
    if (typeof c.selectorIdList === 'undefined') c.selectorIdList = []
    if (typeof c.mediaIdList === 'undefined') c.mediaIdList = []
    if (typeof c.relationUidList === 'undefined') c.relationUidList = []

    c.componentId = c.component.id

    // 필요없는 데이터 제거
    if (c.component) delete c.component
    if (c.content) delete c.content
    if (c.selectorList) delete c.selectorList
    if (c.mediaList) delete c.mediaList
    if (c.relationList) delete c.relationList
  })

  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${id}`,
    contentsItem
  )
}

/**
 * 콘텐츠 삭제
 * @param projectUid
 * @param modelId
 * @param id
 * @returns
 */
export const deleteContents = (projectUid, modelId, id) => {
  return axiosInstance.delete(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${id}`
  )
}

/**
 * 콘텐츠 삭제
 * @param projectUid
 * @param modelId
 * @param id
 * @param uidArray
 * @returns
 */
export const deleteMultiContents = (projectUid, modelId, uidArray) => {
  return axiosInstance.delete(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents`,
    {
      data: uidArray,
    }
  )
}

/**
 * 콘텐츠 모두 삭제
 * @param projectUid
 * @param modelId
 * @returns
 */
export const truncateContents = (projectUid, modelId) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/truncate`
  )
}

/**
 * 셀 추가
 * @param projectUid
 * @param modelId
 * @param contentsId
 * @param req
 * @returns
 */
export const createCell = (projectUid, modelId, contentsId, req) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${contentsId}/cells`,
    req
  )
}

/**
 * 셀 수정
 * @param projectUid
 * @param modelId
 * @param contentsId
 * @param id
 * @param req
 * @returns
 */
export const updateCell = (projectUid, modelId, contentsId, id, req) => {
  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/models/${modelId}/contents/${contentsId}/cells/${id}`,
    req
  )
}

/**
 * 콘텐츠 Cell 설정
 * @param contentsUid
 * @param cellUid
 * @param title
 * @param modelTitle
 * @returns
 */
export const setContentsCellItem =
  (
    contentsUid: string,
    cellUid: string,
    relationUid: string,
    title: string,
    modelTitle: string
  ) =>
  (dispatch) => {
    return dispatch({
      type: ActionTypesProjects.SET_CONTENTS_CELL_ITEM,
      payload: {
        contentsUid,
        cellUid,
        relationUid,
        title,
        modelTitle,
      },
    })
  }
