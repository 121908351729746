import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/states/reducers'
import { useAppDispatch } from '@/states/store'

interface ProjectsLogoProps {
  onClick?: (e: React.MouseEvent) => void
}

export const ProjectsLogo = ({ onClick }: ProjectsLogoProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { layoutState, projectsState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { gnb } = layoutState
  const { currentProject } = projectsState

  return (
    <div
      className={`${gnb ? 'py-1.5' : 'py-2'} px-4 border-b cursor-pointer`}
      onClick={onClick}>
      <div className={`w-full flex items-center ${gnb ? 'space-x-3' : ''}`}>
        <div
          className={'w-6 h-6 bg-cover bg-gray-300 flex-none'}
          style={{
            backgroundImage: `url(${
              currentProject?.image?.path ? currentProject?.image?.path : ''
            })`,
          }}></div>
        {gnb ? (
          <div className={'grow overflow-hidden'}>
            <div className={'leading-5 truncate'}>{currentProject?.name}</div>
            <div className={'text-xs text-gray-500 leading-4 -mt-0.5 truncate'}>
              {currentProject?.description}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
