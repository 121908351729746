import {
  ProjectInterface,
  ModelInterface,
  LanguagesAvailable,
  MediaInterface,
  ContentsInterface,
  PaginationInterface,
  CellInterface,
  ProjectAccessInterface,
  ProjectUsageInterface,
  SelectorGroupInterface,
} from '@/types'

export enum ActionTypesProjects {
  SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT', // 현재 프로젝트 설정
  SET_CURRENT_PROJECT_USAGE = 'SET_CURRENT_PROJECT_USAGE', // 현재 프로젝트 요금제 설정
  SET_PROJECT_LIST = 'SET_PROJECT_LIST', // 프로젝트 목록 설정
  SET_MODEL_LIST = 'SET_MODEL_LIST', // 모델 목록 설정
  SET_CURRENT_MODEL = 'SET_CURRENT_MODEL', // 현재 모델 설정
  SET_TMP_MODEL = 'SET_TMP_MODEL', // 임시 모델 설정
  SET_MEDIA_LIST = 'SET_MEDIA_LIST', // 미디어 목록 설정
  SET_NEW_MEDIA_LIST = 'SET_NEW_MEDIA_LIST', // 추가된 미디어 목록 설정
  SET_CHECKED_MEDIA_LIST = 'SET_CHECKED_MEDIA_LIST', // 체크된 미디어 목록 설정
  SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE', // 현재 언어 설정
  ADD_MEDIA_TO_LIST = 'ADD_MEDIA_TO_LIST', // 미디어 추가
  SET_CONTENTS_LIST_RELOAD = 'SET_CONTENTS_LIST_RELOAD', // 콘텐츠 목록 리로딩 설정
  SET_CONTENTS_LIST_LOADING = 'SET_CONTENTS_LIST_LOADING', // 콘텐츠 목록 로딩 설정
  SET_CONTENTS_LIST = 'SET_CONTENTS_LIST', // 콘텐츠 목록 설정
  RESET_CONTENTS_LIST = 'RESET_CONTENTS_LIST', // 콘텐츠 목록 초기화
  UPDATE_CONTENTS_ITEM = 'UPDATE_CONTENTS_ITEM', // 콘텐츠 아이템 수정
  DELETE_CONTENTS_ITEM = 'DELETE_CONTENTS_ITEM', // 콘텐츠 아이템 삭제
  SET_CHECKED_CONTENTS_LIST = 'SET_CHECKED_CONTENTS_LIST', // 체크된 콘텐츠 목록 설정
  ADD_CONTENTS_TO_LIST = 'ADD_CONTENTS_TO_LIST', // 콘텐츠 추가
  SET_CONTENTS_CONDITIONS = 'SET_CONTENTS_CONDITIONS', // 콘텐츠 조건 설정
  SET_CONTENTS_PAGINATION = 'SET_CONTENTS_PAGINATION', // 콘텐츠 페이지네이션 설정
  SET_CONTENTS_SORT = 'SET_CONTENTS_SORT', // 콘텐츠 정렬 설정
  SET_CONTENTS_KEYWORD = 'SET_CONTENTS_KEYWORD', // 콘텐츠 검색어 설정
  SET_CONTENTS_REQ_COMPONENTS = 'SET_CONTENTS_REQ_COMPONENTS', // Request 컴포넌트 설정
  SET_ACCESS_LIST = 'SET_ACCESS_LIST', // 액세스 목록 설정
  SET_CONTENTS_CELL_ITEM = 'SET_CONTENTS_CELL_ITEM', // 콘텐츠 Cell 설정
  SET_CATEGORIES_LIST = 'SET_CATEGORIES_LIST', // 카테고리 목록 설정
}

export type ActionsProjects =
  | ProjectsSetProjectList
  | ProjectsSetModelList
  | ProjectsSetCurrentProject
  | ProjectsSetCurrentModel
  | ProjectsSetCurrentProjectUsage
  | ProjectsSetTmpModel
  | ProjectsSetMediaList
  | ProjectsSetNewMediaList
  | ProjectsSetCheckedMediaList
  | ProjectsSetCurrentLanguage
  | ProjectsAddMediaToList
  | ProjectsSetContentsListReload
  | ProjectsSetContentsListLoading
  | ProjectsSetContentsList
  | ProjectsResetContentsList
  | ProjectsUpdateContentsItem
  | ProjectsDeleteContentsItem
  | ProjectsSetCheckedContentsList
  | ProjectsAddContentsToList
  | ProjectsSetContentsConditions
  | ProjectsSetContentsPagination
  | ProjectsSetContentsSort
  | ProjectsSetContentsKeyword
  | ProjectsSetContentsReqComponents
  | ProjectsSetAccessList
  | ProjectsSetContentsCellItem
  | ProjectsSetCategoriesList

export interface ProjectsSetProjectList {
  type: ActionTypesProjects.SET_PROJECT_LIST
  payload: {
    projects: ProjectInterface[]
  }
}

export interface ProjectsSetModelList {
  type: ActionTypesProjects.SET_MODEL_LIST
  payload: {
    models: ModelInterface[]
  }
}

export interface ProjectsSetCurrentProject {
  type: ActionTypesProjects.SET_CURRENT_PROJECT
  payload: {
    project: ProjectInterface | null
    myId: number | undefined | null
  }
}
export interface ProjectsSetCurrentProjectUsage {
  type: ActionTypesProjects.SET_CURRENT_PROJECT_USAGE
  payload: {
    usage: ProjectUsageInterface
  }
}

export interface ProjectsSetCurrentModel {
  type: ActionTypesProjects.SET_CURRENT_MODEL
  payload: {
    model: ModelInterface | null
    isPreserveContents: boolean | undefined
  }
}
export interface ProjectsSetTmpModel {
  type: ActionTypesProjects.SET_TMP_MODEL
  payload: {
    model: ModelInterface | null
  }
}

export interface ProjectsSetMediaList {
  type: ActionTypesProjects.SET_MEDIA_LIST
  payload: {
    mediaList: MediaInterface[]
  }
}

export interface ProjectsSetNewMediaList {
  type: ActionTypesProjects.SET_NEW_MEDIA_LIST
  payload: {
    mediaList: MediaInterface[]
    uid: string
  }
}

export interface ProjectsSetCheckedMediaList {
  type: ActionTypesProjects.SET_CHECKED_MEDIA_LIST
  payload: {
    checkedMediaList: MediaInterface[]
  }
}

export interface ProjectsSetCurrentLanguage {
  type: ActionTypesProjects.SET_CURRENT_LANGUAGE
  payload: {
    language: LanguagesAvailable | ''
  }
}

export interface ProjectsAddMediaToList {
  type: ActionTypesProjects.ADD_MEDIA_TO_LIST
  payload: {
    mediaAdded: MediaInterface
  }
}

export interface ProjectsSetContentsListReload {
  type: ActionTypesProjects.SET_CONTENTS_LIST_RELOAD
  payload: {
    reload: boolean
  }
}

export interface ProjectsSetContentsListLoading {
  type: ActionTypesProjects.SET_CONTENTS_LIST_LOADING
  payload: {
    isLoading: boolean
  }
}

export interface ProjectsSetContentsList {
  type: ActionTypesProjects.SET_CONTENTS_LIST
  payload: {
    contentsList: ContentsInterface[]
    pagination: PaginationInterface
    sort: string
    modelId?: number
    keyword: string
    isPreserveCondition?: boolean
  }
}
export interface ProjectsResetContentsList {
  type: ActionTypesProjects.RESET_CONTENTS_LIST
}

export interface ProjectsUpdateContentsItem {
  type: ActionTypesProjects.UPDATE_CONTENTS_ITEM
  payload: {
    contentsItem: ContentsInterface
  }
}

export interface ProjectsDeleteContentsItem {
  type: ActionTypesProjects.DELETE_CONTENTS_ITEM
  payload: {
    contentsItemUidList: string[]
  }
}

export interface ProjectsSetCheckedContentsList {
  type: ActionTypesProjects.SET_CHECKED_CONTENTS_LIST
  payload: {
    checkedContentsList: ContentsInterface[]
  }
}

export interface ProjectsAddContentsToList {
  type: ActionTypesProjects.ADD_CONTENTS_TO_LIST
  payload: {
    contentsAdded: ContentsInterface
    skipSort: boolean
  }
}

export interface ProjectsSetContentsConditions {
  type: ActionTypesProjects.SET_CONTENTS_CONDITIONS
  payload: {
    pagination: PaginationInterface
    sort: string
    modelId?: number
    keyword: string
  }
}

export interface ProjectsSetContentsPagination {
  type: ActionTypesProjects.SET_CONTENTS_PAGINATION
  payload: {
    pagination: PaginationInterface
  }
}

export interface ProjectsSetContentsSort {
  type: ActionTypesProjects.SET_CONTENTS_SORT
  payload: {
    sort: string
    modelId?: number
  }
}
export interface ProjectsSetContentsKeyword {
  type: ActionTypesProjects.SET_CONTENTS_KEYWORD
  payload: {
    keyword: string
  }
}

export interface ProjectsSetContentsReqComponents {
  type: ActionTypesProjects.SET_CONTENTS_REQ_COMPONENTS
  payload: {
    componentsArr: CellInterface[]
  }
}

export interface ProjectsSetAccessList {
  type: ActionTypesProjects.SET_ACCESS_LIST
  payload: {
    accessList: ProjectAccessInterface[]
  }
}
export interface ProjectsSetContentsCellItem {
  type: ActionTypesProjects.SET_CONTENTS_CELL_ITEM
  payload: {
    contentsUid: string
    cellUid: string
    relationUid: string
    title: string
    modelTitle: string
  }
}

export interface ProjectsSetCategoriesList {
  type: ActionTypesProjects.SET_CATEGORIES_LIST
  payload: {
    categoriesList: SelectorGroupInterface[]
  }
}
