import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
const { Sider } = Layout
import {
  LAYOUT_SIDEBAR_WIDTH,
  LAYOUT_DEFAULT_BG,
  LAYOUT_GNB_WIDTH,
} from '@/configs'
import { useAppDispatch } from '@/states/store'

export const LayoutSidebarAuthSettings = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  /**
   * 설정 메뉴
   */
  const settingsMenu = [
    {
      key: 'account',
      label: t('myAccount'),
      onClick: () => {
        navigate(`/settings/account`)
      },
    },
  ]

  return (
    <Sider
      theme={'light'}
      width={LAYOUT_SIDEBAR_WIDTH}
      className={'border-r border-gray-300 overflow-y-auto'}
      style={{
        height: '100vh',
        position: 'fixed',
        left: LAYOUT_GNB_WIDTH,
        top: 0,
        bottom: 0,
        backgroundColor: LAYOUT_DEFAULT_BG,
        zIndex: 100,
      }}>
      <h4 className={'py-3 px-6 text-xs font-bold uppercase leading-5 mb-0'}>
        {t('settings')}
      </h4>
      <div>
        <Menu
          theme="light"
          defaultSelectedKeys={[]}
          mode="inline"
          style={{
            background: 'transparent',
          }}
          selectedKeys={[
            location.pathname === '/settings/account' ? 'account' : '',
          ]}
          items={settingsMenu}
        />
      </div>
    </Sider>
  )
}
