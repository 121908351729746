import axios from 'axios'
import {
  setLogout,
  setUserAccessToken,
  setUserRefreshToken,
  getToken,
} from '@/utils/auth'
import { issueToken } from '@/states/actions/auth.actions'

// Ignore 401 logout
const ignoreIntercepts = ['/validation/emails', '/login', '/members/token']

// Axios Settings
let axiosConfig = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  // headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
})

// Add access token
axiosConfig.interceptors.request.use(
  (config) => {
    const token = getToken()

    if (token && config && config.headers) {
      config.headers['Access-Token'] = token
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Refresh token
axiosConfig.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config } = error

    const originalRequest = config

    // Refresh Token 만료
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      error.response.data.message === 'EXPIRED_TOKEN' &&
      originalRequest.url.includes('/auth/token')
    ) {
      setLogout(true)
    }
    // Access 토큰 만료
    else if (
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      error.response.data.message === 'EXPIRED_TOKEN' &&
      !originalRequest._retry
    ) {
      try {
        // 토큰 재발행
        const res = await issueToken()
        const newAccessToken = res.data.accessToken
        const newRefreshToken = res.data.refreshToken

        setUserAccessToken(newAccessToken.token)
        setUserRefreshToken(newRefreshToken.token)

        axiosConfig.defaults.headers.common[
          'Access-Token'
        ] = `${newAccessToken.token}`

        if (!originalRequest.url.includes('/file/upload/archive')) {
          axiosConfig.defaults.headers.common['Content-Type'] =
            'application/json'
        }
        originalRequest._retry = true

        if (!originalRequest.url.includes('/file/upload/archive')) {
          originalRequest.headers = {
            ...originalRequest.headers,
            'Access-Token': `${newAccessToken.token}`,
            'Content-Type': 'application/json',
          }
        }

        return axiosConfig(originalRequest)
      } catch (e) {
        console.log(e)
      }
    } else if (
      error.response &&
      error.response.status &&
      error.response.status === 401 &&
      error.response.data.message === 'INVALID_TOKEN'
    ) {
      setLogout(true)
    }

    return Promise.reject(error)
  }
)

export const axiosInstance = axiosConfig
