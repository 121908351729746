import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  Button,
  Typography,
  Input,
  Row,
  Col,
  message,
  Tooltip,
} from 'antd'
import {
  CheckOutlined,
  CloseCircleOutlined,
  InteractionOutlined,
} from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import { setModelFormModal } from '@/states/actions/modals.actions'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { LAYOUT_MODAL_WIDTH } from '@/configs'
import {
  getModels,
  createModel,
  setCurrentModel,
  updateModel,
  getModelData,
} from '@/states/actions/models.actions'
import { setComponentFormModal } from '@/states/actions/modals.actions'
import { AlertStatus } from '@/components/common/common.alert'
import { ComponentInterface } from '@/types'
import { v4 as uuidv4 } from 'uuid'
import {
  getProjectUsageData,
  setCurrentProject,
} from '@/states/actions/projects.actions'
import { useAppDispatch } from '@/states/store'

const initialModelFormValues = {
  name: '',
  devKey: '',
  description: '',
}

export const ModelsFormModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { modalsState, projectsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { modelFormModal, modelInfo } = modalsState
  const { currentProject, modelList } = projectsState

  // State
  const [loading, setLoading] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    if (modelFormModal) {
      setTimeout(() => {
        document.getElementById('modelFormName')?.focus()
      })

      if (!modelInfo) {
        formikModelForm.validateForm()
      }
    } else {
      resetForm()
    }
  }, [modelFormModal])

  useEffect(() => {
    if (modelInfo && currentProject) {
      formikModelForm.setFieldValue(
        'name',
        modelInfo.languageMap[currentProject.defaultLang]
      )
      formikModelForm.setFieldValue('devKey', modelInfo.devKey)
      formikModelForm.setFieldValue('description', modelInfo.description)
    }
  }, [modelInfo])

  // Validation
  const validationModelFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    devKey: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikModelForm = useFormik({
    initialValues: initialModelFormValues,
    validationSchema: validationModelFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (!currentProject) return

      // 중복 개발키 확인
      if (
        (!modelInfo && modelList.find((m) => m.devKey === values.devKey)) ||
        (modelInfo &&
          modelList.find(
            (m) => m.devKey === values.devKey && m.devKey !== modelInfo.devKey
          ))
      ) {
        message.warning(t('error.duplicatedDevKey'))
        document.getElementById('modelFormDevKey')?.focus()
        return false
      }

      setStatus(null)
      setLoading(true)

      // 언어별 값 선택
      const languageMap = {}
      currentProject.languageList.forEach((lang) => {
        languageMap[lang] = values.name
      })

      const req = {
        languageMap,
        devKey: values.devKey,
        componentList: [] as ComponentInterface[],
        order: modelList.length,
      }

      if (!modelInfo) {
        await createModel(currentProject?.uid, req)
          .then((res) => {
            getModelData(currentProject?.uid, res.data.data).then((res) => {
              const newModel = res.data.data

              // 사용량 갱신
              getProjectUsageData(currentProject?.uid)
                .then((res) => {
                  const updatedProject = currentProject
                  updatedProject.usage = res.data

                  dispatch(setCurrentProject(updatedProject))
                  dispatch(setCurrentModel(newModel))
                  dispatch(setModelFormModal(false))
                  dispatch(getModels(currentProject?.uid))
                  dispatch(setComponentFormModal(true))
                })
                .catch((e) => {
                  message.error(e.response.data.error)
                })
            })
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(e.response.data.error)
          })
      } else {
        req.componentList = modelInfo.componentList
          ? modelInfo.componentList
          : []

        await updateModel(currentProject?.uid, modelInfo.id, req)
          .then((res) => {
            dispatch(setModelFormModal(false))
            dispatch(getModels(currentProject?.uid, modelInfo.id))
          })
          .catch((e) => {
            setSubmitting(false)
            setStatus(e.response.data.error)
          })
      }

      setLoading(false)
    },
  })

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikModelForm.resetForm()
    setLoading(false)
  }

  /**
   * 랜덤 개발키 생성
   */
  const generateRandomDevKey = () => {
    formikModelForm.setFieldValue('devKey', uuidv4())
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={modelFormModal}
      maskClosable={false}
      onCancel={() => dispatch(setModelFormModal(false))}
      title={modelInfo ? t('editModel') : t('createModel')}
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => dispatch(setModelFormModal(false))}>
              {t('cancel')}
            </Button>
          </div>
          <div>
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              onClick={() => formikModelForm.submitForm()}
              disabled={loading}
              loading={loading}>
              {modelInfo ? t('update') : t('continue')}
            </Button>
          </div>
        </div>,
      ]}>
      <>
        <form onSubmit={formikModelForm.handleSubmit} method="POST">
          <AlertStatus
            status={formikModelForm.status}
            onClick={() => formikModelForm.setStatus(null)}></AlertStatus>
          <div className={'space-y-6'}>
            <div className={'border-b pb-1.5'}>
              <Typography.Title level={5} className={'mt-0 mb-3'}>
                {modelInfo ? t('editModel') : t('addModel')}
              </Typography.Title>
            </div>
            <Row gutter={24}>
              <Col span={12}>
                <div className="block">
                  <label htmlFor="modelFormName" className="">
                    <div className={'mb-2'}>
                      {t('modelName')} <span className="text-red-500">*</span>
                    </div>
                    <Input
                      id={'modelFormName'}
                      name="name"
                      onChange={formikModelForm.handleChange}
                      value={formikModelForm.values.name}
                    />
                  </label>
                  {formikModelForm.touched.name &&
                  formikModelForm.errors.name ? (
                    <p className="my-1 text-xs text-red-500">
                      {formikModelForm.errors.name}
                    </p>
                  ) : null}
                </div>
              </Col>
              <Col span={12}>
                <div className="space-y-4">
                  <div className="block">
                    <label htmlFor="modelFormDevKey" className="">
                      <div className={'mb-2'}>
                        {t('devKey')} <span className="text-red-500">*</span>
                      </div>
                      <Input.Group compact>
                        <Input
                          id={'modelFormDevKey'}
                          name="devKey"
                          onChange={formikModelForm.handleChange}
                          value={formikModelForm.values.devKey}
                          style={{
                            width: `calc(100% - 2rem)`,
                          }}
                        />
                        <Tooltip title={t('generateRandomDevKey')}>
                          <Button
                            icon={<InteractionOutlined />}
                            onClick={() => generateRandomDevKey()}
                          />
                        </Tooltip>
                      </Input.Group>
                    </label>
                    {formikModelForm.touched.devKey &&
                    formikModelForm.errors.devKey ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikModelForm.errors.devKey}
                      </p>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </>
    </Modal>
  )
}
