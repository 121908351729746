/**
 * Cookie Keys
 */
export const XSRF_TOKEN_KEY = 'XSRF-TOKEN' // CSRF Token
export const USER_ACCESS_TOKEN_KEY = 'mmx_acs_usr_tkn' // Auth access token
export const USER_REFRESH_TOKEN_KEY = 'mmx_rfs_usr_tkn' // Auth refresh token
export const USER_INFO_KEY = 'mmx_usr_inf' // Auth User Info
export const USER_ID_KEY = 'mmx_usr_id' // Auth User ID
export const LOGIN_EMAIL_KEY = 'mmx_lgn_eml' // Login email
export const SPLIT_KEY = 'mmx_spl_val' // Split value
export const GNB_TOGGLE_KEY = 'mmx_gnb_tgl' // GNB Toggle
export const COMPONENTS_QUICK_BUTTONS_KEY = 'mmx_cmp_qbt' // Component quick buttons toggle
export const DISPLAY_MODE_KEY = 'mmx_dsp_mod' // Display mode
export const PROJECT_LIST_VIEW_MODE_KEY = 'mmx_plv_mod' // Project list view mode
export const PROJECT_INFO_KEY = 'mmx_prj_inf' // Project info key
export const MEDIA_LIST_VIEW_MODE_KEY = 'mmx_mlv_mod' // Media list view mode
export const CONTENTS_LIST_VIEW_MODE_KEY = 'mmx_clv_mod' // Contents list view mode
export const CONTENTS_SORT_KEY = 'mmx_cnt_srt' // Contents Sort information
