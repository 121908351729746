/**
 * 이용약관 링크
 */
export const LINK_TERMS = `https://rebel9.notion.site/b1181098649646e4a80e69697cd530b1`

/**
 * 개인정보처리방침 링크
 */
export const LINK_PRIVACY = `https://rebel9.notion.site/7c15028680794415aed87b651fcc728a`

/**
 * 사용법 링크
 */
export const LINK_HELP = `https://rebel9.notion.site/d4780b2853fb44018d26a20d96235d02`

/**
 * 서비스 상태 링크
 */
export const LINK_STATUSPAGE = 'https://memex.statuspage.io'
