import React, { useState, useEffect, useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Input, InputNumber, Select, Switch, DatePicker, Tooltip } from 'antd'
import {
  CaretDownFilled,
  CaretRightFilled,
  CodeOutlined,
  DiffOutlined,
  KeyOutlined,
  AuditOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import 'moment/locale/ko'
import locale from 'antd/es/date-picker/locale/ko_KR'
import { ComponentInterface, LanguagesAvailable, CellInterface } from '@/types'
import { dateFormatsMoment } from '@/configs'
import { RootState } from '@/states/reducers'
import { MediaSelector } from '../media'
import { ContentsRelationSelector } from '../contents'

import CustomEditor from './contents.editorjs'

interface ComponentsItemProps {
  flattenCells: CellInterface[]
  component: ComponentInterface
  curLang: LanguagesAvailable
  onCellChange: (
    componentId: number | undefined,
    key: string,
    value: boolean | number | string | object | null,
    lang?: LanguagesAvailable
  ) => void
  cell: CellInterface | null | undefined
  parentComponent?: ComponentInterface
  customClassName?: string
}

export const ContentsFormComponent = ({
  flattenCells,
  component,
  curLang,
  onCellChange,
  cell,
  parentComponent,
  customClassName,
}: ComponentsItemProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const {
    currentProject,
    currentModel,
    reqComponents,
    categoriesList,
    currentLanguage,
  } = projectsState
  const { contentsFormModal, contentsInfo } = modalsState

  // State
  const [tmpCell, setTmpCell] = useState<CellInterface | null | undefined>(null)
  const [showChildren, setShowChildren] = useState<boolean>(true)
  const [editorRefs, setEditorRefs] = useState({})

  // Effect
  useEffect(() => {
    setTmpCell(cell ? JSON.parse(JSON.stringify(cell)) : null)
  }, [cell])

  useEffect(() => {
    if (!contentsFormModal) {
      setTmpCell(null)
    }
  }, [contentsFormModal])

  // Memo
  const categorySelectors = useMemo(() => {
    return component.type === 'CATEGORY'
      ? categoriesList.find((c) => c.id === component.selectorGroupId)
      : null
  }, [categoriesList, component])

  /**
   * 임시 Cell 정보 변경
   * @param key
   * @param value
   * @param lang
   */
  const onChangeTmpCell = (key, value, lang?: LanguagesAvailable) => {
    const updatedtmpCell = JSON.parse(JSON.stringify(tmpCell))

    if (updatedtmpCell && updatedtmpCell.languageMap) {
      if (lang) {
        updatedtmpCell[key][lang] = value
      } else {
        updatedtmpCell[key] = value
      }

      setTmpCell(updatedtmpCell)
    }
  }

  /**
   * Cell 정보 수정
   * @param key
   * @param lang
   */
  const onBlurCell = (key, lang?: LanguagesAvailable) => {
    onCellChange(
      component.id,
      key,
      tmpCell && lang ? tmpCell[key][lang] : tmpCell ? tmpCell[key] : null,
      lang
    )
  }

  /**
   * 텍스트 에디터 instance 설정
   * @param instance
   * @param lang
   */
  const onHandleInstance = async (instance, lang) => {
    const updatedRefs = editorRefs
    updatedRefs[lang] = instance
    setEditorRefs(updatedRefs)

    const targetCell = contentsInfo?.cellList.find(
      (c) => c?.component?.id === cell?.component?.id
    )

    try {
      await instance.dangerouslyLowLevelInstance.isReady.then(() => {
        const editorJsObj =
          targetCell && targetCell.languageMap && targetCell.languageMap[lang]
            ? JSON.parse(
                (targetCell.languageMap[lang] as string).replace(
                  /(?:\r\n|\r|\n)/g,
                  '<br/>'
                ) as string
              )
            : null

        if (editorJsObj && editorJsObj.blocks.length > 0) {
          instance.dangerouslyLowLevelInstance.render(editorJsObj)
        } else {
          instance.dangerouslyLowLevelInstance.clear()
        }
      })
    } catch (reason) {
      console.log('Editor error: ' + reason)
    }
  }

  /**
   * 텍스트 에디터 저장
   * @param lang
   */
  const onEditorSave = async (lang: LanguagesAvailable) => {
    await setTimeout(async () => {
      // @ts-ignore
      const savedData = await editorRefs[lang]?.save()

      onCellChange(component.id, 'languageMap', JSON.stringify(savedData), lang)
    })
  }

  return currentProject && currentModel ? (
    <div
      id={`contents-component-${component.devKey}`}
      className={`text-sm ${
        component.type === 'BLOCK' ? 'border border-gray-300 rounded-sm' : ''
      }
      ${
        component.type === 'MEDIA' || component.type === 'RELATION'
          ? 'border border-gray-300 rounded-sm p-4'
          : ''
      }
      `}>
      <div
        className={`flex items-center ${
          component.type === 'BLOCK'
            ? 'bg-gray-200 hover:bg-gray-300 p-3 border-b border-gray-300 cursor-pointer'
            : 'mb-2'
        }`}
        onClick={() => setShowChildren(!showChildren)}>
        {component.type === 'BLOCK' ? (
          <div className={`flex-none w-6 h-6 flex justify-center items-center`}>
            {!showChildren ? <CaretRightFilled /> : <CaretDownFilled />}
          </div>
        ) : (
          <></>
        )}
        <div className="grow flex overflow-hidden">
          <div className="text-sm leading-5 truncate flex items-center space-x-1">
            {component.option && component.option.required ? (
              <Tooltip
                title={t('optionsType.required.desc')}
                className="cursor-help">
                <span className="text-red-500">*</span>
              </Tooltip>
            ) : (
              ''
            )}
            <span className="flex space-x-1">
              <span className={`${component.option?.title ? 'font-bold' : ''}`}>
                {component.languageMap[currentProject.defaultLang]}
              </span>
              <span className="text-gray-600 text-sm">
                ({component.devKey})
              </span>
            </span>
            {component.option?.title ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.title.desc')}
                  className="cursor-help">
                  <KeyOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.unique ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.unique.desc')}
                  className="cursor-help">
                  <AuditOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.allowHtml ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.allowHtml.desc')}
                  className="cursor-help">
                  <CodeOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.multiple ? (
              <span className="flex items-center">
                <Tooltip
                  title={t(`optionsType.multiple.${component.type}.desc`)}
                  className="cursor-help">
                  <DiffOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
            {component.option?.multipleMedia ? (
              <span className="flex items-center">
                <Tooltip
                  title={t('optionsType.multipleMedia.desc')}
                  className="cursor-help">
                  <DiffOutlined />
                </Tooltip>
              </span>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* Single Line Text or Email: 시작 */}
      {component.type === 'SINGLE_LINE_TEXT' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div key={lang} className={lang === curLang ? '' : 'hidden'}>
              <Input
                name={component.devKey}
                autoComplete={'off'}
                required={component.option?.required}
                value={
                  tmpCell && tmpCell.languageMap && tmpCell.languageMap[lang]
                    ? (tmpCell.languageMap[lang] as string)
                    : ''
                }
                maxLength={component.option?.maxText}
                showCount
                onChange={(e) =>
                  onChangeTmpCell('languageMap', e.target.value, lang)
                }
                onBlur={(e) => onBlurCell('languageMap', lang)}
                readOnly={currentProject.role === 'VIEWER'}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Single Line Text or Email: 끝 */}
      {/* Long Line Text: 시작 */}
      {component.type === 'LONG_LINE_TEXT' ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div key={lang} className={lang === curLang ? '' : 'hidden'}>
              <Input.TextArea
                rows={5}
                name={component.devKey}
                required={component.option?.required}
                value={
                  tmpCell && tmpCell.languageMap && tmpCell.languageMap[lang]
                    ? (tmpCell.languageMap[lang] as string)
                    : ''
                }
                onChange={(e) =>
                  onChangeTmpCell('languageMap', e.target.value, lang)
                }
                onBlur={(e) => onBlurCell('languageMap', lang)}
                readOnly={currentProject.role === 'VIEWER'}
              />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Long Line Text: 끝 */}
      {/* Rich Text: 시작 */}
      {component.type === 'RICH_TEXT' &&
      contentsFormModal &&
      cell &&
      reqComponents.find((rc) => rc.component?.id === component.id) ? (
        <div>
          {currentProject.languageList.map((lang) => (
            <div key={lang} className={lang === curLang ? '' : 'hidden'}>
              <div
                id={`rich-text-${component.id}-${lang}`}
                className="ant-input ant-input-rich-text"
                onBlur={() => onEditorSave(lang)}>
                {CustomEditor ? (
                  <CustomEditor
                    editorCore={editorRefs[lang]}
                    handleInstance={(instance) =>
                      onHandleInstance(instance, lang)
                    }
                    onSave={() => {
                      onEditorSave(lang)
                    }}
                    readOnly={currentProject.role === 'VIEWER'}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      {/* Rich Text: 끝 */}
      {/* Number: 시작 */}
      {component.type === 'NUMBER' ? (
        <div>
          <InputNumber
            name={component.devKey}
            autoComplete={'off'}
            required={component.option?.required}
            min={component.option?.min}
            max={component.option?.max}
            value={cell && cell.value ? Number(cell.value) : undefined}
            onChange={(val) => onChangeTmpCell('value', val)}
            onBlur={(e) => onBlurCell('value')}
            readOnly={currentProject.role === 'VIEWER'}
          />
          {component?.option?.min || component?.option?.max ? (
            <p className="text-xs text-gray-500 mb-0 mt-0.5">
              {component?.option?.min} ~ {component?.option?.max}
            </p>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Number: 끝 */}
      {/* Category: 시작 */}
      {component.type === 'CATEGORY' && curLang ? (
        <div>
          {component.option?.multiple ? (
            <Select
              mode="multiple"
              showArrow
              className="w-full"
              allowClear={!component?.option?.required}
              value={
                cell && cell.selectorIdList && cell.selectorIdList.length
                  ? cell.selectorIdList
                  : []
              }
              onChange={(val) =>
                onCellChange(component.id, 'selectorIdList', val)
              }
              disabled={currentProject.role === 'VIEWER'}>
              {categorySelectors?.selectorList.map((selector) => (
                <Select.Option key={selector.id} value={selector.id}>
                  {selector.languageMap[curLang]}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select
              className="w-full"
              allowClear={!component?.option?.required}
              value={
                cell && cell.selectorIdList && cell.selectorIdList.length
                  ? cell.selectorIdList
                  : null
              }
              onChange={(val) =>
                onCellChange(component.id, 'selectorIdList', [val])
              }
              disabled={currentProject.role === 'VIEWER'}>
              {categorySelectors?.selectorList.map((selector) => (
                <Select.Option key={selector.id} value={selector.id}>
                  {selector.languageMap[curLang]}
                </Select.Option>
              ))}
            </Select>
          )}
        </div>
      ) : (
        <></>
      )}
      {/* Category: 끝 */}
      {/* Boolean: 시작 */}
      {component.type === 'BOOLEAN' ? (
        <div>
          <Switch
            checked={!!(cell && cell.value)}
            onChange={(checked) => onCellChange(component.id, 'value', checked)}
            disabled={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Boolean 끝 */}
      {/* Date: 시작 */}
      {component.type === 'DATE' ? (
        <div>
          <DatePicker
            locale={locale}
            picker={component?.option?.dateFormats}
            value={
              cell && cell.value
                ? moment(cell.value as string, 'YYYY-MM-DDTHH:mm:ss')
                : undefined
            }
            onChange={(val, dateString) =>
              onCellChange(component.id, 'value', dateString)
            }
            disabled={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Date 끝 */}
      {/* Email: 시작 */}
      {component.type === 'EMAIL' ? (
        <div>
          <Input
            type={'email'}
            autoComplete={'off'}
            name={component.devKey}
            required={component.option?.required}
            value={tmpCell && tmpCell.value ? (tmpCell.value as string) : ''}
            onChange={(e) => onChangeTmpCell('value', e.target.value)}
            onBlur={(e) => onBlurCell('value')}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Email: 끝 */}
      {/* Password: 시작 */}
      {component.type === 'PASSWORD' ? (
        <div>
          <Input.Password
            autoComplete={'off'}
            name={component.devKey}
            required={component.option?.required}
            value={tmpCell && tmpCell.value ? (tmpCell.value as string) : ''}
            onChange={(e) => onChangeTmpCell('value', e.target.value)}
            onBlur={(e) => onBlurCell('value')}
            readOnly={currentProject.role === 'VIEWER'}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Password: 끝 */}
      {/* Component Block: 시작 */}
      {component.type === 'BLOCK' ? (
        <div className={`p-6 bg-white ${showChildren ? '' : 'hidden'}`}>
          <div className="space-y-2">
            {component.childList?.map((subComponent, index) => (
              <ContentsFormComponent
                key={subComponent.id}
                flattenCells={flattenCells}
                component={subComponent}
                onCellChange={onCellChange}
                cell={
                  flattenCells
                    ? flattenCells.find(
                        (cell) => cell?.componentId === subComponent.id
                      )
                    : null
                }
                curLang={curLang}
                parentComponent={component}
                customClassName="bg-gray-100 shadow-sm border border-gray-200 rounded"
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {/* Component Block: 끝 */}
      {/* Media: 시작 */}
      {component.type === 'MEDIA' ? (
        <div>
          <MediaSelector
            component={component}
            cell={
              flattenCells
                ? flattenCells.find(
                    (cell) => cell?.componentId === component.id
                  )
                : null
            }
            mediaList={cell && cell.mediaList ? cell.mediaList : []}
            onSelect={(mediaList) => {
              onCellChange(component.id, 'mediaList', mediaList)
            }}
            noLazy
          />
        </div>
      ) : (
        <></>
      )}
      {/* Media: 끝 */}
      {/* Relation: 시작 */}
      {component.type === 'RELATION' ? (
        <div>
          <ContentsRelationSelector
            component={component}
            cell={
              flattenCells
                ? flattenCells.find(
                    (cell) => cell?.componentId === component.id
                  )
                : null
            }
            relationList={cell && cell.relationList ? cell.relationList : []}
            onSelect={(contentsList) => {
              onCellChange(component.id, 'relationList', contentsList)
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {/* Relation: 끝 */}
    </div>
  ) : (
    <></>
  )
}
