import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  Button,
  Typography,
  Input,
  Select,
  Upload,
  Row,
  Col,
} from 'antd'
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop'
import { RootState } from '@/states/reducers'
import { setProjectFormModal } from '@/states/actions/modals.actions'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import {
  LAYOUT_MODAL_WIDTH,
  ProjectUsageProperties,
  DEFAULT_PROJECT_PLAN,
} from '@/configs'
import { languagesConfig } from '@/configs/languages.config'
import { UploadFile } from 'antd/es/upload/interface'
import { createProject, getProjects } from '@/states/actions/projects.actions'
import { uploadFile } from '@/states/actions/files.actions'
import { AlertStatus } from '@/components/common/common.alert'
import { LanguagesAvailable, ProjectUsagePropertiesInterface } from '@/types'
import { useAppDispatch } from '@/states/store'

const initialProjectFormValues: {
  name: string
  languages: LanguagesAvailable[]
  defaultLanguage: LanguagesAvailable | ''
  description: string
} = {
  name: '',
  languages: [],
  defaultLanguage: '',
  description: '',
}
export const ProjectsFormModal = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { modalsState } = useSelector(
    (state: RootState) => ({
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const { projectFormModal, projectInfo } = modalsState

  // State
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<string>('')
  const [projectPlan, setProjectPlan] = useState<
    ProjectUsagePropertiesInterface | undefined
  >(ProjectUsageProperties.find((p) => p.price === DEFAULT_PROJECT_PLAN))

  // Effect
  useEffect(() => {
    if (projectFormModal) {
      setTimeout(() => {
        document.getElementById('projectFormName')?.focus()
      })

      if (!projectInfo) {
        formikProjectForm.validateForm()
      }
    } else {
      resetForm()
    }
  }, [projectFormModal])

  useEffect(() => {
    if (projectInfo) {
      formikProjectForm.setFieldValue('name', projectInfo.name)
      formikProjectForm.setFieldValue('description', projectInfo.description)
      formikProjectForm.setFieldValue('languages', projectInfo.languageMap)
      formikProjectForm.setFieldValue(
        'defaultLanguage',
        projectInfo.defaultLang
      )
    }
  }, [projectInfo])

  // Validation
  const validationProjectFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    languages: Yup.array().min(1, t('validation.minLanguage')),
    defaultLanguage: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikProjectForm = useFormik({
    initialValues: initialProjectFormValues,
    validationSchema: validationProjectFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setStatus(null)
      setLoading(true)

      // Language Map
      const languageMap = {}
      values.languages.forEach((lang) => {
        languageMap[lang] = lang === values.defaultLanguage
      })

      const req = {
        type: 'ARCHIVE',
        name: values.name,
        description: values.description,
        languageMap: languageMap,
        imageId: null,
      }

      // 프로젝트 이미지 업로드
      const reqImg = new FormData()

      if (fileList && fileList.length) {
        // @ts-ignore
        reqImg.append('files', fileList[0])

        await uploadFile(reqImg).then((res) => {
          req.imageId = res.data[0].fileId
        })
      }

      await createProject(req)
        .then((res) => {
          setLoading(false)

          dispatch(getProjects())

          dispatch(setProjectFormModal(false))
        })
        .catch((e) => {
          setLoading(false)
          setSubmitting(false)
          setStatus(e.response.data.error)
        })
    },
  })

  useEffect(() => {
    if (
      formikProjectForm.values.defaultLanguage &&
      !formikProjectForm.values.languages.includes(
        formikProjectForm.values.defaultLanguage
      )
    ) {
      formikProjectForm.setFieldValue('defaultLanguage', '')
    }
  }, [formikProjectForm.values.languages])

  // Upload props
  const uploadProps = {
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const fileAdded = file
          fileAdded.status = 'done'
          fileAdded.url = e.target.result
          setFileList([fileAdded])
        }
      }
      reader.readAsDataURL(file)

      return false
    },
    fileList,
  }

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikProjectForm.resetForm()
    setLoading(false)
    setFileList([])
  }

  /**
   * 이미지 프리뷰
   * @param file
   */
  const onHandlePreview = (file) => {
    setPreviewUrl(file.url)
    setShowPreview(true)
  }

  /**
   * 가용 언어 선택
   * @param val
   */
  const onHandleLanguages = (val) => {
    if (
      projectPlan &&
      projectPlan.maxLangauges &&
      val.length > projectPlan.maxLangauges
    )
      return false

    formikProjectForm.setFieldValue('languages', val)
  }

  return (
    <Modal
      width={LAYOUT_MODAL_WIDTH}
      closeIcon={<CloseCircleOutlined title={t('close')} />}
      open={projectFormModal}
      maskClosable={false}
      onCancel={() => dispatch(setProjectFormModal(false))}
      title={projectInfo ? t('editProject') : t('createProject')}
      footer={[
        <div key={'footer'} className={'flex justify-between items-center'}>
          <div>
            <Button
              type="primary"
              ghost
              onClick={() => dispatch(setProjectFormModal(false))}>
              {t('cancel')}
            </Button>
          </div>
          <div>
            <Button
              type={'primary'}
              icon={<CheckOutlined />}
              onClick={() => formikProjectForm.submitForm()}
              disabled={loading}
              loading={loading}>
              {projectInfo ? t('update') : t('create')}
            </Button>
          </div>
        </div>,
      ]}>
      <>
        <form onSubmit={formikProjectForm.handleSubmit} method="POST">
          <AlertStatus
            status={formikProjectForm.status}
            onClick={() => formikProjectForm.setStatus(null)}></AlertStatus>
          <div className={'space-y-6'}>
            <div className={'border-b pb-1.5'}>
              <Typography.Title level={5} className={'mt-0 mb-3'}>
                {projectInfo ? t('editProject') : t('createProject')} -{' '}
                {projectPlan?.price} {t('plan')}
              </Typography.Title>
            </div>
            <Row>
              <Col span={8}>
                <div className="block">
                  <label htmlFor="" className="">
                    <div className={'mb-2'}>
                      {t('photo')}{' '}
                      <span className={'text-gray-500 ml-1'}>(optional)</span>
                    </div>
                    <ImgCrop
                      modalTitle={t('editImage')}
                      modalOk={t('confirm')}
                      modalCancel={t('cancel')}>
                      <Upload
                        {...uploadProps}
                        accept="image/*"
                        listType={'picture-card'}
                        maxCount={1}
                        onPreview={onHandlePreview}>
                        {fileList && fileList.length
                          ? t('editPhoto')
                          : t('addPhoto')}
                      </Upload>
                    </ImgCrop>
                  </label>
                </div>
              </Col>
              <Col span={16}>
                <div className="space-y-4">
                  <div className="block">
                    <label htmlFor="projectFormName" className="">
                      <div className={'mb-2'}>{t('projectName')}</div>
                      <Input
                        id={'projectFormName'}
                        name="name"
                        type={'text'}
                        onChange={formikProjectForm.handleChange}
                        value={formikProjectForm.values.name}
                      />
                    </label>
                    {formikProjectForm.touched.name &&
                    formikProjectForm.errors.name ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikProjectForm.errors.name}
                      </p>
                    ) : null}
                  </div>
                  <div className="block">
                    <label htmlFor="projectFormDescription" className="">
                      <div className={'mb-2'}>
                        {t('description')}{' '}
                        <span className={'text-gray-500 ml-1'}>(optional)</span>
                      </div>
                      <Input.TextArea
                        rows={2}
                        id={'projectFormDescription'}
                        name="description"
                        onChange={formikProjectForm.handleChange}
                        value={formikProjectForm.values.description}
                      />
                    </label>
                  </div>
                  <div className="block">
                    <label htmlFor="projectFormLanguages" className="">
                      <div className={'mb-2'}>
                        {t('languages')}
                        {projectPlan?.maxLangauges
                          ? `(${t('maxLanguages', {
                              max: projectPlan?.maxLangauges,
                            })})`
                          : ''}
                      </div>
                      <Select
                        id={'projectFormLanguages'}
                        className={'w-full block'}
                        mode={'multiple'}
                        showArrow
                        onChange={(val) => onHandleLanguages(val)}
                        value={formikProjectForm.values.languages}>
                        {languagesConfig.availableLanguages.map((lang) => (
                          <Select.Option
                            key={lang.code}
                            value={lang.code}
                            label={t('lang.' + lang.code.toLocaleLowerCase())}>
                            <div className="demo-option-label-item">
                              {t('lang.' + lang.code.toLocaleLowerCase())}
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </label>
                    {formikProjectForm.touched.languages &&
                    formikProjectForm.errors.languages ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikProjectForm.errors.languages}
                      </p>
                    ) : null}
                  </div>
                  <div className="block">
                    <label htmlFor="projectFormDefaultLanguage" className="">
                      <div className={'mb-2'}>{t('defaultLanguage')}</div>
                      <Select
                        id={'projectFormDefaultLanguage'}
                        className={'w-full block'}
                        onChange={(val) =>
                          formikProjectForm.setFieldValue(
                            'defaultLanguage',
                            val
                          )
                        }
                        value={formikProjectForm.values.defaultLanguage}>
                        {formikProjectForm.values.languages.map((lang) => (
                          <Select.Option
                            key={lang}
                            value={lang}
                            label={t('lang.' + lang.toLocaleLowerCase())}>
                            <div className="demo-option-label-item">
                              {t('lang.' + lang.toLocaleLowerCase())}
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </label>
                    {formikProjectForm.touched.defaultLanguage &&
                    formikProjectForm.errors.defaultLanguage ? (
                      <p className="my-1 text-xs text-red-500">
                        {formikProjectForm.errors.defaultLanguage}
                      </p>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </form>
        <Modal
          open={showPreview}
          maskClosable={false}
          footer={null}
          onCancel={() => setShowPreview(false)}>
          <img src={previewUrl} className={'w-full'} />
        </Modal>
      </>
    </Modal>
  )
}
