import { AppDispatch } from '@/states/store'
import { ActionTypesProjects } from '@/states/interfaces'
import { axiosInstance, PROJECTS_URL, USAGE_URL } from '@/configs'
import { LanguagesAvailable } from '@/types'

/**
 * 프로젝트 정보 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProject = (projectUid: string) => (dispatch: AppDispatch) => {
  return axiosInstance
    .get(`${PROJECTS_URL}/${projectUid}`)
    .then((res) => {
      const projectData = res.data.data

      axiosInstance
        .get(`${USAGE_URL}/projects/${projectUid}`)
        .then((res) => {
          projectData.usage = res.data

          dispatch({
            type: ActionTypesProjects.SET_CURRENT_PROJECT,
            payload: {
              project: projectData,
            },
          })
        })
        .catch((e) => {
          console.log(e)
        })
    })
    .catch((e) => {
      dispatch({
        type: ActionTypesProjects.SET_CURRENT_PROJECT,
        payload: {
          project: null,
        },
      })
    })
}

/**
 * 프로젝트 정보 불러오기 Data
 * @returns {function(*): Promise<void>}
 */
export const getProjectData = (projectUid: string) => {
  return axiosInstance.get(`${PROJECTS_URL}/${projectUid}`)
}

/**
 * 프로젝트 요금제 정보 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProjectUsage =
  (projectUid: string) => (dispatch: AppDispatch) => {
    return axiosInstance
      .get(`${USAGE_URL}/projects/${projectUid}`)
      .then((res) => {
        dispatch({
          type: ActionTypesProjects.SET_CURRENT_PROJECT_USAGE,
          payload: {
            usage: res.data,
          },
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }

/**
 * 프로젝트 요금제 정보 불러오기 Data
 * @returns {function(*): Promise<void>}
 */
export const getProjectUsageData = (projectUid: string) => {
  return axiosInstance.get(`${USAGE_URL}/projects/${projectUid}`)
}

/**
 * 모든 프로젝트 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProjects = () => (dispatch: AppDispatch) => {
  return axiosInstance
    .get(`${PROJECTS_URL}`)
    .then((res) => {
      dispatch({
        type: ActionTypesProjects.SET_PROJECT_LIST,
        payload: {
          projects: res.data.list,
        },
      })
    })
    .catch((e) => {
      dispatch({
        type: ActionTypesProjects.SET_PROJECT_LIST,
        payload: {
          projects: [],
        },
      })
    })
}

/**
 * 프로젝트 추가
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createProject = (req) => {
  return axiosInstance.post(`${PROJECTS_URL}`, req)
}

/**
 * 프로젝트 수정
 * @param id
 * @param req
 * @returns {function(*): Promise<void>}
 */
export const updateProject = (id: string, req) => {
  return axiosInstance.put(`${PROJECTS_URL}/${id}`, req)
}

/**
 * 프로젝트 삭제
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProject = (id: string) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${id}`)
}

/**
 * 현재 프로젝트 설정
 * @param data
 * @returns {function(*): *}
 */
export const setCurrentProject = (data) => (dispatch: AppDispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CURRENT_PROJECT,
    payload: {
      project: data,
    },
  })
}

/**
 * 현재 프로젝트 언어 설정
 * @param lang
 * @returns {function(*): *}
 */
export const setCurrentLanguage = (lang: LanguagesAvailable) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CURRENT_LANGUAGE,
    payload: {
      language: lang,
    },
  })
}

/**
 * 프로젝트 멤버 불러오기
 * @returns {function(*): Promise<void>}
 */
export const getProjectMembers =
  (projectUid: string) => (dispatch: AppDispatch) => {
    return axiosInstance
      .get(`${PROJECTS_URL}/${projectUid}/members`)
      .then((res) => {
        dispatch({
          type: ActionTypesProjects.SET_CURRENT_PROJECT,
          payload: {
            project: res.data.data,
          },
        })
      })
      .catch((e) => {
        dispatch({
          type: ActionTypesProjects.SET_CURRENT_PROJECT,
          payload: {
            project: null,
          },
        })
      })
  }

/**
 * 프로젝트 멤버 추가
 * @param projectUid
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const addProjectMembers = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/members`, req)
}

/**
 * 프로젝트 멤버 권한 수정
 * @param projectUid
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateProjectMembers = (projectUid, req) => {
  return axiosInstance.put(`${PROJECTS_URL}/${projectUid}/members`, req)
}

/**
 * 프로젝트 소속 멤버 삭제
 * @param projectUid
 * @param memberIdList
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProjectMembers = (projectUid, memberIdList) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/members`, {
    data: memberIdList,
  })
}

/**
 * 프로젝트 탈퇴
 * @param projectUid
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deactivateFromProject = (projectUid) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/members/out`)
}

/**
 * 프로젝트 액세스 확인
 * @returns {function(*): Promise<void>}
 */
export const getProjectAccess =
  (projectUid: string) => (dispatch: AppDispatch) => {
    return axiosInstance
      .get(`${PROJECTS_URL}/${projectUid}/access`)
      .then((res) => {
        dispatch({
          type: ActionTypesProjects.SET_ACCESS_LIST,
          payload: {
            accessList: res.data.list,
          },
        })
      })
      .catch((e) => {
        dispatch({
          type: ActionTypesProjects.SET_ACCESS_LIST,
          payload: {
            accessList: null,
          },
        })
      })
  }

/**
 * 프로젝트 액세스 생성
 * @param projectUid
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const createProjectAccess = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/access`, req)
}

/**
 * 프로젝트 액세스 수정
 * @param projectUid
 * @param accessId
 * @param req
 * @returns {Promise<AxiosResponse<any>>}
 */
export const updateProjectAccess = (projectUid, accessId, req) => {
  return axiosInstance.put(
    `${PROJECTS_URL}/${projectUid}/access/${accessId}`,
    req
  )
}

/**
 * 프로젝트 액세스 삭제
 * @param projectUid
 * @param accessId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const deleteProjectAccess = (projectUid, accessId) => {
  return axiosInstance.delete(
    `${PROJECTS_URL}/${projectUid}/access/${accessId}`
  )
}

/**
 * 프로젝트 액세스 토큰 발행
 * @param projectUid
 * @param accesId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const generateProjectAccessToken = (projectUid, accesId) => {
  return axiosInstance.get(
    `${PROJECTS_URL}/${projectUid}/access/${accesId}/token`
  )
}
