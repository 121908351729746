import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { Spin, Typography, Card } from 'antd'
import { LayoutProjects } from '@/components/layout/layout.projects'
import { RootState } from '@/states/reducers'
import { ProjectsUsageCircle } from '@/components/projects/project.usage.circle'
import { ProjectsUsageBar } from '@/components/projects/project.usage.bar'
import { useAppDispatch } from '@/states/store'

const ProjectsSettingsUsage = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState

  return (
    <LayoutProjects>
      {currentProject && (
        <Helmet>
          <title>
            {t('usage')} · {t('settings')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className={'py-4 px-6'}>
          {/* Setting header: 시작 */}
          <div className="mb-10 flex justify-between">
            <div>
              <h1 className={'mb-0 text-lg leading-7'}>{t('usage')}</h1>
            </div>
          </div>
          {/* Setting header: 끝 */}
          {/* Setting body: 시작 */}
          <div className={'space-y-6'}>
            <div className="grid grid-cols-2">
              <Card>
                <div className="p-3">
                  <div className="mb-6">
                    <Typography.Title level={5} className={'mt-0'}>
                      {t('projectPlan')}
                    </Typography.Title>
                  </div>
                  <p className="text-sm mb-0">
                    {currentProject.usage?.type} {t('plan')}
                  </p>
                  <hr className="mt-4 mb-4" />
                  <a
                    onClick={() => alert('준비 중 입니다')}
                    className="text-sm mb-0"
                    target={'_blank'}>
                    {t('morePlan')}
                  </a>
                </div>
              </Card>
            </div>
            <Card>
              <div className="p-3">
                <div className="mb-6">
                  <Typography.Title level={5} className={'mt-0'}>
                    {t('projectGlobalUsage')}
                  </Typography.Title>
                </div>
                <div className="2xl:px-10">
                  <div className="grid grid-cols-3 xl:grid-cols-5">
                    <div className="border-r border-gray-300">
                      <ProjectsUsageCircle
                        usage={currentProject?.usage?.content}
                        label={t('noGloablContents')}
                        desc={t('noGloablContentsDesc')}
                      />
                    </div>
                    <div className="border-r border-gray-300">
                      <ProjectsUsageCircle
                        usage={currentProject?.usage?.storage}
                        label={t('mediaStorage')}
                        desc={t('mediaStorageDesc')}
                        isFile
                      />
                    </div>
                    <div className="border-r border-gray-300">
                      <ProjectsUsageCircle
                        usage={currentProject?.usage?.model}
                        label={t('noModels')}
                        desc={t('noModelsDesc')}
                      />
                    </div>
                    <div className="border-r border-gray-300">
                      <ProjectsUsageCircle
                        usage={currentProject?.usage?.language}
                        label={t('noLanguages')}
                        desc={t('noLanguagesDesc')}
                      />
                    </div>
                    <div className="">
                      <ProjectsUsageCircle
                        usage={currentProject?.usage?.member}
                        label={t('noMembers')}
                        desc={t('noMembersDesc')}
                      />
                    </div>
                  </div>
                </div>
                <hr className="mt-4 mb-4" />
                <a
                  onClick={() => alert('준비 중 입니다')}
                  className="text-sm mb-0"
                  target={'_blank'}>
                  {t('upgradePlan')}
                </a>
              </div>
            </Card>
            {currentProject.usage?.modelList &&
            currentProject.usage?.modelList.length ? (
              <div className="grid grid-cols-2">
                <Card>
                  <div className="p-3 text-sm">
                    <div className="mb-6">
                      <Typography.Title level={5} className={'mt-0'}>
                        {t('usageByModel')}
                      </Typography.Title>
                    </div>
                    <div className="space-y-3">
                      <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-1">
                          <span>{t('limitComponentByModel')}</span>
                          {/* <InfoCircleOutlined /> */}
                        </div>
                        <div>
                          {currentProject.usage.type !== 'UNLIMITED'
                            ? currentProject.usage?.modelList[0].component.limit.toLocaleString() +
                              t('countWord')
                            : t('unlimited')}
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center space-x-1">
                          <span>{t('maxContentsByModel')}</span>
                          {/* <InfoCircleOutlined /> */}
                        </div>
                        <div>
                          {currentProject.usage.type !== 'UNLIMITED'
                            ? currentProject.usage?.modelList[0].content.limit.toLocaleString() +
                              t('countWord')
                            : t('unlimited')}
                        </div>
                      </div>
                    </div>
                    <hr className="mt-6 mb-6" />
                    {currentProject.usage?.modelList.map((model) => (
                      <div key={model.id}>
                        <div className="grid grid-cols-8 gap-6">
                          <div className="col-span-3">
                            {
                              modelList.find((m) => m.id === model.id)
                                ?.languageMap[currentProject.defaultLang]
                            }
                          </div>
                          <div className="col-span-5 space-y-4">
                            <ProjectsUsageBar
                              usage={model.component}
                              label={t('noComponents')}
                            />
                            <ProjectsUsageBar
                              usage={model.content}
                              label={t('noContents')}
                            />
                          </div>
                        </div>
                        <hr className="mt-6 mb-6" />
                      </div>
                    ))}
                    <a
                      onClick={() => alert('준비 중 입니다')}
                      className="text-sm mb-0"
                      target={'_blank'}>
                      {t('upgradePlan')}
                    </a>
                  </div>
                </Card>
              </div>
            ) : (
              <></>
            )}
          </div>
          {/* Setting body: 끝 */}
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </LayoutProjects>
  )
}

export default ProjectsSettingsUsage
