import { AppDispatch } from '@/states/store'
import { ActionTypesProjects } from '@/states/interfaces'
import { axiosInstance, PROJECTS_URL } from '@/configs'
import { MediaInterface } from '@/types'

/**
 * 프로젝트 미디어 불러오기
 * @param projectUid
 * @returns
 */
export const getMediaListSearch = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/media/search`, req)
}

/**
 * 프로젝트 미디어 불러오기
 * @param projectUid
 * @returns
 */
export const getMedias = (projectUid) => (dispatch: AppDispatch) => {
  return axiosInstance
    .get(`${PROJECTS_URL}/${projectUid}/media`)
    .then((res) => {
      dispatch({
        type: ActionTypesProjects.SET_MEDIA_LIST,
        payload: {
          mediaList: res.data.list,
        },
      })
    })
    .catch((e) => {
      dispatch({
        type: ActionTypesProjects.SET_MEDIA_LIST,
        payload: {
          mediaList: [],
        },
      })
    })
}

/**
 * 미디어 목록 설정
 * @param list
 * @returns
 */
export const setMediaList = (list: MediaInterface[]) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_MEDIA_LIST,
    payload: {
      mediaList: list,
    },
  })
}

/**
 * 미디어 정보 불러오기
 * @param projectUid
 * @param id
 * @returns
 */
export const getMedia = (projectUid, id) => {
  return axiosInstance.get(`${PROJECTS_URL}/${projectUid}/media/${id}`)
}

/**
 * 미디어 추가
 * @param projectUid
 * @param req
 * @returns
 */
export const createMedia = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/media`, req)
}

/**
 * 미디어 여러개 추가
 * @param projectUid
 * @param req
 * @returns
 */
export const createMultiMedia = (projectUid, req) => {
  return axiosInstance.post(`${PROJECTS_URL}/${projectUid}/media/bulk`, req)
}

/**
 * 미디어 수정
 * @param projectUid
 * @param id
 * @param req
 * @returns
 */
export const updateMedia = (projectUid, id, req) => {
  return axiosInstance.put(`${PROJECTS_URL}/${projectUid}/media/${id}`, req)
}

/**
 * 미디어 삭제
 * @param projectUid
 * @param id
 * @returns
 */
export const deleteMedia = (projectUid, id) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/media/${id}`)
}

/**
 * 미디어 여러개 삭제
 * @param projectUid
 * @param id
 * @param idArray
 * @returns
 */
export const deleteMultiMedia = (projectUid, idArray) => {
  return axiosInstance.delete(`${PROJECTS_URL}/${projectUid}/media`, {
    data: idArray,
  })
}

/**
 * 새 업로드 미디어 목록 설정
 * @param list
 * @param uid
 * @returns
 */
export const setNewMediaList = (list: MediaInterface[], uid) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_NEW_MEDIA_LIST,
    payload: {
      mediaList: list,
      uid: uid,
    },
  })
}

/**
 * 파일 이름으로 미디어  ID 검색
 * @param projectUid
 * @param fileNameId
 * @returns
 */
export const getMediaIdByFileName = (projectUid, fileNameId) => {
  return axiosInstance.post(
    `${PROJECTS_URL}/${projectUid}/media/files`,
    fileNameId
  )
}

/**
 * 체크된 미디어 목록 설정
 * @param list
 * @returns
 */
export const setCheckedMediaList = (list: MediaInterface[]) => (dispatch) => {
  return dispatch({
    type: ActionTypesProjects.SET_CHECKED_MEDIA_LIST,
    payload: {
      checkedMediaList: list,
    },
  })
}
