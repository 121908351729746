import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/states/reducers'
import {
  Button,
  Table,
  Dropdown,
  Menu,
  Modal,
  Row,
  Col,
  Card,
  Select,
  message,
  Empty,
  Spin,
  Input,
  Pagination,
  Checkbox,
} from 'antd'
const { Option } = Select
import {
  EllipsisOutlined,
  ExclamationCircleOutlined,
  DeleteFilled,
  DownloadOutlined,
} from '@ant-design/icons'
import {
  MediaListViewType,
  MediaFormModal,
  MediaUploadModal,
  MediaThumb,
} from './'
import { setMediaFormModal } from '@/states/actions/modals.actions'
import moment from 'moment'
import { availableFileTypes, DEFAULT_MEDIA_PAGE_SIZE } from '@/configs'
import {
  FileType,
  MediaInterface,
  MediaType,
  PaginationInterface,
} from '@/types'
import {
  getMediaListSearch,
  setMediaList,
  deleteMedia,
  setCheckedMediaList,
  deleteMultiMedia,
} from '@/states/actions/media.actions'
import { getProjectUsage } from '@/states/actions/projects.actions'
import { useAppDispatch } from '@/states/store'

export const MediaList = () => {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  const [searchParams, setSearchParams] = useSearchParams()

  // State
  const [loading, setLoading] = useState<boolean>(true)
  const [viewType, setViewType] = useState('')
  const [q, setQ] = useState<string>('')
  const [sort, setSort] = useState<string>('DATE_CREATE.DESC')
  const [filter, setFilter] = useState<FileType | MediaType | ''>('')
  const [pagination, setPagination] = useState<PaginationInterface>({
    total: 0,
    pageSize: searchParams.get('size')
      ? Number(searchParams.get('size'))
      : DEFAULT_MEDIA_PAGE_SIZE,
    current: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
  })

  // State (Redux)
  const { projectsState, layoutState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      layoutState: state.layout,
    }),
    shallowEqual
  )
  const { currentProject, mediaList, checkedMediaList } = projectsState
  const { mediaListView } = layoutState

  // Effect
  useEffect(() => {
    if (currentProject) {
      getMediaList(pagination, sort, filter, q)
    }
  }, [currentProject])

  useEffect(() => {
    setViewType(mediaListView)
  }, [mediaListView])

  /**
   * 체크하기
   */
  const onToggleCheckAll = (e) => {
    dispatch(setCheckedMediaList(e.target.checked ? mediaList : []))
  }

  /**
   * 콘텐츠 체크
   * @param e
   */
  const onToggleCheck = (e, media) => {
    const isAlready = checkedMediaList.map((cc) => cc.id).includes(media.id)

    dispatch(
      setCheckedMediaList(
        !isAlready
          ? [...checkedMediaList, media]
          : checkedMediaList.filter((cc) => cc.id !== media.id)
      )
    )
  }

  // Table columns
  const tableCols = [
    {
      title: (
        <div>
          <Checkbox
            indeterminate={
              checkedMediaList.length > 0 &&
              checkedMediaList.length !== mediaList.length
            }
            checked={
              checkedMediaList.length > 0 &&
              checkedMediaList.length === mediaList.length
            }
            onChange={onToggleCheckAll}
            disabled={currentProject?.role === 'VIEWER'}
          />
        </div>
      ),
      render: (media) => (
        <div>
          <Checkbox
            checked={checkedMediaList.map((cc) => cc.id).includes(media.id)}
            onChange={(e) => onToggleCheck(e, media)}
            disabled={currentProject?.role === 'VIEWER'}
          />
        </div>
      ),
    },
    {
      title: t('fileName'),
      key: 'name',
      render: (media: MediaInterface) => (
        <div
          className={'flex items-center space-x-3 cursor-pointer'}
          onClick={(e) => onMediaEdit(media, e)}>
          <MediaThumb
            media={media}
            // width={'96'}
            outerWidth={96}
            outerHeight={96}
            textSize="3xl"
            thumbnail="SMALL"
          />
          <div>
            {currentProject &&
            media.languageMap &&
            media.languageMap[currentProject?.defaultLang] ? (
              <p className="mb-0">
                {media.languageMap[currentProject?.defaultLang]?.name}
              </p>
            ) : (
              ''
            )}
            {media.value && media.mediaType === 'URL' ? (
              <p className="mb-0 mt-1 text-gray-600 text-xs">{media.value}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      ),
    },
    {
      title: t('fileType'),
      key: 'fileType',
      render: (media) => (
        <div className={'flex items-center'}>
          {media.mediaType === 'FILE' ? (
            <div className="rounded-sm px-1 bg-gray-100 border border-gray-400 text-xs">
              {media.fileType}
            </div>
          ) : (
            <></>
          )}
          {media.mediaType === 'URL' ? (
            <div className="rounded-sm px-1 bg-gray-100 border border-gray-400 text-xs">
              {media.mediaType}
            </div>
          ) : (
            <></>
          )}
        </div>
      ),
    },
    {
      title: t('extension'),
      key: 'extension',
      render: (media) => (
        <div className={'flex items-center'}>
          <div className="text-xs">
            {media.file
              ? media.file.path
                  .split('.')
                  [media.file.path.split('.').length - 1].toUpperCase()
              : ''}
          </div>
        </div>
      ),
    },
    {
      title: t('resolutions'),
      key: 'resolutions',
      render: (media) => (
        <div className={'flex items-center'}>
          <div className="text-xs">
            {media.file &&
            media.file.meta &&
            media.file.meta.width &&
            media.file.meta.height
              ? `${media.file.meta.width}x${media.file.meta.height}`
              : '-'}
          </div>
        </div>
      ),
    },
    {
      title: t('fileSize'),
      key: 'fileSize',
      render: (media) => (
        <div className={'flex items-center'}>
          <div className="text-xs">{media.file?.meta?.size}</div>
        </div>
      ),
    },
    {
      title: t('createdDate'),
      key: 'date',
      render: (media) => (
        <>
          {moment(media.date.createdAt, 'YYYYMMDDHHmmss').format(
            'YYYY-MM-DD HH:mm:ss'
          )}
        </>
      ),
    },
    {
      title: t('relatedContents'),
      key: 'relatedContents',
      render: (media) => (
        <div>{media.contentList ? media.contentList.length : 0}</div>
      ),
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      render: (project) =>
        currentProject?.role !== 'VIEWER' ? (
          <Dropdown overlay={mediaMenus(project)} trigger={['click']}>
            <Button
              type={'text'}
              icon={<EllipsisOutlined></EllipsisOutlined>}
              title={t('more')}></Button>
          </Dropdown>
        ) : (
          <></>
        ),
    },
  ]

  /**
   * 미디어 콘텐츠 가져오기
   * @param paging
   * @param sortBy
   * @param type
   * @param keyword
   */
  const getMediaList = (paging, sortBy, type, keyword) => {
    setLoading(true)
    dispatch(setMediaList([]))

    const req = {
      page: paging.current - 1,
      size: paging.pageSize,
      direction: sortBy.split('.')[1],
      orderCond: sortBy.split('.')[0],
      fileType: type && type !== 'URL' ? type : null,
      mediaType: type && type === 'URL' ? type : null,
      keyword: keyword
        ? {
            value: keyword.normalize('NFC').trim(),
          }
        : null,
    }

    getMediaListSearch(currentProject?.uid, req)
      .then((res) => {
        dispatch(setMediaList(res.data.list))
        setLoading(false)

        const pageInfo = res.data.pageInfo

        setPagination({
          total: pageInfo.totalElements,
          pageSize: pageInfo.size,
          current: pageInfo.page + 1,
        })
      })
      .catch((e) => {
        dispatch(setMediaList([]))
        // message.error(e.response.data.error)
        setLoading(false)

        setPagination({
          total: 0,
          pageSize: DEFAULT_MEDIA_PAGE_SIZE,
          current: 1,
        })
      })
  }

  /**
   * Table 정보 수정
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  const onTableChange = (pagination, filters, sorter, extra) => {
    getMediaList(pagination, sort, filter, q)
  }

  /**
   * 필터 변경
   * @param filter
   */
  const onFilterChange = (filter) => {
    setFilter(filter)
    setPagination({
      total: pagination.total,
      pageSize: pagination.pageSize,
      current: 1,
    })

    getMediaList(
      {
        total: pagination.total,
        pageSize: pagination.pageSize,
        current: 1,
      },
      sort,
      filter,
      q
    )
  }

  /**
   * 정렬 변경
   * @param sorter
   */
  const onSortChange = (sorter) => {
    setSort(sorter)
    setPagination({
      total: pagination.total,
      pageSize: pagination.pageSize,
      current: 1,
    })

    getMediaList(
      {
        total: pagination.total,
        pageSize: pagination.pageSize,
        current: 1,
      },
      sorter,
      filter,
      q
    )
  }

  /**
   * 미디어 콘텐츠 수정
   * @param model
   */
  const onMediaEdit = (model, e: undefined | any = null) => {
    if (e.target.tagName === 'INPUT') return false
    dispatch(setMediaFormModal(true, model))
  }

  /**
   * 여러 미디어 삭제
   * @param contentsToBeDeleted
   */
  const onMediaListDelete = () => {
    if (checkedMediaList.length) {
      Modal.confirm({
        centered: true,
        title: t('confirmDeleteContentsTitle'),
        icon: <ExclamationCircleOutlined />,
        content: t('confirmDeleteContentsDesc'),
        okText: t('delete'),
        cancelText: t('cancel'),
        onOk() {
          return new Promise((resolve, reject) => {
            deleteMultiMedia(
              currentProject?.uid,
              checkedMediaList.map((cc) => cc.id)
            )
              .then((res) => {
                message.success(t('deleteSuccess'))
                getMediaList(pagination, sort, filter, q)
                resolve(res)
              })
              .catch((e) => {
                message.error(e.response.data.error)
                reject(e)
              })
          }).catch((e) => console.log(e))
        },
        onCancel() {},
      })
    }
  }

  const onMediaListDownload = () => {
    if (checkedMediaList.length) {
      checkedMediaList.forEach((mediaInfo) => {
        try {
          const source = mediaInfo.file.path
          const fileName = mediaInfo.file.name.normalize('NFC')

          var xhr = new XMLHttpRequest()
          xhr.open('GET', source, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            var urlCreator = window.URL || window.webkitURL
            var imageUrl = urlCreator.createObjectURL(this.response)
            var tag = document.createElement('a')
            tag.href = imageUrl
            tag.download = fileName
            document.body.appendChild(tag)
            tag.click()
            document.body.removeChild(tag)
          }
          xhr.send()
        } catch (e) {
          console.log(e)
        }
      })
    }
  }

  /**
   * 미디어 콘텐츠 삭제 confirm
   * @param media
   */
  const onMediaDelete = (media) => {
    Modal.confirm({
      centered: true,
      title: t('confirmDeleteFileTitle'),
      icon: <ExclamationCircleOutlined />,
      content: t('confirmDeleteFileDesc'),
      okText: t('delete'),
      cancelText: t('cancel'),
      onOk() {
        return new Promise((resolve, reject) => {
          deleteMedia(currentProject?.uid, media.id)
            .then((res) => {
              message.success(t('deleteSuccess'))
              dispatch(getProjectUsage(currentProject?.uid as string))
              getMediaList(pagination, sort, filter, q)
              resolve(res)
            })
            .catch((e) => {
              message.error(e.response.data.error)
              reject(e)
            })
        }).catch((e) => console.log(e))
      },
      onCancel() {},
    })
  }

  /**
   * 미디어 콘텐츠 액션 메뉴
   * @param media
   * @returns
   */
  const mediaMenusItem = (media) => {
    return [
      {
        key: 'edit',
        label: t('editMediaContents'),
        onClick: () => {
          onMediaEdit(media, null)
        },
      },
      {
        key: 'delete',
        label: t('deleteMediaContents'),
        onClick: () => {
          onMediaDelete(media)
        },
      },
    ]
  }

  /**
   * 미디어 콘텐츠 액션 툴
   * @param media
   */
  const mediaMenus = (media) => (
    <Menu className={'w-48'} items={mediaMenusItem(media)} />
  )

  /**
   * 미디어 콘텐츠 카드
   * @param media
   * @constructor
   */
  const MediaCardItem = (media) => {
    return (
      <Card
        cover={
          <div
            className="cursor-pointer relative"
            onClick={(e) => onMediaEdit(media, e)}>
            <MediaThumb
              cover
              media={media}
              // height={'157'}
              outerHeight={157}
              textSize="3xl"
              thumbnail="SMALL"
            />
            <div className="absolute top-2 left-2">
              <Checkbox
                checked={checkedMediaList.map((cc) => cc.id).includes(media.id)}
                onChange={(e) => onToggleCheck(e, media)}
                disabled={currentProject?.role === 'VIEWER'}
              />
            </div>
          </div>
        }>
        <div className={'flex justify-between items-center'}>
          <div
            className={'overflow-hidden cursor-pointer'}
            onClick={(e) => onMediaEdit(media, e)}>
            <div
              className={
                'w-full overflow-hidden leading-6  mb-0 flex justify-between items-center space-x-2'
              }>
              <div className="truncate">
                {currentProject &&
                media.languageMap[currentProject?.defaultLang]
                  ? media.languageMap[currentProject?.defaultLang].name
                  : ''}
              </div>
              <div className="flex-none rounded-sm px-1 bg-gray-100 border border-gray-400 text-xs">
                {media.fileType}
              </div>
            </div>
            {media.file ? (
              <p className={'text-xs text-gray-500 leading-5 truncate mb-0'}>
                {media.file.path
                  .split('.')
                  [media.file.path.split('.').length - 1].toUpperCase()}
                {media.file &&
                media.file.meta &&
                media.file.meta.width &&
                media.file.meta.height
                  ? `, ${media.file.meta.width}x${media.file.meta.height}`
                  : ''}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div>
            {currentProject?.role !== 'VIEWER' ? (
              <Dropdown overlay={mediaMenus(media)} trigger={['click']}>
                <Button
                  type={'text'}
                  icon={<EllipsisOutlined></EllipsisOutlined>}
                  title={t('more')}></Button>
              </Dropdown>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Card>
    )
  }

  /**
   * 페이지네이션 변경
   * @param page
   * @param pageSize
   */
  const onHandleChangePagination = (page, pageSize) => {
    getMediaList(
      {
        total: pagination.total,
        pageSize: pageSize,
        current: page,
        changing: true,
      },
      sort,
      filter,
      q
    )
  }

  return (
    <div className={'space-y-7'}>
      {/* 미디어 콘텐츠 목록 보기 옵션: 시작 */}
      <div className={'flex justify-between space-x-4'}>
        <div>
          <Input.Search
            placeholder={t('search')}
            title={t('search')}
            allowClear
            onChange={(e) => setQ(e.target.value)}
            onSearch={(value) => getMediaList(pagination, sort, filter, value)}
            value={q}
            disabled={loading}
            enterButton
          />
        </div>
        <div className="flex space-x-7">
          <div className={'flex items-center space-x-3'}>
            <span className={'text-xs text-gray-500 leading-5'}>Filter:</span>
            <div>
              <Select
                bordered={false}
                defaultValue={''}
                style={{
                  width: 160,
                }}
                disabled={loading}
                onChange={(val) => onFilterChange(val)}>
                <Option value={''}>{t('all')}</Option>
                {availableFileTypes.map((fileType) => (
                  <Option key={fileType} value={fileType}>
                    {t(
                      'fileTypes.' +
                        (fileType ? fileType : 'all').toLowerCase() +
                        '.name'
                    )}
                  </Option>
                ))}
                <Option value={'URL'}>{t('url')}</Option>
              </Select>
            </div>
          </div>
          <div className={'flex items-center space-x-3'}>
            <span className={'text-xs text-gray-500 leading-5'}>Sort:</span>
            <div>
              <Select
                bordered={false}
                defaultValue={'DATE_CREATE.DESC'}
                style={{
                  width: 160,
                }}
                disabled={loading}
                onChange={(val) => onSortChange(val)}>
                <Option value={'DATE_CREATE.DESC'}>
                  {t('recentCreatedDate')}
                </Option>
                <Option value={'DATE_CREATE.ASC'}>
                  {t('oldestCreatedDate')}
                </Option>
                <Option value={'NAME.ASC'}>{t('sortByFileAsc')}</Option>
                <Option value={'NAME.DESC'}>{t('sortByFileDesc')}</Option>
              </Select>
            </div>
          </div>
          <MediaListViewType />
          {checkedMediaList.length ? (
            <div className="flex items-center space-x-3">
              <Button
                danger
                icon={<DeleteFilled />}
                onClick={() => onMediaListDelete()}>
                {t('delete')}
              </Button>
              <Button
                type={'primary'}
                icon={<DownloadOutlined />}
                onClick={() => onMediaListDownload()}>
                {t('downloadSelected')}
              </Button>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* 미디어 콘텐츠 목록 보기 옵션: 끝 */}
      {/* Card View: 시작 */}
      {viewType === 'card' ? (
        !loading ? (
          <Row gutter={24}>
            {mediaList.length ? (
              mediaList.map((media) => (
                <Col key={media.id} span={4} className="mb-6">
                  {MediaCardItem(media)}
                </Col>
              ))
            ) : (
              <Col span={24} className="w-full flex justify-center">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </Col>
            )}
          </Row>
        ) : (
          <div className={'h-screen flex justify-center items-center'}>
            <Spin />
          </div>
        )
      ) : (
        <></>
      )}
      {/* Card View: 끝 */}
      {/* List View: 시작 */}
      {viewType === 'list' ? (
        <div>
          <Table
            // @ts-ignore
            columns={tableCols}
            rowKey="id"
            loading={loading}
            dataSource={mediaList}
            onChange={onTableChange}
            pagination={false}></Table>
        </div>
      ) : (
        <></>
      )}
      {/* List View: 끝 */}
      {/* Pagination: 시작 */}
      <div className=" flex justify-between items-center py-6">
        <div className="text-gray-600">
          {t('noOfContents', { no: pagination.total })}
        </div>
        <Pagination
          defaultCurrent={1}
          current={pagination.current}
          total={pagination.total}
          pageSize={pagination.pageSize}
          onChange={onHandleChangePagination}
        />
      </div>
      {/* Pagination: 끝 */}

      <MediaFormModal
        onChangeMedia={() => getMediaList(pagination, sort, filter, q)}
      />
      <MediaUploadModal
        onUpload={() => getMediaList(pagination, sort, filter, q)}
      />
    </div>
  )
}
