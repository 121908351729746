import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { shallowEqual, useSelector } from 'react-redux'
import { Button } from 'antd'
import { SelectOutlined } from '@ant-design/icons'
import { RootState } from '@/states/reducers'
import { useTranslation } from 'react-i18next'
import { ContentsInterface } from '@/types'
import { setMediaFormModal } from '@/states/actions/modals.actions'
import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { useAppDispatch } from '@/states/store'

interface MediaContentsListProps {
  connectedContentsList: ContentsInterface[]
}

export const MediaContentsList = ({
  connectedContentsList,
}: MediaContentsListProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { mediaInfo } = modalsState

  /**
   * 연결된 컨텐츠로 이동
   * @param contentsToBeEdited
   * @param idx
   */
  const goConnectedContentsForm = (contentsToBeEdited, idx) => {
    if (mediaInfo && mediaInfo.modelList) {
      const targetModelID = mediaInfo.modelList[idx].id
      const targetModel = modelList.find((m) => m.id === targetModelID)

      if (!targetModel) return

      dispatch(setMediaFormModal(false))

      navigate(
        `/projects/${
          currentProject?.uid
        }/contents/${targetModelID}?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
          targetModel.id
        )}&q=&edit=modal&id=${contentsToBeEdited.uid}`
      )
    }
  }

  return currentProject &&
    mediaInfo &&
    mediaInfo.contentList &&
    mediaInfo.contentList.length ? (
    <div className="space-y-3">
      <p className="text-sm">
        {t('connectedContents')} (
        {mediaInfo.contentList ? mediaInfo.contentList.length : 0})
      </p>
      {mediaInfo.modelList ? (
        <div className="space-y-3">
          <hr />
          <ul className="text-xs flex gap-2">
            {mediaInfo.modelList
              .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
              .map((model) => (
                <li
                  key={model.id}
                  className="px-2 bg-gray-200 border border-gray-300 rounded-sm">
                  {model.languageMap[currentProject.defaultLang]} (
                  {mediaInfo.modelList?.filter((m) => m.id === model.id).length}
                  )
                </li>
              ))}
          </ul>
          <hr />
          <ul className="space-y-1">
            {connectedContentsList.length ? (
              connectedContentsList.map((contents, idx) => (
                <li key={contents.uid} className="flex justify-between">
                  <div className="flex space-x-2 items-center text-xs">
                    <div className="px-2 bg-gray-200 border border-gray-300 rounded-sm">
                      {mediaInfo &&
                      mediaInfo.modelList &&
                      mediaInfo.modelList.length
                        ? mediaInfo.modelList[idx].languageMap[
                            currentProject.defaultLang
                          ]
                        : ''}
                    </div>
                    <div>
                      {contents && contents.cellList && contents.cellList[0]
                        ? contents.cellList[0].languageMap &&
                          contents.cellList[0].languageMap[
                            currentProject.defaultLang
                          ]
                          ? contents.cellList[0].languageMap[
                              currentProject.defaultLang
                            ]
                          : contents.cellList[0].value
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="flex-none">
                    <Button
                      type="text"
                      size="small"
                      icon={<SelectOutlined />}
                      onClick={() => goConnectedContentsForm(contents, idx)}
                      title={t('moveToConnectedContents')}></Button>
                  </div>
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
