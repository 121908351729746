import { useEffect } from 'react'

import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'
import { Result, Button } from 'antd'

export default function NotFound() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()

  useEffect(() => {}, [])

  return (
    <div className={'h-screen flex justify-center items-center'}>
      <Result
        status="404"
        title="404"
        subTitle={
          <p>
            죄송합니다. 페이지를 찾을 수 없습니다!
            <br />
            요청하신 페이지가 수정되었거나 삭제되었을 수 있습니다.
          </p>
        }
        extra={
          <Button
            type="primary"
            key="console"
            onClick={() => navigate('/projects')}>
            메인으로 돌아가기
          </Button>
        }
      />
    </div>
  )
}
