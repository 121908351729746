import React, { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { RootState } from '@/states/reducers'
import { DEFAULT_CONTENTS_PAGE_SIZE } from '@/configs'
import { LayoutProjects } from '@/components/layout'
import { getSavedContentsSortKey } from '@/utils/helpers'
import { setCurrentModel } from '@/states/actions/models.actions'
import { useAppDispatch } from '@/states/store'
import { useNavigate } from 'react-router'

const ProjectsDashboard = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  // State (Redux)
  const { projectsState, authState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState
  const { init } = authState

  // Effect
  useEffect(() => {
    if (currentProject) {
      if (modelList.length) {
        const modelInit = modelList[0]
        dispatch(setCurrentModel(modelInit))

        navigate(
          `/projects/${currentProject?.uid}/contents/${
            modelInit.id
          }?page=1&size=${DEFAULT_CONTENTS_PAGE_SIZE}&sort=${getSavedContentsSortKey(
            modelInit.id
          )}&q=`
        )
      } else {
        navigate(`/projects/${currentProject?.uid}/builder`)
      }
    }
  }, [currentProject, modelList])

  return (
    <LayoutProjects>
      <div></div>
    </LayoutProjects>
  )
}

export default ProjectsDashboard
