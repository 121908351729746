import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Spin } from 'antd'

const Home = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    navigate('/projects')
  }, [])

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spin />
    </div>
  )
}

export default Home
