import {
  ProjectRoleType,
  ProjectUsageType,
  ProjectUsagePropertiesInterface,
} from '@/types'
export const ProjectRoles: ProjectRoleType[] = ['ADMIN', 'USER', 'VIEWER']

/**
 * 프로젝트 요금제 정보
 */
export const ProjectUsageProperties: ProjectUsagePropertiesInterface[] = [
  {
    price: 'FREE',
    availableRoles: ['ADMIN'],
    maxLangauges: 2,
  },
  {
    price: 'BASIC',
    availableRoles: ['ADMIN'],
    maxLangauges: 3,
  },
  {
    price: 'PRO',
    availableRoles: ['ADMIN', 'USER', 'VIEWER'],
    maxLangauges: 5,
  },
  {
    price: 'UNLIMITED',
    availableRoles: ['ADMIN', 'USER', 'VIEWER'],
    maxLangauges: null,
  },
  {
    price: 'TEST',
    availableRoles: ['ADMIN'],
    maxLangauges: 1,
  },
]

/**
 * 기본 프로젝트 요금제
 */
export const DEFAULT_PROJECT_PLAN: ProjectUsageType = 'FREE'
