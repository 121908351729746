import Cookies from 'js-cookie'
import { UserInterface } from '@/types'
import {
  USER_INFO_KEY,
  USER_ACCESS_TOKEN_KEY,
  XSRF_TOKEN_KEY,
  USER_REFRESH_TOKEN_KEY,
  USER_ID_KEY,
} from '@/configs'
import moment from 'moment'

/**
 * 유효한 로그인 확인
 */
export const isValidLogin = () => {
  return (
    !!Cookies.get(USER_INFO_KEY) &&
    !!Cookies.get(USER_ID_KEY) &&
    !!Cookies.get(USER_ACCESS_TOKEN_KEY) &&
    !!Cookies.get(USER_REFRESH_TOKEN_KEY)
  )
}

/**
 * Access 토큰 저장
 * @param accessToken
 * @param expiredDate
 */
export const setUserAccessToken = (accessToken: string) => {
  if (accessToken) {
    Cookies.set(USER_ACCESS_TOKEN_KEY, accessToken, { expires: 365 })
  } else {
    Cookies.remove(USER_ACCESS_TOKEN_KEY, { path: '/' })
  }
}

/**
 * Refresh 토큰 저장
 * @param refreshToken
 * @param expiredDate
 */
export const setUserRefreshToken = (refreshToken: string) => {
  if (refreshToken) {
    Cookies.set(USER_REFRESH_TOKEN_KEY, refreshToken, { expires: 365 })
  } else {
    Cookies.remove(USER_REFRESH_TOKEN_KEY, { path: '/' })
  }
}

/**
 * 유저 정보 저장
 * @param user
 */
export const setUserInfo = (user: UserInterface | null) => {
  if (user) {
    Cookies.set(USER_INFO_KEY, JSON.stringify(user), { expires: 365 })
    Cookies.set(USER_ID_KEY, user.id.toString(), { expires: 365 })
  } else {
    // Cookies.remove(USER_INFO_KEY, { path: '/' })
  }
}

/**
 * Access token 불러오기
 */
export const getToken = () => {
  return Cookies.get(USER_ACCESS_TOKEN_KEY)
    ? (Cookies.get(USER_ACCESS_TOKEN_KEY) as string)
    : ''
}

/**
 * 유저 정보 불러오기
 */
export const getUser = () => {
  return Cookies.get(USER_INFO_KEY)
    ? JSON.parse(<string>Cookies.get(USER_INFO_KEY))
    : null
}

/**
 * 유저 아이디 불러오기
 */
export const getUserId = () => {
  return Cookies.get(USER_ID_KEY)
    ? JSON.parse(<string>Cookies.get(USER_ID_KEY))
    : null
}

/**
 * 로그인
 * @param data
 */
export const setLogin = (data) => {
  setUserAccessToken(data.accessToken.token)
  setUserRefreshToken(data.refreshToken.token)
  setUserInfo(data.data)
}

/**
 * 로그아웃
 * @param isRefresh
 * @param url
 */
export const setLogout = (isRefresh: boolean = true, url: string = '') => {
  if (typeof window !== 'undefined') {
    // remove logged in user's cookie and redirect to login page
    Cookies.remove(USER_ACCESS_TOKEN_KEY, { path: '/' })
    Cookies.remove(USER_REFRESH_TOKEN_KEY, { path: '/' })
    Cookies.remove(USER_INFO_KEY, { path: '/' })
    Cookies.remove(USER_ID_KEY, { path: '/' })

    if (isRefresh) {
      location.reload()
    } else if (url) {
      location.href = url || '/login'
    }
  }
}

/**
 * CSRF token 제거
 */
export const removeCsrf = () => {
  Cookies.remove(XSRF_TOKEN_KEY, { path: '/' })
  location.reload()
}
