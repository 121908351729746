import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Modal, Input, Button, message } from 'antd'
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  CheckOutlined,
} from '@ant-design/icons'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { RootState } from '@/states/reducers'
import { AlertStatus } from '@/components/common/common.alert'
import { updatePassword } from '@/states/actions/auth.actions'
import { setAccountPasswordModal } from '@/states/actions/modals.actions'
import { useAppDispatch } from '@/states/store'

const initialSettingsPasswordFormValues = {
  password: '',
  newPassword: '',
  newPasswordConfirm: '',
}

export const AccountPassword = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { authState, modalsState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
      modalsState: state.modals,
    }),
    shallowEqual
  )

  const { me } = authState
  const { accountPasswordModal } = modalsState

  // State
  const [loading, setLoading] = useState<boolean>(false)

  // Validation
  const validationsettingsAccountFormSchema = Yup.object().shape({
    password: Yup.string().required(t('validation.required')),
    newPassword: Yup.string()
      .required(t('validation.required'))
      .min(8, t('validation.min', { len: 8 }))
      .max(30, t('validation.max', { len: 30 }))
      .matches(
        /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
        t('validation.passwordPattern')
      ),
    newPasswordConfirm: Yup.string()
      .required(t('validation.required'))
      .min(8, t('validation.min', { len: 8 }))
      .max(30, t('validation.max', { len: 30 }))
      .matches(/^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/, t('validation.passwordPattern'))
      .oneOf([Yup.ref('newPassword'), null], t('validation.passwordNotMatch')),
  })

  // Formik
  const formikSettingsPasswordForm = useFormik({
    initialValues: initialSettingsPasswordFormValues,
    validationSchema: validationsettingsAccountFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setStatus(null)
      setLoading(true)

      const req = {
        password: values.password,
        newPassword: values.newPassword,
        newPasswordConfirm: values.newPasswordConfirm,
      }

      updatePassword(req)
        .then((res) => {
          message.success(t('changePasswordCompleted'))
          dispatch(dispatch(setAccountPasswordModal(false)))
          resetForm()
        })
        .catch((e) => {
          // No data
          if (e.response.data.message === 'INVALID_PARAMETER - Password') {
            setStatus(t('error.wrongPassword'))
          } else if (
            e.response.data.message === 'INVALID_PARAMETER - Same Password'
          ) {
            setStatus(t('error.samePassword'))
          } else {
            setStatus(t('error.wrong'))
          }
        })
        .then(() => {
          setLoading(false)
        })
    },
  })

  /**
   * 폼 리셋
   */
  const resetForm = () => {
    formikSettingsPasswordForm.resetForm()
    setLoading(false)
  }

  return (
    <form onSubmit={formikSettingsPasswordForm.handleSubmit} method="POST">
      <Modal
        open={accountPasswordModal}
        maskClosable={false}
        title={null}
        footer={[
          <div
            key={'footer'}
            className={'flex justify-end items-center space-x-3'}>
            <div>
              <Button onClick={() => dispatch(setAccountPasswordModal(false))}>
                {t('cancel')}
              </Button>
            </div>
            <div>
              <Button
                key="submit"
                type={'primary'}
                icon={<CheckOutlined />}
                onClick={() => formikSettingsPasswordForm.submitForm()}
                disabled={loading}
                loading={loading}>
                {t('save')}
              </Button>
            </div>
          </div>,
        ]}
        width={400}
        style={{ padding: 0, borderRadius: 0 }}
        onCancel={() => dispatch(setAccountPasswordModal(false))}
        wrapClassName={'modal-small'}>
        <div className="space-y-6">
          <AlertStatus
            status={formikSettingsPasswordForm.status}
            onClick={() =>
              formikSettingsPasswordForm.setStatus(null)
            }></AlertStatus>
          <div className="block">
            <label htmlFor="settingsPasswordFormPassword" className="">
              <div className={'mb-2'}>{t('oldPassword')}</div>
              <Input.Password
                id={'settingsPasswordFormPassword'}
                name="password"
                type={'password'}
                onChange={formikSettingsPasswordForm.handleChange}
                value={formikSettingsPasswordForm.values.password}
                placeholder={t('validation.password')}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </label>
            {formikSettingsPasswordForm.touched.password &&
            formikSettingsPasswordForm.errors.password ? (
              <p className="my-1 text-xs text-red-500">
                {formikSettingsPasswordForm.errors.password}
              </p>
            ) : null}
          </div>
          <div className="block">
            <label htmlFor="settingsPasswordFormNewPassword" className="">
              <div className={'mb-0.5'}>{t('newPassword')}</div>
              <Input.Password
                id="settingsPasswordFormNewPassword"
                name="newPassword"
                type="password"
                onChange={formikSettingsPasswordForm.handleChange}
                value={formikSettingsPasswordForm.values.newPassword}
                placeholder={t('validation.password')}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </label>
            {formikSettingsPasswordForm.touched.newPassword &&
            formikSettingsPasswordForm.errors.newPassword ? (
              <p className="my-1 text-xs text-red-500">
                {formikSettingsPasswordForm.errors.newPassword}
              </p>
            ) : null}
          </div>
          <div className="block">
            <label
              htmlFor="settingsPasswordFormNewPasswordConfirm"
              className="">
              <div className={'mb-0.5'}>{t('passwordConfirmed')}</div>
              <Input.Password
                id="settingsPasswordFormNewPasswordConfirm"
                name="newPasswordConfirm"
                type="password"
                onChange={formikSettingsPasswordForm.handleChange}
                value={formikSettingsPasswordForm.values.newPasswordConfirm}
                placeholder={t('validation.password')}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </label>
            {formikSettingsPasswordForm.touched.newPasswordConfirm &&
            formikSettingsPasswordForm.errors.newPasswordConfirm ? (
              <p className="my-1 text-xs text-red-500">
                {formikSettingsPasswordForm.errors.newPasswordConfirm}
              </p>
            ) : null}
          </div>
        </div>
      </Modal>
    </form>
  )
}
