import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import {
  Button,
  Col,
  Card,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
  Upload,
  message,
} from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import { UploadFile } from 'antd/es/upload/interface'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import ImgCrop from 'antd-img-crop'
import { LayoutProjects } from '@/components/layout'
import { LanguagesAvailable } from '@/types'
import { RootState } from '@/states/reducers'
import { uploadFile } from '@/states/actions/files.actions'
import { updateProject, getProject } from '@/states/actions/projects.actions'
import { AlertStatus } from '@/components/common/common.alert'
import { languagesConfig } from '@/configs/languages.config'
import { setCurrentModel } from '@/states/actions/models.actions'
import { useAppDispatch } from '@/states/store'
import { FILE_IMAGE_MAX_RES, FILE_MAX_SIZE_UPLOAD } from '@/configs'

const initialProjectFormValues: {
  name: string
  languages: LanguagesAvailable[]
  defaultLanguage: LanguagesAvailable | ''
  description: string
} = {
  name: '',
  languages: [],
  defaultLanguage: '',
  description: '',
}

const ProjectsSettingsIndex = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject, modelList } = projectsState

  // State
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewUrl, setPreviewUrl] = useState<string>('')

  // Effect
  useEffect(() => {
    if (currentProject) {
      formikProjectForm.setFieldValue('name', currentProject.name)
      formikProjectForm.setFieldValue('description', currentProject.description)
      formikProjectForm.setFieldValue('languages', currentProject.languageList)
      formikProjectForm.setFieldValue(
        'defaultLanguage',
        currentProject.defaultLang
      )

      if (currentProject.image) {
        setFileList([
          {
            uid: '-1',
            name: currentProject.image.name,
            status: 'success',
            url: currentProject.image.path,
          },
        ])
      }
    }
  }, [currentProject])

  // Validation
  const validationProjectFormSchema = Yup.object().shape({
    name: Yup.string().required(t('validation.required')),
    languages: Yup.array().min(1, t('validation.minLanguage')),
    defaultLanguage: Yup.string().required(t('validation.required')),
  })

  // Formik
  const formikProjectForm = useFormik({
    initialValues: initialProjectFormValues,
    validationSchema: validationProjectFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        if (!currentProject) return false

        setStatus(null)
        setLoading(true)

        // Language Map
        const languageMap = {}
        values.languages.forEach((lang) => {
          languageMap[lang] = lang === values.defaultLanguage
        })

        const req = {
          type: 'ARCHIVE',
          name: values.name,
          description: values.description,
          languageMap: languageMap,
          imageId: null,
          memberAuthList: currentProject?.memberList.map((ma) => {
            return { memberId: ma.id, role: ma.role }
          }),
        }

        // 프로젝트 이미지 업로드
        if (fileList && fileList.length) {
          let imageId = currentProject?.image?.id
          const reqImg = new FormData()
          const projectThumbnail = fileList[0]

          // New Image
          if (projectThumbnail.status === 'done') {
            // @ts-ignore
            reqImg.append('files', fileList[0])

            await uploadFile(reqImg).then((res) => {
              imageId = res.data[0].fileId
            })
          }

          // @ts-ignore
          req.imageId = imageId
        }

        await updateProject(currentProject.uid, req)
        await dispatch(getProject(currentProject.uid))
        await setLoading(false)
        await message.success(t('saveSuccess'))

        if (modelList && modelList.length) {
          await dispatch(setCurrentModel(modelList[0]))
        }
      } catch (e) {
        setLoading(false)
        setSubmitting(false)
        // @ts-ignore
        setStatus(e.response.data.error)
      }
    },
  })

  useEffect(() => {
    if (
      formikProjectForm.values.defaultLanguage &&
      !formikProjectForm.values.languages.includes(
        formikProjectForm.values.defaultLanguage
      )
    ) {
      formikProjectForm.setFieldValue('defaultLanguage', '')
    }
  }, [formikProjectForm.values.languages])

  // Upload props
  const uploadProps = {
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          // 파일 크기 체크
          if (file.size > FILE_MAX_SIZE_UPLOAD * 1024) {
            message.error(
              t('error.fileTooBig', {
                name: file.name,
                size: FILE_MAX_SIZE_UPLOAD / 1024,
              })
            )
            return false
          }

          // 이미지의 경우 해상도 확인
          if (file.type.indexOf('image') >= 0) {
            const image = new Image()

            // @ts-ignore
            image.src = e.target.result
            image.onload = function () {
              // @ts-ignore
              const height = this.height
              // @ts-ignore
              const width = this.width

              if (width > FILE_IMAGE_MAX_RES || height > FILE_IMAGE_MAX_RES) {
                message.error(
                  t('error.imageSizeTooBig', {
                    name: file.name,
                    px: FILE_IMAGE_MAX_RES,
                  })
                )
                return false
              } else {
                const fileAdded = file
                fileAdded.status = 'done'
                // @ts-ignore
                fileAdded.url = e.target.result
                setFileList([fileAdded])
              }

              return true
            }
          } else {
            const fileAdded = file
            fileAdded.status = 'done'
            fileAdded.url = e.target.result
            setFileList([fileAdded])
          }
        }
      }
      reader.readAsDataURL(file)

      return false
    },
    fileList,
  }

  /**
   * 이미지 프리뷰
   * @param file
   */
  const onHandlePreview = (file) => {
    setPreviewUrl(file.url)
    setShowPreview(true)
  }

  /**
   * 가용 언어 선택
   * @param val
   */
  const onHandleLanguages = (val) => {
    if (
      currentProject &&
      currentProject.usage &&
      val.length > currentProject?.usage?.language.limit
    )
      return false

    formikProjectForm.setFieldValue('languages', val)
  }

  return (
    <LayoutProjects>
      {currentProject && (
        <Helmet>
          <title>
            {t('projects')} · {t('settings')} · {currentProject.name} ·{' '}
            {process.env.REACT_APP_NAME}
          </title>
        </Helmet>
      )}
      {currentProject ? (
        <div className={'py-4 px-6'}>
          <form onSubmit={formikProjectForm.handleSubmit} method="POST">
            {/* Setting header: 시작 */}
            <div className="mb-10 flex justify-between">
              <div>
                <h1 className={'mb-0 text-lg leading-7'}>
                  {t('updateProject')}
                </h1>
              </div>
              <div>
                {currentProject.role === 'ADMIN' ? (
                  <Button
                    type={'primary'}
                    icon={<CheckOutlined />}
                    onClick={() => formikProjectForm.submitForm()}
                    disabled={loading}
                    loading={loading}>
                    {t('save')}
                  </Button>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* Setting header: 끝 */}
            {/* Setting body: 시작 */}
            <Card>
              <div className="p-3">
                <AlertStatus
                  status={formikProjectForm.status}
                  onClick={() =>
                    formikProjectForm.setStatus(null)
                  }></AlertStatus>
                <div className={'space-y-6'}>
                  <div>
                    <Typography.Title level={5} className={'mt-0 mb-3'}>
                      {currentProject.name}
                    </Typography.Title>
                    <p className={'text-gray-500'}>
                      {currentProject.description}
                    </p>
                  </div>
                  <Row>
                    <Col span={8}>
                      <div className="block">
                        <label htmlFor="" className="">
                          <div className={'mb-2'}>
                            {t('photo')}{' '}
                            <span className={'text-gray-500 ml-1'}>
                              (optional)
                            </span>
                          </div>
                          <ImgCrop
                            modalTitle={t('editImage')}
                            modalOk={t('confirm')}
                            modalCancel={t('cancel')}>
                            <Upload
                              {...uploadProps}
                              accept="image/*"
                              listType={'picture-card'}
                              maxCount={1}
                              onPreview={onHandlePreview}
                              disabled={currentProject.role !== 'ADMIN'}>
                              {fileList && fileList.length
                                ? t('editPhoto')
                                : t('addPhoto')}
                            </Upload>
                          </ImgCrop>
                        </label>
                      </div>
                    </Col>
                    <Col span={16}>
                      <div className="space-y-4">
                        <div className="block">
                          <label htmlFor="projectFormName" className="">
                            <div className={'mb-2'}>{t('projectName')}</div>
                            <Input
                              id={'projectFormName'}
                              name="name"
                              type={'text'}
                              onChange={formikProjectForm.handleChange}
                              value={formikProjectForm.values.name}
                              disabled={currentProject.role !== 'ADMIN'}
                            />
                          </label>
                          {formikProjectForm.touched.name &&
                          formikProjectForm.errors.name ? (
                            <p className="my-1 text-xs text-red-500">
                              {formikProjectForm.errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="block">
                          <label htmlFor="projectFormDescription" className="">
                            <div className={'mb-2'}>
                              {t('description')}{' '}
                              <span className={'text-gray-500 ml-1'}>
                                (optional)
                              </span>
                            </div>
                            <Input.TextArea
                              rows={2}
                              id={'projectFormDescription'}
                              name="description"
                              onChange={formikProjectForm.handleChange}
                              value={formikProjectForm.values.description}
                              disabled={currentProject.role !== 'ADMIN'}
                            />
                          </label>
                        </div>
                        <div className="block">
                          <label htmlFor="projectFormLanguages" className="">
                            <div className={'mb-2'}>
                              {t('languages')}
                              {currentProject.price !== 'UNLIMITED'
                                ? `(${t('maxLanguages', {
                                    max: currentProject.usage?.language.limit,
                                  })})`
                                : ''}
                            </div>
                            <Select
                              id={'projectFormLanguages'}
                              className={'w-full block'}
                              mode={'multiple'}
                              showArrow
                              maxLength={currentProject.usage?.language.limit}
                              onChange={(val) => onHandleLanguages(val)}
                              value={formikProjectForm.values.languages}
                              disabled={currentProject.role !== 'ADMIN'}>
                              {languagesConfig.availableLanguages.map(
                                (lang) => (
                                  <Select.Option
                                    key={lang.code}
                                    value={lang.code}
                                    label={t(
                                      'lang.' + lang.code.toLocaleLowerCase()
                                    )}>
                                    <div className="demo-option-label-item">
                                      {t(
                                        'lang.' + lang.code.toLocaleLowerCase()
                                      )}
                                    </div>
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </label>
                          {formikProjectForm.touched.languages &&
                          formikProjectForm.errors.languages ? (
                            <p className="my-1 text-xs text-red-500">
                              {formikProjectForm.errors.languages}
                            </p>
                          ) : null}
                        </div>
                        <div className="block">
                          <label
                            htmlFor="projectFormDefaultLanguage"
                            className="">
                            <div className={'mb-2'}>{t('defaultLanguage')}</div>
                            <Select
                              id={'projectFormDefaultLanguage'}
                              className={'w-full block'}
                              onChange={(val) =>
                                formikProjectForm.setFieldValue(
                                  'defaultLanguage',
                                  val
                                )
                              }
                              value={formikProjectForm.values.defaultLanguage}
                              disabled={currentProject.role !== 'ADMIN'}>
                              {formikProjectForm.values.languages.map(
                                (lang) => (
                                  <Select.Option
                                    key={lang}
                                    value={lang}
                                    label={t(
                                      'lang.' + lang.toLocaleLowerCase()
                                    )}>
                                    <div className="demo-option-label-item">
                                      {t('lang.' + lang.toLocaleLowerCase())}
                                    </div>
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </label>
                          {formikProjectForm.touched.defaultLanguage &&
                          formikProjectForm.errors.defaultLanguage ? (
                            <p className="my-1 text-xs text-red-500">
                              {formikProjectForm.errors.defaultLanguage}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <Modal
                  open={showPreview}
                  maskClosable={false}
                  footer={null}
                  onCancel={() => setShowPreview(false)}>
                  <img src={previewUrl} className={'w-full'} />
                </Modal>
              </div>
            </Card>
            {/* Setting body: 끝 */}
          </form>
        </div>
      ) : (
        <div className={'flex justify-center items-center h-screen'}>
          <Spin />
        </div>
      )}
    </LayoutProjects>
  )
}

export default ProjectsSettingsIndex
