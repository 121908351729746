import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useLocation, useSearchParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Checkbox, Empty, Spin, message, Button, notification } from 'antd'
import {
  CaretUpFilled,
  CaretDownFilled,
  LoadingOutlined,
} from '@ant-design/icons'
import axios from 'axios'
import { RootState } from '@/states/reducers'
import { ContentsHeaderCells } from './contents.headerCells'
import {
  getContentsItem,
  getContentsListSearch,
  setCheckedContentsList,
  setContentsList,
  setContentsListLoading,
  setContentsCellItem,
  setContentsConditions,
} from '@/states/actions/contents.actions'
import { ContentsFormModal, ContentsRelationSelectorModal } from './'
import { MediaFormModal, MediaSelectorModal, MediaUploadModal } from '../media'
import { ContentsCellList } from './contents.cell.list'
import {
  DEFAULT_CONTENTS_PAGE_SIZE,
  LAYOUT_CHECKBOX_CELL_WIDTH,
  LAYOUT_DEFAULT_CELL_HEIGHT,
  LAYOUT_DEFAULT_CELL_DATE_WIDTH,
  LAYOUT_GNB_WIDTH,
  LAYOUT_SIDEBAR_WIDTH,
  DEFAULT_SORT,
  CHUNK_CONTENTS_GET_SIZE,
} from '@/configs'
import { setCurrentModel } from '@/states/actions/models.actions'
import { setContentsFormModal } from '@/states/actions/modals.actions'
import { addItem, getItem, updateItem } from '@/utils/indexedDb'
import { useAppDispatch } from '@/states/store'
import { removeUnnecessaryPropertiesForIndexedDB } from '@/utils/helpers'

export const ContentsListExcel = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { projectsState, modalsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
      modalsState: state.modals,
    }),
    shallowEqual
  )
  const {
    currentProject,
    contentsPagination,
    currentModel,
    contentsListInit,
    contentsListReload,
    contentsList,
    allContentsList,
    checkedContentsList,
    contentsListLoading,
    contentsSort,
    contentsKeyword,
    modelList,
    flattenComponentList,
  } = projectsState
  const { contentsFormModal, contentsRelationSelectorModal, contentsInfo } =
    modalsState

  // State
  const [localLoading, setLocalLoading] = useState<boolean>(false)

  // Effect
  useEffect(() => {
    if (location) {
      const projectUidParsed = projectUid ? projectUid : -1
      const modelIdParsed = modelId ? Number(modelId) : -1

      const sort = searchParams.get('sort')
        ? (searchParams.get('sort') as string)
        : DEFAULT_SORT
      const pagination = {
        total: allContentsList.length,
        pageSize: searchParams.get('size')
          ? Number(searchParams.get('size'))
          : DEFAULT_CONTENTS_PAGE_SIZE,
        current: searchParams.get('page')
          ? Number(searchParams.get('page'))
          : 1,
      }
      const q = searchParams.get('q')

      if (
        currentProject &&
        currentModel &&
        (contentsListReload ||
          !contentsListInit ||
          currentProject.uid !== projectUidParsed ||
          currentModel.id !== modelIdParsed)
      ) {
        if (currentModel.id !== modelIdParsed) {
          const targetModel = modelList.find((m) => m.id === modelIdParsed)
          dispatch(setCurrentModel(targetModel))
        }

        if (contentsFormModal) dispatch(setContentsFormModal(false))
        getContentsList(projectUidParsed, modelIdParsed, pagination, sort, '')
      } else if (
        currentProject &&
        currentModel &&
        (pagination.pageSize !== contentsPagination.pageSize ||
          pagination.current !== contentsPagination.current ||
          sort !== contentsSort ||
          q !== contentsKeyword)
      ) {
        dispatch(setContentsConditions(pagination, q, sort, modelIdParsed))
      } else if (
        contentsFormModal &&
        (!(searchParams.get('new') === 'modal') ||
          !(
            searchParams.get('edit') &&
            searchParams.get('edit') === 'modal' &&
            searchParams.get('id') &&
            projectUidParsed &&
            modelIdParsed
          ))
      ) {
        dispatch(setContentsFormModal(false))
      } else if (!contentsFormModal && searchParams.get('new') === 'modal') {
        dispatch(setContentsFormModal(true))
      } else if (
        !contentsFormModal &&
        searchParams.get('edit') === 'modal' &&
        searchParams.get('id') &&
        projectUidParsed &&
        modelIdParsed
      ) {
        getContentsItem(projectUidParsed, modelIdParsed, searchParams.get('id'))
          .then(async (res) => {
            await dispatch(setContentsFormModal(true, res.data.data))

            // 연관 데이터 제목 가져오기
            await getRelationContents(projectUidParsed, res.data.data)
          })
          .catch((e) => {
            message.error(e.response.data.error)
          })
      }
    }
  }, [location, contentsListReload])

  useEffect(() => {
    if (contentsRelationSelectorModal && contentsInfo) {
      // 연관 데이터 제목 가져오기
      getRelationContents(currentProject?.uid, contentsInfo)
    }
  }, [contentsRelationSelectorModal, contentsInfo])

  /**
   * 체크하기
   */
  const onToggleCheckAll = (e) => {
    dispatch(setCheckedContentsList(e.target.checked ? contentsList : []))
  }

  /**
   * 정렬 순서 변경
   * @param comp
   */
  const onHandleDateSort = (dateField) => {
    navigate(
      `/projects/${currentProject?.uid}/contents/${currentModel?.id}?page=${
        searchParams.get('page')
          ? searchParams.get('page')
          : contentsPagination.current
      }&size=${
        searchParams.get('size')
          ? searchParams.get('size')
          : contentsPagination.pageSize
      }&sort=${
        dateField + '.' + (contentsSort.indexOf('.DESC') >= 0 ? 'ASC' : 'DESC')
      }&q=${searchParams.get('q') ? searchParams.get('q') : ''}`
    )
  }

  /**
   * 엑셀 헤더
   * @returns
   */
  const getHeader = () => {
    return (
      <div
        id="contents-list-header-contents"
        className="flex h-6 text-xs text-gray-600 leading-6">
        <div
          className="px-2 flex items-center justify-center border-b border-t border-r border-gray-300 bg-gray-200 sticky z-40 header-fixed-cell"
          style={{
            width: LAYOUT_CHECKBOX_CELL_WIDTH + 'px',
            height: LAYOUT_DEFAULT_CELL_HEIGHT,
          }}
          title={t('selectAll')}>
          <Checkbox
            indeterminate={
              checkedContentsList.length > 0 &&
              checkedContentsList.length !== contentsList.length
            }
            checked={
              checkedContentsList.length > 0 &&
              checkedContentsList.length === contentsList.length
            }
            onChange={onToggleCheckAll}
            disabled={currentProject?.role === 'VIEWER'}
          />
        </div>
        <ContentsHeaderCells />
        <div
          className="px-2 flex justify-between items-center border-b border-t border-r border-gray-300 bg-gray-200"
          style={{
            width: LAYOUT_DEFAULT_CELL_DATE_WIDTH + 'px',
            height: LAYOUT_DEFAULT_CELL_HEIGHT,
          }}>
          <div>{t('createdDate')}</div>
          <div
            className="flex flex-col space-y-0 cursor-pointer"
            onClick={() => onHandleDateSort('DATE_CREATE')}>
            <CaretUpFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'DATE_CREATE' &&
                  contentsSort.split('.')[1] === 'ASC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
            <CaretDownFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'DATE_CREATE' &&
                  contentsSort.split('.')[1] === 'DESC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
          </div>
        </div>
        <div
          className="px-2 flex justify-between items-center border-b border-t border-r border-gray-300 bg-gray-200"
          style={{
            width: LAYOUT_DEFAULT_CELL_DATE_WIDTH + 'px',
            height: LAYOUT_DEFAULT_CELL_HEIGHT,
          }}>
          <div>{t('editedDate')}</div>
          <div
            className="flex flex-col space-y-0 cursor-pointer"
            onClick={() => onHandleDateSort('DATE_UPDATE')}>
            <CaretUpFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'DATE_UPDATE' &&
                  contentsSort.split('.')[1] === 'ASC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
            <CaretDownFilled
              className={`h-2.5 cursor-pointer text-xxs hover:opacity-80`}
              style={{
                color:
                  contentsSort &&
                  contentsSort.split('.') &&
                  contentsSort.split('.')[0] === 'DATE_UPDATE' &&
                  contentsSort.split('.')[1] === 'DESC'
                    ? '#1890FF'
                    : '#BFBFBF',
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  /**
   * 콘텐트 목록 가져오기
   * @param paging
   * @param sorting
   * @param field
   * @param filter
   * @param delay
   * @param skipLoading
   */
  const getContentsList = (
    projectUid,
    modelId,
    pagination,
    sorting,
    q,
    delay = 0,
    skipLoading = false
  ) => {
    if (!skipLoading) dispatch(setContentsListLoading(true))
    setLocalLoading(true)
    notification.destroy()

    setTimeout(
      async () => {
        // IndexedDB 가져오기
        const indexedDBContents = await getItem(modelId)
        const contentsListReqs: any[] = []
        let isScrollReset = false

        notification.open({
          message: t('syncLoading'),
          icon: <LoadingOutlined spin />,
          placement: 'bottomRight',
          duration: 0,
        })

        if (indexedDBContents && indexedDBContents !== -1) {
          try {
            await dispatch(
              setContentsList(indexedDBContents, pagination, q, sorting)
            )

            await setLocalLoading(false)

            if (document.getElementById('contents-list')) {
              // @ts-ignore
              document.getElementById('contents-list').scrollLeft = 0
            }

            if (document.getElementById('contents-body')) {
              // @ts-ignore
              document.getElementById('contents-body').scrollTop = 0
            }

            isScrollReset = true
          } catch (e) {
            console.log(e)
          }
        }

        try {
          // console.time('콘텐츠 가져오기')
          const getData = (page: number) => {
            const resAxios = getContentsListSearch(projectUid, modelId, {
              page: page,
              size: CHUNK_CONTENTS_GET_SIZE,
              direction: 'ASC',
            })
            contentsListReqs.push(resAxios)

            return resAxios.then((res) => {
              if (!res.data.pageInfo.isLast) {
                return getData(page + 1)
              }
            })
          }

          await getData(0)

          await axios.all([...contentsListReqs]).then(
            axios.spread(async (...responses) => {
              const updatedAllContents = responses
                .map((r) => r.data.list)
                .flat()

              dispatch(
                setContentsList(
                  updatedAllContents,
                  indexedDBContents ? contentsPagination : pagination,
                  indexedDBContents ? contentsKeyword : q,
                  indexedDBContents ? contentsSort : sorting,
                  true
                )
              )

              setTimeout(() => {
                notification.destroy()
                notification.success({
                  message: t('syncComplete'),
                  placement: 'bottomRight',
                })
              })

              setTimeout(() => {
                dispatch(setContentsListLoading(false))
                setLocalLoading(false)
                // console.timeEnd('콘텐츠 가져오기')

                if (!isScrollReset) {
                  // @ts-ignore
                  document.getElementById('contents-list').scrollLeft = 0
                  // @ts-ignore
                  document.getElementById('contents-body').scrollTop = 0
                }

                setTimeout(() => {
                  const updatedAllContetsIndexedDB =
                    removeUnnecessaryPropertiesForIndexedDB(updatedAllContents)

                  if (indexedDBContents) {
                    updateItem(modelId, updatedAllContetsIndexedDB)
                  } else {
                    addItem(modelId, updatedAllContetsIndexedDB)
                  }
                })
              })
            })
          )
        } catch (e) {
          dispatch(
            setContentsList(
              [],
              {
                total: 0,
                pageSize: DEFAULT_CONTENTS_PAGE_SIZE,
                current: 1,
              },
              '',
              DEFAULT_SORT
            )
          )
          dispatch(setContentsListLoading(false))
          setLocalLoading(false)

          notification.destroy()
        }
      },
      delay ? delay : 0
    )
  }

  /**
   * 연관 콘텐츠 정보 가져오기
   * @param projectUid
   * @param contents
   */
  const getRelationContents = (projectUid, contents) => {
    contents?.cellList
      ?.filter((c) => c.component?.type === 'RELATION')
      .forEach((relationCell) => {
        relationCell?.relationList?.forEach((relation) => {
          getContentsItem(projectUid, relation.modelId, relation.uid)
            .then((res) => {
              if (currentProject) {
                const targetModel = modelList.find(
                  (m) => m.id === relation.modelId
                )
                const titleComponent = targetModel?.flattenComponentList?.find(
                  (fc) => fc.option?.title
                )

                const titleCell = res.data.data.cellList.find(
                  (cell) => cell.component.id === titleComponent?.id
                )

                const relationTitle =
                  titleCell && titleCell.languageMap
                    ? titleCell?.languageMap[currentProject.defaultLang]
                    : contents.uid

                const relationModelTitle =
                  targetModel && targetModel.languageMap
                    ? (targetModel.languageMap[
                        currentProject.defaultLang
                      ] as string)
                    : 'N/A'

                dispatch(
                  setContentsCellItem(
                    contents.uid,
                    relationCell.uid,
                    relation.uid,
                    relationTitle,
                    relationModelTitle
                  )
                )

                const titleEles = document.querySelectorAll(
                  `.rel-title-${relationCell.uid}-${relation.uid}`
                )
                const modelTitleEles = document.querySelectorAll(
                  `.rel-modelTitle-${relationCell.uid}-${relation.uid}`
                )

                titleEles.forEach((titleEle, tIdx) => {
                  titleEle.innerHTML = relationTitle
                  modelTitleEles[tIdx].innerHTML = relationModelTitle
                })
              }
            })
            .catch((e) => {
              message.error(e.response.data.error)
            })
        })
      })
  }

  return currentProject && currentModel ? (
    <div className="relative">
      {flattenComponentList && flattenComponentList.length ? (
        <div className="flex" id="contents-list-header">
          {getHeader()}
        </div>
      ) : (
        <></>
      )}
      {flattenComponentList && flattenComponentList.length ? (
        <div className="relative">
          {contentsListInit && !localLoading ? (
            <div id="contents-list-body">
              {contentsList.length ? (
                contentsList.map((contents, cIdx) => (
                  <div className="flex " key={contents.uid}>
                    <ContentsCellList contents={contents} />
                  </div>
                ))
              ) : !contentsListLoading ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <Empty
          description={
            <div className="space-y-6">
              <p className="text-center">{t('componentNeed')}</p>
              <div className={'flex justify-center'}>
                <Button
                  type={'primary'}
                  htmlType={'button'}
                  onClick={() =>
                    navigate(
                      `/projects/${currentProject.uid}/builder/${currentModel.id}`
                    )
                  }>
                  {t('editModel')}
                </Button>
              </div>
            </div>
          }
        />
      )}

      <ContentsFormModal />
      <MediaSelectorModal />
      <MediaFormModal />
      <MediaUploadModal />
      <ContentsRelationSelectorModal />
      {localLoading ? (
        <div
          className={`h-screen fixed top-0 right-0 flex justify-center items-center text-center`}
          style={{
            width: `calc(100% - ${LAYOUT_GNB_WIDTH + LAYOUT_SIDEBAR_WIDTH}px)`,
            zIndex: 1000,
          }}>
          <Spin />
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  )
}
