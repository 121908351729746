import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
import { RootState } from '@/states/reducers'
const { Sider } = Layout
import { LAYOUT_DEFAULT_BG } from '@/configs'
import { useAppDispatch } from '@/states/store'

export const LayoutSidebarProjectDevelopers = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // Params
  let { projectUid } = useParams()

  // State (Redux)
  const { projectsState } = useSelector(
    (state: RootState) => ({
      projectsState: state.projects,
    }),
    shallowEqual
  )
  const { currentProject } = projectsState

  /**
   * 프로젝트 개발자 메뉴
   */
  const projectDevelopersMenus: any[] = [
    {
      key: 'apps',
      label: t('appSettings'),
      onClick: () => {
        navigate(`/projects/${projectUid}/developers/apps`)
      },
    },
    {
      key: 'externalAPIs',
      label: t('externalAPIs'),
      onClick: () => {
        navigate(`/projects/${projectUid}/developers/externalAPIs`)
      },
    },
    {
      key: 'typescript',
      label: t('typescript'),
      onClick: () => {
        navigate(`/projects/${projectUid}/developers/typescript`)
      },
    },
  ]

  return (
    <Sider
      theme={'light'}
      width={'auto'}
      className={'border-r border-gray-300 overflow-hidden overflow-y-auto'}
      style={{
        height: '100vh',
        backgroundColor: LAYOUT_DEFAULT_BG,
        zIndex: 100,
      }}>
      <h4 className={'py-3 px-6 text-xs font-bold uppercase leading-5 mb-0'}>
        {t('developers')}
      </h4>
      <div>
        <Menu
          theme="light"
          defaultSelectedKeys={[]}
          mode="inline"
          style={{
            background: 'transparent',
          }}
          selectedKeys={[
            location.pathname === `/projects/${projectUid}/developers/apps`
              ? 'apps'
              : '',
            location.pathname ===
            `/projects/${projectUid}/developers/externalAPIs`
              ? 'externalAPIs'
              : '',
            location.pathname ===
            `/projects/${projectUid}/developers/typescript`
              ? 'typescript'
              : '',
          ]}
          items={projectDevelopersMenus}
        />
      </div>
    </Sider>
  )
}
