import React, { useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Split from 'react-split'
import { Layout, message } from 'antd'
const { Content } = Layout
import { setAccountMenu, setSplit } from '@/states/actions/layout.actions'
import { RootState } from '@/states/reducers'
import {
  LayoutGnbProject,
  LayoutSidebarProjectSettings,
  LayoutSidebarProjectContentsBuilder,
  LayoutSidebarProjectContents,
  LayoutSidebarProjectDevelopers,
} from '@/components/layout'
import {
  LAYOUT_SIDEBAR_MAX_WIDTH,
  LAYOUT_GNB_WIDTH,
  LAYOUT_GNB_COLLAPSE_WIDTH,
} from '@/configs'
import { getProfile } from '@/states/actions/auth.actions'
import {
  getProjectData,
  getProjectUsageData,
  setCurrentProject,
} from '@/states/actions/projects.actions'
import { getModels } from '@/states/actions/models.actions'
import { useAppDispatch } from '@/states/store'
import { getCategoryGroups } from '@/states/actions/categories.actions'

interface LayoutProjectsProps {
  children
}

export const LayoutProjects = ({ children }: LayoutProjectsProps) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  // Params
  const { projectUid, modelId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  // State (Redux)
  const { layoutState, projectsState, authState } = useSelector(
    (state: RootState) => ({
      layoutState: state.layout,
      projectsState: state.projects,
      authState: state.auth,
    }),
    shallowEqual
  )
  const { accountMenu, splitSize, gnb } = layoutState
  const { modelListInit, currentModel } = projectsState
  const { init, me } = authState

  useEffect(() => {
    if (!modelListInit && projectUid) {
      getProjectData(projectUid)
        .then((res) => {
          const projectData = res.data.data

          getProjectUsageData(projectUid)
            .then((res) => {
              projectData.usage = res.data

              dispatch(setCurrentProject(projectData))

              dispatch(
                getModels(
                  projectUid,
                  modelId ? (Number(modelId) as number) : true
                )
              )

              dispatch(getCategoryGroups(projectUid))
            })
            .catch((e) => {
              message.error(t('error.pageNotFound'))
              navigate(`/projects/`)
            })
        })
        .catch((e) => {
          message.error(t('error.pageNotFound'))
          navigate(`/projects/`)
        })
    }
  }, [modelListInit, projectUid])

  /* useEffect(() => {
    dispatch(setProfile(profile))
  }, [profile]) */

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  // Effect
  useEffect(() => {
    if (!init) {
      // 내 프로필 가져오기
      dispatch(getProfile())
    }
  }, [init])

  /**
   * 헤더 메뉴 닫기
   * @param e
   */
  const closeMenu = (e) => {
    if (accountMenu && !e.target.closest('.stay-open')) {
      dispatch(setAccountMenu(false))
    }
  }

  const onChangeSplitSize = (e) => {
    dispatch(setSplit(e))
  }

  return (
    <Layout hasSider onClick={(e) => closeMenu(e)}>
      <LayoutGnbProject></LayoutGnbProject>
      <Split
        className={'w-full flex split'}
        sizes={splitSize}
        style={{
          width: `calc(100vw - ${
            gnb ? LAYOUT_GNB_WIDTH : LAYOUT_GNB_COLLAPSE_WIDTH
          }px)`,
        }}
        minSize={[0, 800]}
        maxSize={[LAYOUT_SIDEBAR_MAX_WIDTH, Infinity]}
        gutterAlign={'start'}
        onDragEnd={onChangeSplitSize}>
        {location.pathname.includes('/settings') ? (
          <LayoutSidebarProjectSettings />
        ) : (
          ''
        )}
        {location.pathname.includes('/builder') ? (
          <LayoutSidebarProjectContentsBuilder />
        ) : (
          ''
        )}
        {location.pathname === `/projects/${projectUid}/contents/${modelId}` ? (
          <LayoutSidebarProjectContents />
        ) : (
          ''
        )}
        {location.pathname.includes('/developers') ? (
          <LayoutSidebarProjectDevelopers />
        ) : (
          ''
        )}
        <Layout id="layout-main" className="site-layout h-screen">
          <Content>
            <div className="site-layout-background">{children}</div>
          </Content>
        </Layout>
      </Split>
    </Layout>
  )
}
