import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router'
import { useSelector, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Layout, Menu } from 'antd'
import { UserOutlined } from '@ant-design/icons'
const { Sider } = Layout
import { CommonLogo } from '@/components/common/common.logo'
import { AuthProfile } from '@/components/auth/auth.profile'
import { useAppDispatch } from '@/states/store'
import { LAYOUT_GNB_WIDTH } from '@/configs'
import { RootState } from '@/states/reducers'

export const LayoutGnbHome = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()

  // State (Redux)
  const { authState } = useSelector(
    (state: RootState) => ({
      authState: state.auth,
    }),
    shallowEqual
  )
  const { init } = authState

  const projectMenuItems = [
    {
      key: 'projects',
      label: t('project'),
      onClick: () => {
        navigate('/projects')
      },
    },
  ]
  const settingsMenuItems = [
    {
      key: 'settings',
      label: t('myInformation'),
      icon: <UserOutlined />,
      onClick: () => {
        navigate('/settings/account')
      },
    },
  ]

  return (
    <Sider
      theme={'light'}
      width={LAYOUT_GNB_WIDTH}
      className={''}
      style={{
        overflow: 'hidden',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 100,
      }}>
      <CommonLogo></CommonLogo>
      <div className={'py-3 space-y-4'}>
        <div>
          <Menu
            theme="light"
            defaultSelectedKeys={[]}
            selectedKeys={[
              location.pathname.includes('/projects') ? 'projects' : '',
            ]}
            mode="inline"
            items={projectMenuItems}></Menu>
        </div>
        <div>
          <h4
            className={'py-3 px-6 text-xs font-bold uppercase leading-5 mb-0'}>
            {t('settings')}
          </h4>
          <Menu
            theme="light"
            defaultSelectedKeys={[]}
            selectedKeys={[
              location.pathname.includes('/settings') ? 'settings' : '',
            ]}
            mode="inline"
            items={settingsMenuItems}></Menu>
        </div>
      </div>
      <AuthProfile></AuthProfile>
    </Sider>
  )
}
